/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import './NewDocMain.scss';
import { toast } from 'react-toastify';
import CONSTANTS from '../../constants';
import ImportantFields from '../important-fields/ImportantFields';
import { useDocumentContext } from '../../contexts/document-provider';
import WorkFlowPanel from '../work-flow-panel/WorkFlowPanel';
import Attachments from '../attachments/edit/EditAttachments';
import { PlusIconSvg, SuccessfulIcon } from '../../assets/images';
// import ApproversHelpText from '../approvers-help-text/ApproversHelpText';
import STATIC_CONTENT from '../../constants/StaticContent';
import Button from '../button/Button';
import DocumentPreview from '../document-preview/DocumentPreview';
import { IDocChecklistSteps } from '../../interfaces/document';
import ConfirmationModal, { IAcceptedType } from '../confirmation-modal/ConfirmationModal';
import { removeApprovalFlow, toggleDocumentLock } from '../../API/document';

interface Props {
    isDisabled: boolean;
}

const NewDocMain: React.FC<Props> = ({ isDisabled }: Props) => {
    const {
        saveFieldsData,
        isFieldsDataSaved,
        activeStep,
        fields,
        fieldsData,
        setActiveStep,
        onDocumentSubmit,
        documentId,
        submitDocStatus,
        documentDispatch,
        error: {
            STEP1: { errorFields },
        },
        approvers,
        accessibleByCurrentUser,
        taskId,
        attachmentConfig,
        docTypeInfo,
        docInfo,
        activeDocVersionId,
        setDocInfo
    } = useDocumentContext();
    const {search} = useLocation();
    const [modalOpen, setModalOpen] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [newDocNumber, setNewDocNumber] = useState('');
    const [isNextLoader, setIsNextLoader] = useState(false);
    const [isPreviousLoader, setIsPreviousLoader] = useState(false);
    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [confirmClicked, setConfirmClicked] = useState(false);
    const [SaveLoading, setSaveLoading] = useState(false);
    const [removeWorkflowLoader,setRemoveWorkflowLoader] = useState(false);
    const { CREATE_DOCUMENTS } = STATIC_CONTENT;
    const [isNotifyModalOpen ,setNotifyModal] = useState<boolean>(false);


    const updateNotifyModalState = () => {
        setNotifyModal(true);
      };

    const onButtonClick = async (type: 'PREVIOUS' | 'NEXT') => {
        if (type === 'NEXT') setIsNextLoader(true);
        else setIsPreviousLoader(true);
        await saveFieldsData(false);
        if (type === 'NEXT') {
            setIsNextLoader(false);
            setIsPreviousLoader(false);
            // if (attachmentConfig.length === 0) {
            //     setActiveStep('STEP3');
            // } else 
            if (activeStep === 'STEP1') {
                setActiveStep('STEP2');
            } else if (activeStep === 'STEP2') {
                setActiveStep('STEP3');
            } else {
                setActiveStep('STEP3');
            }
        }
        if (type === 'PREVIOUS') {
            setIsNextLoader(false);
            setIsPreviousLoader(false);
            // if (attachmentConfig.length === 0) {
            //     setActiveStep('STEP1');
            // } else 
            if (activeStep === 'STEP3') {
                setActiveStep('STEP2');
            } else if (activeStep === 'STEP2') {
                setActiveStep('STEP1');
            } else {
                setActiveStep('STEP1');
            }
        }
    };

    const history = useHistory();
    useEffect(() => {
        setConfirmationModal(true);
        if (approvers.length > 0) {
            approvers.forEach((approver) => {
                if (approver.approvers.length > 0) {
                    setConfirmationModal(false);
                }
            });
        }
    }, [approvers]);

    const removeSignatureProcess = async () => {
        setRemoveWorkflowLoader(true);
        await saveFieldsData(false);
        const res = await removeApprovalFlow(documentId,activeDocVersionId,taskId);
        setRemoveWorkflowLoader(false);
        history.push(`/document-details?doc_id=${documentId}`);
    }

    const submitHandler = async (notifyTravelAdmin : boolean) => {
        const queryObj = qs.parse(search, { ignoreQueryPrefix: true });
        if (accessibleByCurrentUser && taskId !== '') {
            await saveFieldsData(true);
            history.push(`/task-details?task_id=${taskId}&prev_page_type=MY_GROUP_APPROVALS`);
        } else {
            const status = await onDocumentSubmit(notifyTravelAdmin);
            if (status.status === 'SUCCESS') {
                const {pathname} = window.location;
                if(pathname.includes("create-document-in-dco")){
                    if(queryObj.doc_id&&queryObj.dcoDocumentId&&queryObj.doc_version_id){
                        history.push(`/create-document?doc_id=${queryObj.dcoDocumentId}&newDocversionId=${queryObj.doc_version_id}`);
                    }
                    await toggleDocumentLock(true,Number(queryObj.doc_id ? queryObj.doc_id : 0));
                }
                else{
                    setNewDocNumber(status.docNumber);
                    setModalOpen(true);
                }
            }
        }
    };

    const closeModal = async () => {
        await saveFieldsData(true);
        const queryObj = qs.parse(search, { ignoreQueryPrefix: true });
        const { pathname } = window.location;
        if (pathname.includes('create-document-in-dco')) {
            if (queryObj.doc_id && queryObj.dcoDocumentId && queryObj.doc_version_id) {
                history.push(
                    `/create-document?doc_id=${queryObj.dcoDocumentId}&newDocversionId=${queryObj.doc_version_id}`,
                );
            }
            await toggleDocumentLock(true, Number(queryObj.doc_id ? queryObj.doc_id : 0));
        }
    };

    useEffect(() => {
        if (confirmClicked) {
            submitHandler(false);
        }
        setConfirmClicked(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmClicked]);

    Modal.setAppElement('#root');
    const [confirmationModalData, setConfirmationModalData] = useState({
        type: 'EMPTY_APPROVER',
        onConfirm: () => {
            setConfirmClicked(true);
        },
    });

    return (
        <div className="new-doc-main">
            <h2>{CONSTANTS.NEW_DOC_STEP[activeStep]}</h2>
            {activeStep === 'STEP1' && (
                <ImportantFields
                    isDisabled={isDisabled || docInfo.documentLock}
                    fields={fields}
                    fieldsData={fieldsData}
                    documentDispatch={documentDispatch}
                    errorFields={errorFields}
                />
            )}
            {activeStep === 'STEP2' && <Attachments />}
            {activeStep === 'STEP3' && <WorkFlowPanel updateNotifyModalState={updateNotifyModalState} isButtonAvailabe={submitDocStatus === 'LOADING'}/>}
            <div className="buttons">
                {
                    !docInfo.documentLock && docTypeInfo.allowDCO && attachmentConfig.length && activeStep === 'STEP1' ? (
                        <>
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => saveFieldsData(true)}
                                disabled={false}
                                isLoading={isFieldsDataSaved}
                            >
                                {CREATE_DOCUMENTS.BUTTON.SAVE}
                            </Button>
                            
                            <Button
                            type="button"
                            className="primary-btn"
                            onClick={() => onButtonClick('NEXT')}
                            disabled={false}
                            isLoading={isNextLoader}
                            >
                                {CREATE_DOCUMENTS.BUTTON.NEXT}
                            </Button>
                        </>
                    ) : null
                }
                {
                    !docInfo.documentLock && docTypeInfo.allowDCO && attachmentConfig.length && activeStep === 'STEP2' ? (
                        <>
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => onButtonClick('PREVIOUS')}
                                disabled={false}
                                isLoading={isPreviousLoader}
                            >
                                {CREATE_DOCUMENTS.BUTTON.PREVIOUS}
                            </Button>
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => closeModal()}
                                disabled={false}
                                isLoading={isFieldsDataSaved}
                            >
                                {CREATE_DOCUMENTS.BUTTON.SAVE}
                            </Button>
                        </>
                    ) : null
                }
                {
                    !docInfo.documentLock && docTypeInfo.allowDCO && !attachmentConfig.length && activeStep === 'STEP1' ? (
                        <>
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => closeModal()}
                                disabled={false}
                                isLoading={isFieldsDataSaved}
                            >
                                {CREATE_DOCUMENTS.BUTTON.SAVE}
                            </Button>
                        </>
                    ) : null
                }
                {
                    !docInfo.documentLock && !docTypeInfo.allowDCO && attachmentConfig.length && activeStep === 'STEP1' ? (
                        <>
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => saveFieldsData(true)}
                                disabled={false}
                                isLoading={isFieldsDataSaved}
                            >
                                {CREATE_DOCUMENTS.BUTTON.SAVE}
                            </Button>
                            
                            <Button
                            type="button"
                            className="primary-btn"
                            onClick={() => onButtonClick('NEXT')}
                            disabled={false}
                            isLoading={isNextLoader}
                            >
                                {CREATE_DOCUMENTS.BUTTON.NEXT}
                            </Button>
                        </>
                    ) : null
                }
                {
                    !docInfo.documentLock && !docTypeInfo.allowDCO && attachmentConfig.length && activeStep === 'STEP2' ? (
                        <>
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => saveFieldsData(true)}
                                disabled={false}
                                isLoading={isFieldsDataSaved}
                            >
                                {CREATE_DOCUMENTS.BUTTON.SAVE}
                            </Button>
                            
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => onButtonClick('PREVIOUS')}
                                disabled={submitDocStatus === 'LOADING'}
                                isLoading={isPreviousLoader}
                                loaderColor="#f37321"
                            >
                                {CREATE_DOCUMENTS.BUTTON.PREVIOUS}
                            </Button>

                            <Button
                                type="button"
                                className="primary-btn"
                                onClick={() => onButtonClick('NEXT')}
                                disabled={false}
                                isLoading={isNextLoader}
                            >
                                {CREATE_DOCUMENTS.BUTTON.NEXT}
                            </Button>
                        </>
                    ) : null
                }
                {
                    !docInfo.documentLock && !docTypeInfo.allowDCO && attachmentConfig.length && activeStep === 'STEP3' ? (
                        <>
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => onButtonClick('PREVIOUS')}
                                disabled={submitDocStatus === 'LOADING'}
                                isLoading={isPreviousLoader}
                                loaderColor="#f37321"
                            >
                                {CREATE_DOCUMENTS.BUTTON.PREVIOUS}
                            </Button>
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => {
                                    saveFieldsData(false);
                                    setShowPreview(true);
                                }}
                                disabled={submitDocStatus === 'LOADING'}
                                isLoading={false}
                                loaderColor="#f37321"
                            >
                                {CREATE_DOCUMENTS.BUTTON.PREVIEW}
                            </Button>
                            <Button
                                type="button"
                                className="primary-btn"
                                onClick={async () => {
                                    setSubmitClicked(true);
                                    if (!showConfirmationModal) submitHandler(false);
                                }}
                                disabled={submitDocStatus === 'LOADING'}
                                isLoading={submitDocStatus === 'LOADING'}
                                loaderColor="#f37321"
                            >
                                {accessibleByCurrentUser ? 'Save' : CREATE_DOCUMENTS.BUTTON.SUBMIT}
                            </Button>
                            {accessibleByCurrentUser && taskId !== null && taskId !== '' && docTypeInfo.isContract && (
                            <Button
                                type="button"
                                className="primary-btn"
                                onClick={async () => {
                                    await removeSignatureProcess()
                                }}
                                disabled={submitDocStatus === 'LOADING' || SaveLoading}
                                isLoading={removeWorkflowLoader}
                                loaderColor="#f37321"
                            >
                                Remove Docusign and Approve
                            </Button>
                        )}
                        </>
                    ) : null
                }
                {
                    !docInfo.documentLock && !docTypeInfo.allowDCO && !attachmentConfig.length && activeStep === 'STEP1' ? (
                        <>
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => saveFieldsData(true)}
                                disabled={false}
                                isLoading={isFieldsDataSaved}
                            >
                                {CREATE_DOCUMENTS.BUTTON.SAVE}
                            </Button>
                            
                            <Button
                            type="button"
                            className="primary-btn"
                            onClick={async () => {
                                setIsNextLoader(true);
                                await saveFieldsData(true);
                                setIsNextLoader(false);
                                setActiveStep('STEP3')
                            }}
                            disabled={false}
                            isLoading={isNextLoader}
                            >
                                {CREATE_DOCUMENTS.BUTTON.NEXT}
                            </Button>
                        </>
                    ) : null
                }
                {
                    !docInfo.documentLock && !docTypeInfo.allowDCO && !attachmentConfig.length && activeStep === 'STEP3' ? (
                        <>
                            <Button
                                type="button"
                                className="secondary"
                                onClick={async () => {
                                    setIsPreviousLoader(true);
                                    await saveFieldsData(true);
                                    setIsPreviousLoader(false);
                                    setActiveStep('STEP1');
                                }}
                                disabled={submitDocStatus === 'LOADING'}
                                isLoading={isPreviousLoader}
                                loaderColor="#f37321"
                            >
                                {CREATE_DOCUMENTS.BUTTON.PREVIOUS}
                            </Button>
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => {
                                    saveFieldsData(false);
                                    setShowPreview(true);
                                }}
                                disabled={submitDocStatus === 'LOADING'}
                                isLoading={false}
                                loaderColor="#f37321"
                            >
                                {CREATE_DOCUMENTS.BUTTON.PREVIEW}
                            </Button>
                            <Button
                                type="button"
                                className="primary-btn"
                                onClick={async () => {
                                    setSubmitClicked(true);
                                    if (!showConfirmationModal) submitHandler(false);
                                }}
                                disabled={submitDocStatus === 'LOADING'}
                                isLoading={submitDocStatus === 'LOADING'}
                                loaderColor="#f37321"
                            >
                                {accessibleByCurrentUser ? 'Save' : CREATE_DOCUMENTS.BUTTON.SUBMIT}
                            </Button>
                        </>
                    ) : null
                }
                {
                    docInfo.documentLock && attachmentConfig.length && activeStep === 'STEP1' ? (
                        <>
                            <Button
                                type="button"
                                className="primary-btn"
                                onClick={() => onButtonClick('NEXT')}
                                disabled={false}
                                isLoading={isNextLoader}
                            >
                                {CREATE_DOCUMENTS.BUTTON.NEXT}
                            </Button>
                        </>
                    ) : null
                }
                {
                    docInfo.documentLock && attachmentConfig.length && activeStep === 'STEP2' ? (
                        <>
                            <Button
                                type="button"
                                className="secondary"
                                onClick={() => onButtonClick('PREVIOUS')}
                                disabled={submitDocStatus === 'LOADING'}
                                isLoading={isPreviousLoader}
                                loaderColor="#f37321"
                            >
                                {CREATE_DOCUMENTS.BUTTON.PREVIOUS}
                            </Button>
                        </>
                    ) : null
                }
            </div>
            {showPreview && (
                <DocumentPreview
                    openModal={showPreview}
                    onModalClose={() => setShowPreview(false)}
                    onEdit={(step: IDocChecklistSteps) => {
                        setActiveStep(step);
                        setShowPreview(false);
                    }}
                />
            )}
            <Modal isOpen={modalOpen} contentLabel="submit Modal" className="doc_submit__modal">
                <div className="modal-body">
                    <div className="icon">
                        <SuccessfulIcon />
                    </div>
                    <h1>Document Submitted</h1>
                    {newDocNumber && (
                        <h4>
                            Document Number : <i>{newDocNumber}</i>
                        </h4>
                    )}
                    <p>On an average, it takes about 2 days for approvals</p>
                    <button
                        className="primary-btn"
                        type="button"
                        onClick={() => history.push(`document-details?doc_id=${documentId}`)}
                    >
                        {CREATE_DOCUMENTS.BUTTON.VIEW_SUBMISSION}
                    </button>
                </div>
            </Modal>
            <Modal isOpen={isNotifyModalOpen} onRequestClose={() => setNotifyModal(false)} contentLabel="submit Modal" className="doc_submit__modal">
                <div className="modal-body">
                    <PlusIconSvg className="close-btn" onClick={() => setNotifyModal(false)} />
                    <h1>Notify Travel Admin Team and Submit?</h1>
                    <h4>Travel Admin Team will help you with the approval process.</h4>
                    <div className="notify-buttons">
                        <button type="button" onClick={() => setNotifyModal(false)}>
                            {STATIC_CONTENT.CONFIRMATION.BUTTON.CANCEL}
                        </button>
                        <button
                            className="primary-btn"
                            type="button"
                            onClick={() => {
                                setNotifyModal(false);
                                setSubmitClicked(true);
                                if (!showConfirmationModal) submitHandler(true);
                            }}
                            disabled={submitDocStatus === 'LOADING'}
                        >
                            {CREATE_DOCUMENTS.BUTTON.NOTIFY_AND_SUBMIT}
                        </button>
                    </div>
                </div>
            </Modal>
            {submitClicked && showConfirmationModal && (
                <ConfirmationModal
                    type={confirmationModalData.type as IAcceptedType}
                    isModalOpen={showConfirmationModal}
                    onModalClose={setSubmitClicked}
                    onConfirm={confirmationModalData.onConfirm}
                />
            )}
        </div>
    );
};
export default NewDocMain;
