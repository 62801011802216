/* eslint-disable max-lines-per-function */
import React, { createContext, useState, useEffect, useContext, useReducer, useMemo, useRef } from 'react';
import { toast } from 'react-toastify';
import Ajv from 'ajv';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

import { format, isValid as Validation, parse } from 'date-fns';
import {
    getDocumentVersion,
    updateDocumentVersion,
    submitDocument,
    updateDocument,
    getDocument,
    getDocumentTypeInfo,
    toggleDocumentLock,
    updateApprovlStatusForTravelForm,
    updateApprovalStatus,
    getTaskDetails,
    updateApprovalStatusForLPNF,
} from '../API/document';
import CONSTANTS from '../constants';
import {
    IDocChecklistSteps,
    IDocumentInfo,
    IField,
    IFieldsData,
    IApprovalDocStage,
    IError,
    IErrorInfo,
    IDocReferenceInfo,
    IVersionNumberInfo,
    IExternalApprovers,
} from '../interfaces/document';
import { IAttachment, IAttachmentConfig } from '../interfaces/attachment';
import { IAPIResponseStatus, IButtonStatus, IExternalApprover, IOptionValue, IReviewers, IUserType } from '../interfaces';
import { IDocumentAction, documentReducer } from '../reducers/document-reducer';
import {
    IDocumentReviewersAction,
    documentReviewersReducer,
} from '../reducers/document-reviewers-reducer';
import {
    IDocumentApproversAction,
    IDocumentExternalApproversAction,
    documentApproversReducer,
    externalApproversReducer,
} from '../reducers/document-approver-reducer';
import { useAuthDataContext } from './user-auth-provider';
import { getAttachments } from '../API/attachments';
import { IDocumentTypeInfo } from '../interfaces/document-type';
import { getDynamicValues } from '../API/fields';
import { IVersionOwner } from '../interfaces/user';
import STATIC_CONTENT from '../constants/StaticContent';
import { getUserGroups } from '../API/groups';

const initialNewDocInfo: IDocumentInfo = {
    author: {
        id: 0,
        name: CONSTANTS.LOADING.TEXT,
    },
    versionOwner: {
        id: 0,
        name: CONSTANTS.LOADING.TEXT,
    },
    render: false,
    assignee: {
        employeeName: CONSTANTS.LOADING.TEXT,
        id: 0,
        email: CONSTANTS.LOADING.TEXT,
    },
    approvedVersionId: 0,
    confidential: false,
    docState: 'NEW',
    docNumber: CONSTANTS.LOADING.TEXT,
    title: CONSTANTS.LOADING.TEXT,
    description: CONSTANTS.LOADING.TEXT,
    isFavorite: false,
    approvedOn: 0,
    effectiveFrom: new Date(),
    effectiveTill: new Date(),
    isEverGreen: false,
    autoRenew: false,
    effectivePeriod: 0,
    sunsetPeriod: 0,
    activeDocumentState: 'NEW',
    documentLock: false,
};

const initialDocTypeInfo: IDocumentTypeInfo = {
    title: CONSTANTS.LOADING.TEXT,
    description: CONSTANTS.LOADING.TEXT,
    version: CONSTANTS.LOADING.TEXT,
    status: 'DRAFT',
    isConfidential: false,
    isContract: false,
    allowExport: false,
    allowDCO: false,
    allowReviseByAnyone: false,
    allowViewingInProcessDocByAnyone: false,
    allowCustomVersioning: false,
    blockRevise: false,
    allowTemplateGeneration: false,
    detailsSectionTitle: undefined,
    attachmentSectionTitle: undefined,
    approversSectionTitle: undefined,
    approverTimeStamp: undefined
};

const DEFAULT_ERROR: IError = {
    STEP1: {
        isValid: true,
        errorFields: [],
    },
    STEP2: {
        isValid: true,
        errorFields: [],
    },
    STEP3: {
        isValid: true,
        errorFields: [],
    },
};

const intialTripDetails = {from: '', to: '', fromDate: '', toDate: '' };

const DEFAULT_DATE_ERROR: IErrorInfo = {
    isValid: true,
    errorFields: [],
};

const initialFields: IField[] = [];
const initialFieldsData: IFieldsData[] = [];
const ajv = new Ajv({ allErrors: true, jsonPointers: true });
const initialVersionOwnerData: IVersionOwner = {
    id: 0,
    email: '',
    firstName: '',
    lastname: '',
    employeeName: '',
    jobTitle: '',
    managerId: 0,
    activeUser: false,
};
const initialVersionNumberInfo: IVersionNumberInfo = {
    minorVersionNumber: 0,
    majorVersionNumber: 0,
};
interface IDocumentContext {
    documentId: number;
    selectedDocVersion: IOptionValue;
    docInfo: IDocumentInfo;
    fields: IField[];
    fieldsData: IFieldsData[];
    reviewers: IReviewers[];
    approvers: IApprovalDocStage[];
    externalApprovers: IExternalApprover[];
    activeStep: IDocChecklistSteps;
    submitDocStatus: IButtonStatus;
    userType: IUserType;
    docVersions: IOptionValue[];
    attachmentConfig: IAttachmentConfig[];
    attachments: IAttachment[];
    error: IError;
    dateErrorInfo: IErrorInfo;
    docTitleError: boolean;
    docDescError: boolean;
    docEffectiveFromError: boolean;
    docEffectiveError: boolean;
    docSunsetError: boolean;
    hideUsers: number[];
    docTypeInfo: IDocumentTypeInfo;
    activeDocVersionId: number;
    canViewCreateDocument: boolean;
    canViewDocumentDetails: boolean;
    hideFunctionalAreaICMP : boolean;
    hideCurrentGradeICMP : boolean;
    documentDispatch: React.Dispatch<IDocumentAction>;
    reviewersDispatch: React.Dispatch<IDocumentReviewersAction>;
    approversDispatch: React.Dispatch<IDocumentApproversAction>;
    externalApproversDispatch: React.Dispatch<IDocumentExternalApproversAction>;
    isFieldsDataSaved: boolean;
    saveFieldsData: (showToast: boolean) => void;
    setDocInfo: React.Dispatch<React.SetStateAction<IDocumentInfo>>;
    onDocumentSubmit: (onDocumentSubmit: boolean) => Promise<{ status: 'SUCCESS' | 'FAILURE'; docNumber: string }>;
    setActiveStep: React.Dispatch<React.SetStateAction<IDocChecklistSteps>>;
    setSelectedDocVersion: React.Dispatch<React.SetStateAction<IOptionValue>>;
    fetchDocumentVersion: () => Promise<void>;
    fetchDocument: () => Promise<void>;
    fetchAttachments: () => Promise<void>;
    changeApprovlStatusForTravelForm : (email : string, body : any) => Promise<void>;
    changeApprovalStatusForLPNF : (email : string) => Promise<void>;
    setIsFileUploading: React.Dispatch<React.SetStateAction<boolean>>;
    setHideUsers: React.Dispatch<React.SetStateAction<number[]>>;
    setHideFunctionalAreaICMP : React.Dispatch<React.SetStateAction<boolean>>;
    setHideCurrentGradeICMP : React.Dispatch<React.SetStateAction<boolean>>;
    accessibleByCurrentUser: boolean;
    isMemo: boolean;
    isLeadershipForm: boolean;
    taskId: string;
    publishOnceApproved: boolean;
    isSunsetEnabled: boolean;
    isPublishLimitReached: boolean;
    documentTypeId: number;
    isPRB: boolean;
    isICMP: boolean;
    isTravelAuthorizationForm : boolean;
    activeVersionOwnerInfo: IVersionOwner;
    checkInVersionOwner: IVersionOwner;
    allowViewInProcessDocFlag: boolean;
    versionNumberInfo: IVersionNumberInfo;
    submittedOn: number;
    isEndDateAsked: boolean;
    travelRequestFor: IOptionValue;
    setTravelRequestFor :  React.Dispatch<React.SetStateAction<IOptionValue>>;
    isItACustomaerMeeting: boolean;
    setIsItACustomaerMeeting :  React.Dispatch<React.SetStateAction<boolean>>;
    disableApprovalFlow: boolean;
    setDisableApprovalFlow: React.Dispatch<React.SetStateAction<boolean>>;
    activeApprovalStatusId: number;
    isGroupMember: boolean;
    existingContract: boolean;
    setExistingContract: React.Dispatch<React.SetStateAction<boolean>>;
}

export const newDocumentContext = createContext<IDocumentContext>({
    hideFunctionalAreaICMP: true,
    hideCurrentGradeICMP: true,
    setHideCurrentGradeICMP : () => { },
    setHideFunctionalAreaICMP : () => { },
    documentId: 0,
    selectedDocVersion: { label: '', value: 0 },
    docInfo: initialNewDocInfo,
    fields: initialFields,
    fieldsData: initialFieldsData,
    reviewers: [],
    approvers: [],
    externalApprovers: [],
    activeStep: 'STEP1',
    submitDocStatus: 'DEFAULT',
    docVersions: [],
    attachmentConfig: [],
    attachments: [],
    error: DEFAULT_ERROR,
    dateErrorInfo: DEFAULT_DATE_ERROR,
    docTitleError: false,
    docDescError: false,
    docEffectiveFromError: false,
    docEffectiveError: false,
    docSunsetError: false,
    isFieldsDataSaved: false,
    hideUsers: [],
    docTypeInfo: initialDocTypeInfo,
    activeDocVersionId: 0,
    canViewCreateDocument: true,
    canViewDocumentDetails: true,
    documentDispatch: () => null,
    reviewersDispatch: () => null,
    approversDispatch: () => null,
    externalApproversDispatch: () => null,
    saveFieldsData: () => null,
    onDocumentSubmit: () => Promise.resolve({ status: 'FAILURE', docNumber: '' }),
    setActiveStep: () => {},
    setDocInfo: () => {},
    userType: 'OTHER',
    setSelectedDocVersion: () => { },
    fetchDocumentVersion: () => Promise.resolve(),
    fetchDocument: () => Promise.resolve(),
    fetchAttachments: () => Promise.resolve(),
    changeApprovlStatusForTravelForm: () => Promise.resolve(),
    changeApprovalStatusForLPNF: () => Promise.resolve(),
    setIsFileUploading: () => { },
    setHideUsers: () => { },
    accessibleByCurrentUser: true,
    isMemo: true,
    isPRB: false,
    isICMP: false,
    isTravelAuthorizationForm: false,
    isLeadershipForm: true,
    taskId: '',
    publishOnceApproved: false,
    isSunsetEnabled: false,
    isPublishLimitReached: false,
    activeVersionOwnerInfo: initialVersionOwnerData,
    checkInVersionOwner: initialVersionOwnerData,
    allowViewInProcessDocFlag: false,
    versionNumberInfo: initialVersionNumberInfo,
    documentTypeId: 0,
    submittedOn: 0,
    isEndDateAsked: false,
    travelRequestFor: {label : 'Self' , value : 'Self'},
    setTravelRequestFor : () => {},
    isItACustomaerMeeting: false,
    setIsItACustomaerMeeting : () => { },
    disableApprovalFlow: false,
    setDisableApprovalFlow: () => {},
    activeApprovalStatusId: 0,
    isGroupMember: true,
    existingContract: false,
    setExistingContract: () => { },
});

const DocumentProvider: React.FC = (props: unknown) => {
    const { search } = useLocation();

    let fieldsValidator: Ajv.ValidateFunction;

    const [documentId, setDocumentId] = useState(0);
    const [isFieldsDataSaved, setIsFieldsDataSaved] = useState(false);
    const [activeDocVersionId, setActiveDocVersionId] = useState(0);
    const [selectedDocVersion, setSelectedDocVersion] = useState<IOptionValue>({
        label: '',
        value: 0,
    });
    const { user } = useAuthDataContext();
    const [docInfo, setDocInfo] = useState(initialNewDocInfo);
    const [isTravelAuthorizationForm, setIsTravelAuthorizationForm] = useState<boolean>(false);

    const [docTypeInfo, setDocTypeInfo] = useState(initialDocTypeInfo);
    const [fields, setFields] = useState<IField[]>([]);
    const [docVersions, setDocVersions] = useState<IOptionValue[]>([]);
    const [attachmentConfig, setAttachmentConfig] = useState<IAttachmentConfig[]>([]);
    const [activeApprovalStatusId, setActiveApprovalStatusId] = useState(0);
    const [externalApprovalId, setExternalApprovalId] = useState(0);
    const [activeStep, setActiveStep] = useState<IDocChecklistSteps>('STEP1');
    const [submitDocStatus, setSubmitDocStatus] = useState<IButtonStatus>('DEFAULT');
    const [userType, setUserType] = useState<IUserType>('OTHER');
    const [attachments, setAttachments] = useState<IAttachment[]>([]);
    const [error, setError] = useState<IError>(DEFAULT_ERROR);
    const [dateErrorInfo, setdateErrorInfo] = useState<IErrorInfo>(DEFAULT_DATE_ERROR);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [docTitleError, setDocTitleError] = useState(false);
    const [docEffectiveFromError, setdocEffectiveFromError] = useState(false);
    const [docDescError, setDocDescError] = useState(false);
    const [docEffectiveError, setDocEffectiveError] = useState(false);
    const [docSunsetError, setDocSunsetError] = useState(false);
    const [publishOnceApproved, setPublishOnceApproved] = useState(true);
    const [isEndDateAsked, setIsEndDateAsked] = useState(false);
    const [isSunsetEnabled, setIsSunsetEnabled] = useState(true);
    const [isPublishLimitReached, setisPublishLimitReached] = useState(false);
    const [fieldsData, documentDispatch] = useReducer(documentReducer, []);
    const [reviewers, reviewersDispatch] = useReducer(documentReviewersReducer, []);
    const [externalApprovers, externalApproversDispatch] = useReducer(externalApproversReducer, []);
    const [approvers, approversDispatch] = useReducer(documentApproversReducer, []);
    const [hideUsers, setHideUsers] = useState<number[]>([]);
    const [submittedOn, setsubmittedOn] = useState(0);
    const [canViewCreateDocument, setCanViewCreateDocument] = useState(true);
    const [canViewDocumentDetails, setCanViewDocumentDetails] = useState(true);
    const [isDocFetched, setIsDocFetched] = useState(false);
    const [accessibleByCurrentUser, setAccessibleByCurrentUser] = useState(true);
    const [isMemo, setIsMemo] = useState<boolean>(true);
    const [isPRB, setIsPRB] = useState<boolean>(false);
    const [isICMP, setIsICMP] = useState<boolean>(false);
    const [isItACustomaerMeeting, setIsItACustomaerMeeting] = useState<boolean>(false);
    const [travelRequestFor , setTravelRequestFor] = useState<IOptionValue>({label : 'Self' , value : 'Self'});
    const [hideFunctionalAreaICMP, setHideFunctionalAreaICMP] = useState<boolean>(true);
    const [hideCurrentGradeICMP, setHideCurrentGradeICMP] = useState<boolean>(true);
    const [isLeadershipForm, setisLeadershipForm] = useState<boolean>(false);
    const [taskId, setTaskId] = useState('');
    const [allowReviseFlag, setAllowReviseFlag] = useState(false);
    const [allowViewInProcessDocFlag, setAllowViewInProcessDocFlag] = useState(false);
    const [isGroupMember, setIsGroupMember] = useState(true);
    const [activeVersionOwnerInfo, setActiveVersionOwnerInfo] =
        useState<IVersionOwner>(initialVersionOwnerData);
    const [checkInVersionOwner, setCheckInVersionOwner] =
        useState<IVersionOwner>(initialVersionOwnerData);
    const [versionNumberInfo, setVersionNumberInfo] =
        useState<IVersionNumberInfo>(initialVersionNumberInfo);
    const history = useHistory();
    const [documentTypeId, setDocumentTypeId] = useState(0);
    const [disableApprovalFlow,setDisableApprovalFlow] = useState(false);
    const [existingContract, setExistingContract] = useState<boolean>(false);

    const makeFieldMandatory = (fieldName : string) => {
        setFields(perviousData => {
            const index = perviousData.findIndex(field => field.name === fieldName);
            if (index !== -1) {
              const newData = [...perviousData];
              newData[index].mandatory = true;
              return newData;
            }
            return perviousData;
          });
    };

    const makeFieldNotMandatory = (fieldName : string) => {
        setFields(perviousData => {
            const index = perviousData.findIndex(field => field.name === fieldName);
            if (index !== -1) {
              const newData = [...perviousData];
              newData[index].mandatory = false;
              return newData;
            }
            return perviousData;
          });
    };

    const fetchDocumentVersion = async () => {
        const isDocumentDetails = history.location.pathname === "/document-details";
        if (documentId && selectedDocVersion.value) {
            const response = await getDocumentVersion(
                documentId,
                selectedDocVersion.value as number,
                user.id,
                allowReviseFlag,
                isDocumentDetails,
            );
            if (response) {
                const fieldsTempData: IFieldsData[] = [];
                // eslint-disable-next-line no-restricted-syntax
                for (const field of response.fields) {
                    let value: unknown = CONSTANTS.DEFAULT_VALUE[field.dataType.label];
                    if (response.data[field.name]) {
                        if (field.selectType === 'dynamic') {
                            if (field.uiControl.label === 'Single Select Dropdown') {
                                if ((response.data[field.name] as string[]).length > 0) {
                                    // eslint-disable-next-line no-await-in-loop
                                    const dynamicOptions = await getDynamicValues(
                                        field.id,
                                        '',
                                        response.data[field.name] as string[],
                                    );
                                    if (dynamicOptions?.apiStatus === 'SUCCESS') {
                                        value = dynamicOptions.dynamicData;
                                    } else {
                                        toast.error(CONSTANTS.ERROR_406);
                                    }
                                }
                            }
                            if (field.uiControl.label === 'Multi Select Dropdown') {
                                if ((response.data[field.name] as string[]).length > 0) {
                                    // eslint-disable-next-line no-await-in-loop
                                    const dynamicOptions = await getDynamicValues(
                                        field.id,
                                        '',
                                        response.data[field.name] as string[],
                                    );
                                    if (dynamicOptions?.apiStatus === 'SUCCESS') {
                                        value = dynamicOptions.dynamicData;
                                    } else {
                                        toast.error(CONSTANTS.ERROR_406);
                                    }
                                }
                            }
                        } else if (field.selectType === 'static') {
                            if (field.uiControl.label === 'Single Select Dropdown') {
                                // value = {
                                //     label: response.data[field.name],
                                //     value: response.data[field.name],
                                // };
                                // value = response.data[field.name];
                                value = (response.data[field.name] as string[]).map((o) => ({
                                    label: o,
                                    value: o,
                                }));
                            }
                            if (field.uiControl.label === 'Multi Select Dropdown') {
                                value = (response.data[field.name] as string[]).map((o) => ({
                                    label: o,
                                    value: o,
                                }));
                            }
                        } else if (field.uiControl.label === 'Travel Form'){
                            if(Array.isArray(response.data[field.name])){
                                value ={
                                    travelType : "Round Trip",
                                    travelDetails : response.data[field.name],
                                }
                            } else{
                                value = response.data[field.name];
                            }
                        } else if (response.data[field.name]) {
                            value = response.data[field.name];
                        }
                    } else if (field.dataType.label === 'date-time' && field.uiControl.label === 'OnlyDate') {
                        value = '';
                    } else if (field.uiControl.label === 'Travel Form'){
                        if(Array.isArray(value)){
                            value ={
                                travelType : "Round Trip",
                                travelDetails : value.length > 0 ? value : [{...intialTripDetails}] ,
                            }
                        }
                    }
                    fieldsTempData.push({
                        name: field.name,
                        value,
                    });
                }
                const { prefixes } = response;
                if (prefixes.length > 0) {
                    const temp = prefixes.some(
                        (item) => item.type === 'static' && item.value === 'MEMO',
                    );
                    const check = prefixes.some(
                        (item) =>
                            item.type === 'static' &&
                            (item.value === 'ICMP' || item.value === 'LPNF'),
                    );
                    const isLeadershipFormCheck = prefixes.some(
                        (item) => item.type === 'static' && item.value === 'LPNF',
                    );
                    const checkICMP =  prefixes.some(
                        (item) => item.type === 'static' && item.value === 'ICMP',
                    );
                    const checkTravelForm =  prefixes.some(
                        (item) => item.type === 'static' && item.value === 'TRAP',
                    );
                    setIsICMP(checkICMP);
                    setIsMemo(temp);
                    setIsPRB(check);
                    setisLeadershipForm(isLeadershipFormCheck);
                    setIsTravelAuthorizationForm(checkTravelForm);
                    if(checkTravelForm){
                        const customerMeetingField = fieldsTempData.filter((item) => item.name === 'customerMetting');
                        if(customerMeetingField.length > 0){
                            setIsItACustomaerMeeting(customerMeetingField[0].value);
                        }
                        const travelRequestForField = fieldsTempData.filter((item) => item.name === 'travelRequestFor');
                        if(travelRequestForField.length > 0){
                            setTravelRequestFor(travelRequestForField[0].value[0]);
                        }
                    }
                }
                setAttachmentConfig(response.attachmentConfig);
                documentDispatch({ type: 'push', value: fieldsTempData });
                reviewersDispatch({ type: 'update', value: response.reviewers });
                approversDispatch({
                    type: 'update',
                    value: response.approvers,
                });
                externalApproversDispatch({
                    type: 'update',
                    value: response.externalApprovers
                });
                setsubmittedOn(response.submittedOn);
                setActiveApprovalStatusId(response.activeApprovalStatusId);
                setExternalApprovalId(response.externalApprovalId);
                setDocInfo(response.docInfo);
                setFields(response.fields);
                setUserType(response.userType);
                setActiveVersionOwnerInfo(response.versionOwner);
                setCheckInVersionOwner(response.checkInVersionOwner);
                setVersionNumberInfo(response.versionNumberInfo);
                setDocTypeInfo(response.docTypeInfo);
                setHideUsers([...response.hideUsers, user.id]);
                setAccessibleByCurrentUser(response.accessibleByCurrentUser);
                setTaskId(response.taskId);
                setPublishOnceApproved(response.docTypeConfig.publishOnceApproved);
                setIsEndDateAsked(response.docTypeConfig.isEndDateAsked);
                setIsSunsetEnabled(response.docTypeConfig.isSunsetEnabled);
                setDocumentTypeId(response.documentTypeId);
                setDisableApprovalFlow(response.disableApprovalFlow);
                setExistingContract(response?.existingContract);
                const { maxRepublishCount } = response.docTypeConfig;
                const { republishCount } = response;
                if (republishCount >= maxRepublishCount) setisPublishLimitReached(true);
                fieldsValidator = ajv.compile(response.schema);
                if (!(
                    response.userType === 'CREATOR' &&
                    (response.docInfo.docState === 'DRAFT' ||
                        response.docInfo.docState === 'REJECTED') &&
                    Number(response.versionOwner.id) === user.id
                )) {
                    setCanViewCreateDocument(false);
                }
                if(isItACustomaerMeeting){
                    makeFieldMandatory('customerName');
                } else {
                    makeFieldNotMandatory('customerName');
                }
            }
        }
    };

    const saveFieldsData = async (showToast = true) => {
        setIsFieldsDataSaved(true);
        const docUpdateRes = await updateDocument(
            docInfo.title,
            docInfo.description,
            docInfo.confidential,
            docInfo.documentLock,
            documentId,
            docTypeInfo.allowReviseByAnyone,
        );

        if (docUpdateRes?.apiStatus === 'SUCCESS') {
            const childDocIds: number[] = [];
            fieldsData.forEach((fieldData) => {
                if (fieldData.name === 'documents') {
                    fieldData.value.forEach((currDoc: IDocReferenceInfo) => {
                        childDocIds.push(currDoc.id);
                    });
                }
            });

            if (childDocIds.length) {
                await Promise.all(
                    childDocIds.map(async (currId) => {
                        await toggleDocumentLock(true, Number(currId));
                    }),
                );
            }

            const response = await updateDocumentVersion(
                fieldsData,
                documentId,
                selectedDocVersion.value as number,
                activeApprovalStatusId,
                isEndDateAsked,
                reviewers,
                approvers,
                externalApprovalId,
                externalApprovers,
                docInfo.author.id,
                docTypeInfo.allowReviseByAnyone,
                docInfo.isEverGreen,
                docInfo.autoRenew,
                existingContract,
                docInfo.effectiveFrom,
                docInfo.effectiveTill,
                docInfo.effectivePeriod * 604800,
                isEndDateAsked ? docInfo.sunsetPeriod * 86400 : docInfo.sunsetPeriod * 604800,
            );
            if (response?.apiStatus === 'SUCCESS') {
                if (showToast) toast.success('Saved successfully...');
                await fetchDocumentVersion();
            } else if (response?.apiStatus === 'FAILURE') {
                toast.error(response.errorMessage);
            } else if (response?.apiStatus === 'UNAUTHORIZED') {
                toast.error(STATIC_CONTENT.DOCUMENT_ACTIONS.TOAST.ERROR_MSG.UNAUTHORIZED);
            }
            setIsFieldsDataSaved(false);
        } else if (docUpdateRes?.apiStatus === 'FAILURE') {
            toast.error('Failed to save document info');
            setIsFieldsDataSaved(false);
        } else if (docUpdateRes?.apiStatus === 'UNAUTHORIZED') {
            toast.error(STATIC_CONTENT.DOCUMENT_ACTIONS.TOAST.ERROR_MSG.UNAUTHORIZED);
            setIsFieldsDataSaved(false);
        }
    };

    const fieldsValidation = (): IErrorInfo => {
        let isValid = true;
        const errorFields: string[] = [];
        const data: {
            [key: string]: unknown;
        } = {};
        // else if(field.dataType.label === 'date-time' && field.uiControl.label === 'OnlyDate'){
        //     value  = format(value as Date, 'dd/MM/yyyy');
        // }
        // eslint-disable-next-line no-restricted-syntax
        for (const fieldData of fieldsData) {
            const [field] = fields.filter((f) => f.name === fieldData.name);
            if (field && field.mandatory) {
                if (
                    field.uiControl.label === 'Multi Select Dropdown' ||
                    field.uiControl.label === 'Single Select Dropdown'
                ) {
                    const fieldDataArray = fieldData.value;
                    const objToArr = Object.keys(fieldDataArray);
                    objToArr.forEach((idx) => {
                        fieldDataArray[idx] = fieldDataArray[idx].value;
                    });

                    data[fieldData.name] = fieldDataArray;
                } else {
                    data[fieldData.name] = fieldData.value;
                }
            }
        }
        const valid = fieldsValidator(data);
        if (!valid) {
            isValid = false;
            if (fieldsValidator?.errors) {
                // eslint-disable-next-line no-restricted-syntax
                for (const errorType of fieldsValidator.errors) {
                    let field = errorType.dataPath.split('/')[1];
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const params: Ajv.DependenciesParams = errorType.params as any;
                    if (!field) {
                        field = params?.missingProperty ? params?.missingProperty : '';
                    }
                    errorFields.push(field);
                }
            }
        }

        return { isValid, errorFields };
    };

    const dateValidation = (): IErrorInfo => {
        let isValid = true;
        const errorFields: string[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const fieldData of fieldsData) {
            const [field] = fields.filter((f) => f.name === fieldData.name);
            if (field.dataType.label === 'date-time' && field.uiControl.label === 'OnlyDate') {
                if (fieldData.value === '') {
                    isValid = true;
                } else {
                    const [month, day, year] = fieldData.value.split('/');
                    const parsedDate = parse(`${day}/${month}/${year}`, 'dd/MM/yyyy', new Date());
                    if (!Validation(parsedDate)) {
                        isValid = false;
                        errorFields.push(fieldData.name);
                    }
                }
            }
        }
        return { isValid, errorFields };
    };

    const attachmentValidation = (): IErrorInfo => {
        let isValid = true;
        const errorFields: string[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const config of attachmentConfig) {
            if (config.isMandatory) {
                const uploaded = attachments.filter(
                    (attachment) => attachment.fileMeta.attachmentType === config.attachmentType,
                );
                if (uploaded.length === 0) {
                    isValid = false;
                    errorFields.push(config.attachmentType);
                }
            }
        }

        return { isValid, errorFields };
    };

    const workflowValidation = (): IErrorInfo => {
        let isValid = true;
        const errorFields: string[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const approverKey in approvers) {
            if (
                approvers[approverKey].mandatory &&
                approvers[approverKey].approvers.length === 0 &&
                !approvers[approverKey].exempt
            ) {
                isValid = false;
                errorFields.push(approverKey);
            }
        }

        return { isValid, errorFields };
    };

    const validateDocument = (): boolean => {
        let isValid = true;
        if (isFileUploading) {
            toast.warn('Submit after file upload');
            isValid = false;
        }
        if (!docInfo.title) {
            setDocTitleError(true);
            isValid = false;
        }
        if (!docInfo.description) {
            setDocDescError(true);
            isValid = false;
        }
        if (!docInfo.isEverGreen) {
            if (!docInfo.effectivePeriod && isSunsetEnabled && !isEndDateAsked) {
                setDocEffectiveError(true);
                isValid = false;
            }
            if (!docInfo.sunsetPeriod && isSunsetEnabled && !isEndDateAsked) {
                setDocSunsetError(true);
                isValid = false;
            }
            if (!docInfo.sunsetPeriod && isSunsetEnabled && isEndDateAsked && docInfo.autoRenew) {
                setDocSunsetError(true);
                isValid = false;
            }
            if (docInfo.sunsetPeriod > docInfo.effectivePeriod && !isEndDateAsked) {
                setDocSunsetError(true);
                isValid = false;
            }
            if (isEndDateAsked) {
                const effectiveFromTime = Math.floor(new Date(docInfo.effectiveFrom).getTime() / 1000);
                const effectiveTillTime = Math.floor(new Date(docInfo.effectiveTill).getTime() / 1000);
                if (docInfo.effectiveTill < docInfo.effectiveFrom) {
                    setDocEffectiveError(true);
                    isValid = false;
                }
                else if (effectiveTillTime - effectiveFromTime < docInfo.sunsetPeriod * 86400 && docInfo.autoRenew) {
                    setDocSunsetError(true);
                    isValid = false;
                }
            }
        }
        // if (!docInfo.effectiveFrom && !publishOnceApproved) {
        //     setdocEffectiveFromError(true);
        //     isValid = false;
        // }
        const errorData = DEFAULT_ERROR;
        const field = fieldsValidation();

        const attachment = attachmentValidation();
        const workflow = workflowValidation();
        const validDates = dateValidation();
        if (!field.isValid) {
            isValid = false;
        }
        if (!attachment.isValid) {
            isValid = false;
        }
        if (!workflow.isValid) {
            isValid = false;
        }
        if (!validDates.isValid) {
            isValid = false;
        }
        if (!validDates.isValid) {
            field.isValid = false;
        }
        errorData.STEP1 = field;
        errorData.STEP2 = attachment;
        errorData.STEP3 = workflow;
        setError(errorData);
        setdateErrorInfo(validDates);
        return isValid;
    };

    const onDocumentSubmit = async (notifyTravelAdmin : boolean): Promise<{
        status: 'SUCCESS' | 'FAILURE';
        docNumber: string;
    }> => {
        setSubmitDocStatus('LOADING');
        await saveFieldsData(false);
        const valid = validateDocument();
        if (valid) {
            const response = await submitDocument(documentId, notifyTravelAdmin);
            if (response?.apiStatus === 'SUCCESS') {
                return { status: 'SUCCESS', docNumber: response.docNumber };
            }
            if (response?.docTypeChanged) {
                window.location.reload();
            }
            if (response?.apiStatus === 'FAILURE') {
                toast.error(response.errorMessage);
            }
        } else {
            // 
        }
        setSubmitDocStatus('DEFAULT');
        return { status: 'FAILURE', docNumber: '' };
    };

    useEffect(() => {
        fetchDocumentVersion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDocVersion]);

    function useQuery() {
        return useMemo(() => new URLSearchParams(search), [search]);
    }

    const query = useQuery();

    const fetchDocument = async () => {
        if (documentId) {
            const res = await getDocumentTypeInfo(documentId);
            const flag = res?.docTypeConfig.allowReviseByAnyone
                ? res?.docTypeConfig.allowReviseByAnyone
                : false;
            const readFlag = res?.docTypeConfig.allowViewingInProcessDocByAnyone
                ? res?.docTypeConfig.allowViewingInProcessDocByAnyone
                : false;
            setAllowReviseFlag(flag);
            setAllowViewInProcessDocFlag(readFlag);
            const isDocumentDetails = history.location.pathname === "/document-details";
            const response = await getDocument(documentId, flag, isDocumentDetails);
            if (response?.apiStatus === 'SUCCESS') {
                setDocVersions(response.documentInfo.versions);
                // Incase the document is selected through Search Bar , it should show approvedDocument Version
                if (
                    (history.location.search.includes('prev_page_type=SEARCH') ||
                        flag ||
                        readFlag) &&
                    (response.documentInfo.state === 'DRAFT' ||
                        response.documentInfo.state === 'UNDER_REVIEW' ||
                        response.documentInfo.state === 'REJECTED') &&
                    history.location.pathname !== '/create-document' &&
                    user.id !== response.documentInfo.activeDocumentVersionOwnerId
                ) {
                    const selectedVersion = response.documentInfo.versions.filter(
                        (version) =>
                            version.value === response.documentInfo.approvedDocumentVersion,
                    );

                    if (selectedVersion.length > 0) {
                        setSelectedDocVersion(selectedVersion[0]);
                    } else {
                        setSelectedDocVersion(response.documentInfo.activeDocumentVersion);
                    }
                } else {
                    setSelectedDocVersion(response.documentInfo.activeDocumentVersion);
                }

                if (query.get('docVersionId')) {
                    const selectedVersion = response.documentInfo.versions.filter(
                        (version) => version.value === Number(query.get('docVersionId')),
                    );
                    if (selectedVersion.length > 0) {
                        setSelectedDocVersion(selectedVersion[0]);
                    }
                    // setSelectedDocVersion(state.activeVersionId)
                }
                setActiveDocVersionId(response.documentInfo.activeDocumentVersion.value as number);
                setIsDocFetched(true);
                setAttachments([]);
            } else if (response?.apiStatus === 'UNAUTHORIZED') {
                setCanViewDocumentDetails(false);
                setIsDocFetched(false);
            } else {
                toast('Something went wrong while fetching document details');
                setIsDocFetched(false);
            }
        }
    };

    const changeApprovlStatusForTravelForm = async (email : string, body : any) => {
        const res = await updateApprovlStatusForTravelForm(email, documentId, body);
        if(res){
            if(documentDispatch){
                documentDispatch({ type: 'text', inputName: 'department', value: res.department})
            }
            const isDocumentDetails = history.location.pathname === "/document-details";
            const response = await getDocumentVersion(
                documentId,
                selectedDocVersion.value as number,
                user.id,
                allowReviseFlag,
                isDocumentDetails,
            );
            if(response){
                approversDispatch({
                    type: 'update',
                    value: response.approvers,
                });
            }
        }
        // await saveFieldsData(false);
    };

    const changeApprovalStatusForLPNF = async (email : string) => {
        const res = await updateApprovalStatusForLPNF(email, documentId);
        if(res){
            const isDocumentDetails = history.location.pathname === "/document-details";
            const response = await getDocumentVersion(
                documentId,
                selectedDocVersion.value as number,
                user.id,
                allowReviseFlag,
                isDocumentDetails,
            );
            if(response){
                approversDispatch({
                    type: 'update',
                    value: response.approvers,
                });
            }
        }
    }

    useEffect(() => {
        if(isItACustomaerMeeting){
            makeFieldMandatory('customerName');
        } else {
            makeFieldNotMandatory('customerName');
        }
    }, [isItACustomaerMeeting]);

    useEffect(() => {
        if(travelRequestFor.value === STATIC_CONTENT.TRAVEL_FORM.TRAVEL_REQUEST_FOR.OTHER_EMPLOYEE){
            makeFieldMandatory('traveller');
            makeFieldNotMandatory('guestName');
        } else if(travelRequestFor.value === STATIC_CONTENT.TRAVEL_FORM.TRAVEL_REQUEST_FOR.GUEST){
            makeFieldMandatory('guestName');
            makeFieldNotMandatory('traveller');
        } else if(travelRequestFor.value === STATIC_CONTENT.TRAVEL_FORM.TRAVEL_REQUEST_FOR.SELF){
            makeFieldNotMandatory('traveller');
            makeFieldNotMandatory('guestName');
        }
    }, [travelRequestFor]);

    useEffect(() => {
        fetchDocument();
    }, [documentId]);

    useEffect(() => {
        if (docTypeInfo.isConfidential) {
            setDocInfo({ ...docInfo, confidential: true });
        }
    }, [docTypeInfo.isConfidential]);

    const fetchAttachments = async () => {
        const res = await getAttachments(
            selectedDocVersion.value as number,
            docTypeInfo.allowReviseByAnyone,
        );
        if (res?.apiStatus === 'SUCCESS') {
            setAttachments(res.attachments);
        }
    };

    useEffect(() => {
        if (docTypeInfo.isConfidential) {
            setDocInfo({ ...docInfo, confidential: true });
        }
    }, [docTypeInfo.isConfidential]);

    useEffect(() => {
        if (docTitleError) {
            setDocTitleError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo.title]);

    useEffect(() => {
        if (docDescError) {
            setDocDescError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo.description]);

    useEffect(() => {
        if (docEffectiveFromError) {
            setdocEffectiveFromError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo.effectiveFrom]);

    useEffect(() => {
        if (docSunsetError) {
            setDocSunsetError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo.sunsetPeriod]);

    useEffect(() => {
        if (docEffectiveError) {
            setDocEffectiveError(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docInfo.effectivePeriod]);

    useEffect(() => {
        if (isDocFetched) fetchAttachments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDocFetched]);

    useEffect(() => {
        const queryObj = qs.parse(search, { ignoreQueryPrefix: true });
        setDocumentId(Number(queryObj.doc_id ? queryObj.doc_id : 0));
        setSelectedDocVersion({ label: '', value: 0 });
        setActiveStep('STEP1');
    }, [search]);

    const findGroupMember = async () => {
            const list: number[] = [];
            const approverList: any = [];
            if (approvers.length > 0) {
                // eslint-disable-next-line no-restricted-syntax
                for (const approver of approvers) {
                    approverList.push(...approver.approvers);
                }
            }
            // eslint-disable-next-line no-restricted-syntax
            for (const approverData of approverList) {
                if (Object.prototype.hasOwnProperty.call(approverData, 'groupId')) {
                    list.push(approverData.groupId);
                }
            }
            if (list.length > 0) {
                const res = await getUserGroups();
                if (res?.apiStatus === 'SUCCESS') {
                    const data = res.myGroups;
                    const GroupList = data.map((d) => d.id);
                    const isFounded = list.some((ai) => GroupList.includes(ai));
                    setIsGroupMember(isFounded);
                }
            }
    };

    const fetchApprovers = async () => {
        if (taskId) {
            const res = await getTaskDetails(taskId, user.id);
            if (res) {
                approversDispatch({ type: 'update', value: res.approvers });
            }
        }
    };

    useEffect(() => {
        findGroupMember();
        fetchApprovers();
    }, [hideUsers]);


    return (
        <newDocumentContext.Provider
            value={{
                documentId,
                docInfo,
                fields,
                fieldsData,
                reviewers,
                approvers,
                externalApprovers,
                activeStep,
                submitDocStatus,
                userType,
                docVersions,
                selectedDocVersion,
                attachmentConfig,
                attachments,
                error,
                docTitleError,
                docDescError,
                docEffectiveFromError,
                docEffectiveError,
                docSunsetError,
                hideUsers,
                docTypeInfo,
                activeDocVersionId,
                canViewCreateDocument,
                canViewDocumentDetails,
                documentDispatch,
                reviewersDispatch,
                approversDispatch,
                externalApproversDispatch,
                saveFieldsData,
                isFieldsDataSaved,
                setActiveStep,
                onDocumentSubmit,
                setDocInfo,
                setSelectedDocVersion,
                fetchDocumentVersion,
                fetchDocument,
                fetchAttachments,
                setIsFileUploading,
                setHideUsers,
                accessibleByCurrentUser,
                isMemo,
                taskId,
                publishOnceApproved,
                isSunsetEnabled,
                isPublishLimitReached,
                isPRB,
                activeVersionOwnerInfo,
                checkInVersionOwner,
                allowViewInProcessDocFlag,
                versionNumberInfo,
                isLeadershipForm,
                documentTypeId,
                submittedOn,
                dateErrorInfo,
                isEndDateAsked,
                isICMP,
                hideFunctionalAreaICMP,
                setHideCurrentGradeICMP,
                setHideFunctionalAreaICMP,
                hideCurrentGradeICMP,
                isTravelAuthorizationForm,
                isItACustomaerMeeting,
                setIsItACustomaerMeeting,
                travelRequestFor,
                setTravelRequestFor,
                changeApprovlStatusForTravelForm,
                disableApprovalFlow,
                setDisableApprovalFlow,
                activeApprovalStatusId,
                isGroupMember,
                existingContract,
                setExistingContract,
                changeApprovalStatusForLPNF,
            }}
            {...props}
        />
    );
};

export const useDocumentContext = (): IDocumentContext => useContext(newDocumentContext);

export default DocumentProvider;
