/* eslint-disable max-lines-per-function */
import { IOptionValue, ITravelForm } from '../interfaces';
import { IFieldsData, IDocReferenceInfo } from '../interfaces/document';

export type IDocumentAction =
    | { type: 'push'; value: IFieldsData[] }
    | { type: 'text'; inputName: string; value: string }
    | { type: 'text-box'; inputName: string; value: string }
    | { type: 'single-select-dropdown'; inputName: string; value: IOptionValue[] }
    | { type: 'number'; inputName: string; value: number }
    | { type: 'date-picker'; inputName: string; value: Date }
    | { type: 'date-picker-date-only'; inputName: string; value: string }
    | { type: 'multi-select-dropdown'; inputName: string; value: IOptionValue[] }
    | { type: 'text-box-with-chip'; inputName: string; value: string[] }
    | { type: 'toggle'; inputName: string; value: boolean }
    | { type: 'button-select'; inputName: string; value: string }
    | {type : 'reset-single-field'; inputName: string; value: (string | number)}
    | { type: 'document-reference'; inputName: string; value: IDocReferenceInfo[] }
    | { type: 'reference'; inputName: string; value: (string | number)[] }
    | { type: 'travel-form'; inputName: string; value: ITravelForm };
export const taskdocumentReducer = (
    state: IFieldsData[],
    action: IDocumentAction,
): IFieldsData[] => {
    switch (action.type) {
        case 'push':
            return action.value;
        case 'text':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
            
        case 'text-box':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'reset-single-field':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'single-select-dropdown':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'multi-select-dropdown':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'number':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'toggle':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'date-picker':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'date-picker-date-only':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'text-box-with-chip':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'button-select':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'document-reference':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'reference':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'travel-form':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        default:
            return state;
    }
};
