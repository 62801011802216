/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, createContext } from 'react';

import './DocumentDetails.scss';
import ViewAttachments from '../../components/attachments/view/ViewAttachments';
import DocumentAction from '../../components/document-action/DocumentAction';
import ImportantFields from '../../components/important-fields/ImportantFields';
import ImportantFieldsEditable from '../../components/important-fields/ImportantFieldsEditable';
import DocumentProvider, { useDocumentContext } from '../../contexts/document-provider';
import DocComments from '../../widgets/doc-comment/DocComments';
import DocDetailTimeline from '../../widgets/doc-detail-events/DocDetailEvents';
import DocumentInfo from '../../widgets/document-info/DocumentInfo';
import { ExternalStageCard, StageCard } from '../../components/work-flow-panel/WorkFlowPanel';
import Reviewers from '../../components/reviewers/Reviewers';
import { NoPermission } from '../../components/General';
import EventsHeightProvider, { useEventsHeight } from '../../contexts/events-height-provider';
import { useAuthDataContext } from '../../contexts/user-auth-provider';

const Sidebar = ({ height, documentId, allowReviseByAnyone }: { height: number; documentId: number; allowReviseByAnyone: boolean; }): JSX.Element => (
    <div className="sidebar" style={{ height: `${height}px` }}>
        <DocDetailTimeline docId={documentId} allowReviseByAnyone={allowReviseByAnyone} />
    </div>
);
export const HeightContext = createContext({ updatePosition: () => {} });

// eslint-disable-next-line max-lines-per-function
const DocumentDetails: React.FC = () => {
    const {
        docInfo,
        userType,
        fields,
        fieldsData,
        documentId,
        selectedDocVersion,
        docVersions,
        approvers,
        externalApprovers,
        reviewers,
        docTypeInfo,
        activeDocVersionId,
        attachmentConfig,
        setSelectedDocVersion,
        setDocInfo,
        hideUsers,
        setHideUsers,
        fetchDocumentVersion,
        canViewDocumentDetails,
        documentDispatch,
        publishOnceApproved,
        isSunsetEnabled,
        activeVersionOwnerInfo,
        checkInVersionOwner,
        isEndDateAsked,
        isGroupMember,
        externalApproversDispatch,
        approversDispatch
    } = useDocumentContext();
    const { heightRef, height, updateHeight } = useEventsHeight();
    const isDocusignTriggered = approvers[0]?.approvers[0]?.docuSignTriggered
    ? approvers[0].approvers[0].docuSignTriggered
    : false;
    useEffect(() => {
        updateHeight();
    });
    // useEffect(()=>{console.log(approvers)},[approvers]);
    const { user } = useAuthDataContext();
    let latestDocVersion = 0;
    docVersions.forEach((docVersion) => {
        const versionNumber = parseFloat(docVersion.label);
        if (versionNumber > latestDocVersion) {
            latestDocVersion = versionNumber;
        }
    });
    const [unsavedComment, setUnsavedComment] = useState('');
    const setUnsavedCommentFunction = (comment: string) => {
        setUnsavedComment(comment);
    };

    return (
        <>
            {canViewDocumentDetails ? (
                <>
                    <DocumentInfo
                        pageType="DOCUMENT_DETAIL"
                        docInfo={docInfo}
                        documentId={documentId}
                        activeDocVersion={selectedDocVersion}
                        latestDocVersionId={activeDocVersionId}
                        docVersions={docVersions}
                        setActiveDocVersion={setSelectedDocVersion}
                        setDocInfo={setDocInfo}
                        onFavoriteClickHAndler={fetchDocumentVersion}
                        descError={false}
                        titleError={false}
                        effectiveFromError={false}
                        effectivePeriodError={false}
                        sunsetPeriodError={false}
                        docTypeInfo={docTypeInfo}
                        publishOnceApproved={publishOnceApproved}
                        isSunsetEnabled={isSunsetEnabled}
                        activeVersionOwnerInfo={activeVersionOwnerInfo}
                        checkInVersionOwner={checkInVersionOwner}
                        isEndDateAsked={isEndDateAsked}
                    />
                    <div className="document-details__section">
                        <Sidebar height={height} documentId={documentId} allowReviseByAnyone={docTypeInfo.allowReviseByAnyone || true} />
                        <div className="main-section" id="docDetailMain" ref={heightRef}>
                            <div className="document-details">
                                <div className="document-details-header">
                                    <h3>Document Details</h3>
                                    <div>
                                        {Number(selectedDocVersion.value) <
                                            activeDocVersionId && docInfo.docState === 'PUBLISHED' && (
                                            <label>*viewing an older version</label>
                                        )}
                                    </div>
                                </div>
                                {docTypeInfo.title === 'MEMO' &&
                                docInfo.docState === 'PUBLISHED' &&
                                docInfo.confidential &&
                                docInfo.author.id === user.id ? (
                                    <ImportantFieldsEditable
                                        documentId={documentId}
                                        isDisabled
                                        fields={fields}
                                        fieldsData={fieldsData}
                                        documentDispatch={documentDispatch}
                                    />
                                ) : (
                                    <ImportantFields
                                        documentId={documentId}
                                        documentVersionId={selectedDocVersion.value as number}
                                        isDisabled
                                        fields={fields}
                                        fieldsData={fieldsData}
                                        documentDispatch={null}
                                    />
                                )}
                            </div>
                            {selectedDocVersion.value && (
                                <ViewAttachments
                                    activeDocVersion={selectedDocVersion.value as number}
                                    attachmentConfig={attachmentConfig}
                                    isDocFetched
                                    allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                                />
                            )}
                            {approvers.length > 0 &&
                                            approvers[0].approvers.length > 0 && (
                                                <h3>{docTypeInfo.approversSectionTitle ? docTypeInfo.approversSectionTitle : 'Approvers'}</h3>
                                            )}
                            {approvers.length > 0 && (
                                <div className="approvers">
                                    <div className="approvers-tab tab-data">
                                        {/* eslint-disable-next-line max-lines-per-function */}
                                        {approvers.map((approver, index) => (
                                            <>
                                                {approver.approvers.length > 0 ? (
                                                    docTypeInfo.isContract && approver.scopeType ===
                                                    'groupBased' ? (
                                                        <>
                                                            <StageCard
                                                                stageIndex={index}
                                                                error={false}
                                                                stageData={approver}
                                                                isEditable={(isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isContract && !isDocusignTriggered)}
                                                                // eslint-disable-next-line react/no-array-index-key
                                                                key={index}
                                                                cardTitle={
                                                                    approver?.approvalStageTitle
                                                                        ? approver.approvalStageTitle
                                                                        : ``
                                                                }
                                                                docInfo={docInfo}
                                                                docTypeInfo={docTypeInfo}
                                                                approversDispatch={approversDispatch}
                                                            />
                                                            {externalApprovers?.length ? <ExternalStageCard externalApprovers={ externalApprovers } isEditable={isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isContract && !isDocusignTriggered} docTypeInfo={docTypeInfo} externalApproversDispatch={externalApproversDispatch}
                                                                                docInfo={docInfo}/> : null}
                                                        </>
                                                    ) : (
                                                        <StageCard
                                                            stageIndex={index}
                                                            error={false}
                                                            stageData={approver}
                                                            isEditable={(isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isContract && !isDocusignTriggered)}
                                                            // eslint-disable-next-line react/no-array-index-key
                                                            key={index}
                                                            cardTitle={
                                                                approver?.approvalStageTitle
                                                                    ? approver.approvalStageTitle
                                                                    : ``
                                                            }
                                                            docInfo={docInfo}
                                                            docTypeInfo={docTypeInfo}
                                                            approversDispatch={approversDispatch}
                                                        />
                                                    )
                                                ) : null}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {reviewers.length > 0 && (
                                <div className="reviewers">
                                    <h3>Reviewers</h3>
                                    <Reviewers isEditable={false} />
                                </div>
                            )}
                            {
                            // userType !== 'OTHER' &&
                            (
                                <div className="comments">
                                    <DocComments
                                        activeDocVersion={selectedDocVersion.value as number}
                                        setUnsavedCommentFunction={setUnsavedCommentFunction}
                                        allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                                        userType={userType}
                                    />
                                </div>
                            )}
                            {(userType === 'CREATOR' || docTypeInfo.allowReviseByAnyone) &&
                                ((docInfo.docState === 'UNDER_REVIEW' && !docInfo.approvedOn) ||
                                    docInfo.docState === 'PUBLISHED' ||
                                    (docInfo.docState === 'SUNSET' &&
                                        activeDocVersionId === selectedDocVersion.value)) && (
                                    <DocumentAction
                                        docState={docInfo.activeDocumentState}
                                        documentId={documentId}
                                        userType={userType}
                                        activeDocumentVersion={selectedDocVersion.value as number}
                                        hideUsers={hideUsers}
                                        setHideUsers={setHideUsers}
                                        unsavedComment={unsavedComment}
                                        setUnsavedCommentFunction={setUnsavedCommentFunction}
                                        render={docInfo.render}
                                        assignee={docInfo.assignee}
                                        isSunsetEnabled={isSunsetEnabled}
                                        autoRenew={docInfo.autoRenew}
                                        docInfo={docInfo}
                                        setDocInfo={setDocInfo}
                                        isEndDateAsked={isEndDateAsked}
                                        isDocusignTriggered={approvers[0]?.approvers[0]?.docuSignTriggered ? approvers[0].approvers[0].docuSignTriggered : false}
                                    />
                                )}
                            {/* {docTypeInfo.allowReviseByAnyone && docInfo.docState === 'DRAFT' && docInfo.approvedVersionId === selectedDocVersion.value  && (
                                <div className="check-out-info">
                                    NOTE: This document has been checked out by: {activeVersionOwnerInfo.employeeName} <a href={`mailto:${activeVersionOwnerInfo.email}`}>{activeVersionOwnerInfo.email}</a>
                                </div>
                            )} */}
                        </div>
                    </div>
                </>
            ) : (
                <NoPermission />
            )}
        </>
    );
};

const DocumentDetailsPage: React.FC = () => (
    <div className="document-details">
        <DocumentProvider>
            <EventsHeightProvider>
                <DocumentDetails />
            </EventsHeightProvider>
        </DocumentProvider>
    </div>
);

export default DocumentDetailsPage;
