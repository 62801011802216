import React, { useEffect, useRef, useState } from 'react';

import './TextBox.scss';
import { IField } from '../../../interfaces/document';
import { IDocumentAction } from '../../../reducers/document-reducer';
import { useDocumentContext } from '../../../contexts/document-provider';

const TEXT_ARE_LINE_HEIGHT = 22;
const DIFFERENCE_TO_TEXTAREA = 10;
const MAX_LINES_IN_TEXTAREA = 4;

interface Props {
    field: IField;
    isDisabled: boolean;
    value: string;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

// eslint-disable-next-line max-lines-per-function
const TextBox: React.FC<Props> = ({ field, isDisabled, value, documentDispatch, error }: Props) => {
    const {isPRB} = useDocumentContext(); 
    const target = useRef<HTMLTextAreaElement>(null);
    const maxLimit = 230;
    const [CharCount, setCount] = useState(maxLimit);
    const numberRowsInTextarea = (scrollHeight: number): number => {
        const height = scrollHeight - DIFFERENCE_TO_TEXTAREA;
        const count = Math.round(height / TEXT_ARE_LINE_HEIGHT);
        return count > MAX_LINES_IN_TEXTAREA ? MAX_LINES_IN_TEXTAREA : count;
    };

    useEffect(() => {
        if (target.current !== null) {
            target.current.rows = numberRowsInTextarea(target.current.scrollHeight);
        }
    }, [value]);

    return isPRB ? (
        <div>
        <textarea
            className={`input-textbox ${error ? 'error' : ''}`}
            disabled={isDisabled}
            name={field.name}
            value={value}
            onChange={(e) =>
                {
                    setCount(maxLimit - e.target.value.length);
                    return (
                        documentDispatch &&
                        documentDispatch({
                            type: 'text-box',
                            inputName: field.name,
                            value: e.target.value,
                        })
                    );
                }
            }
            ref={target}
            maxLength={maxLimit}
        />
        <span style={{'fontSize': 10}}>* remaining characters {CharCount}</span>
        </div>
    ) : (
        <textarea
            className={`input-textbox ${error ? 'error' : ''}`}
            disabled={isDisabled}
            name={field.name}
            value={value}
            onChange={(e) =>
                documentDispatch &&
                documentDispatch({ type: 'text-box', inputName: field.name, value: e.target.value })
            }
            ref={target}
        />
    );
};

export default TextBox;
