import React from 'react'
import CONSTANTS from '../../constants';
import { IDocChecklistSteps, IError } from '../../interfaces/document';
import NewDocMain from '../../components/new-doc-main/NewDocMain';

import './NewDocDetail.scss';
import { useDocumentContext } from '../../contexts/document-provider';
import useWindowSize from "../../hooks/use-window-size";

interface IDockCheckList {
    activeStep: IDocChecklistSteps;
    error: IError;
    onStepChange: React.Dispatch<React.SetStateAction<IDocChecklistSteps>>;
}

const DocChecklist: React.FC<IDockCheckList> = ({
    activeStep,
    error,
    onStepChange,
}: IDockCheckList) => {
    const { attachmentConfig, docTypeInfo , dateErrorInfo} = useDocumentContext();
    let STEPS = {};
    
    if(docTypeInfo.allowDCO) {
        STEPS = attachmentConfig.length === 0 ? CONSTANTS.NEW_DOC_STEP_WITH_DCO_ENABLED_AND_WITHOUT_ATTACHMENTS : CONSTANTS.NEW_DOC_STEP_WITH_DCO_ENABLED_AND_ATTACHMENTS;
    } else {
        STEPS = attachmentConfig.length === 0 ? CONSTANTS.NEW_DOC_STEP_WITH_NO_ATTACHMENTS : CONSTANTS.NEW_DOC_STEP;
    }
    const steps = Object.entries(STEPS).map((step) => ({ key: step[0] as IDocChecklistSteps, name: step[1] as string}));

    return (
        <div className="doc-checklist">
            <h2>Document Checklist</h2>
            <div className="list">
                {steps.map((step, index) => (
                    <div
                        key={step.key}
                        className={`row ${activeStep === step.key ? 'active' : ''} ${
                            error[step.key].isValid ? '' : 'checklist-error'
                        }`}
                        onClick={() => onStepChange(step.key)}
                        role="presentation"
                    >
                        <div className="circle">
                            <h5>{index + 1}</h5>
                        </div>
                        <h5>{step.name}</h5>
                    </div>
                ))}
            </div>
        </div>
    );
};

const NewDocDetail: React.FC = () => {
    const { activeStep, setActiveStep, error } = useDocumentContext();
    const size=useWindowSize();
    return (
        <div className="new-doc-detail">
            {size.width>=576?<DocChecklist activeStep={activeStep} error={error} onStepChange={setActiveStep} />:null}
            <NewDocMain isDisabled={false} />
        </div>
    );
};

export default NewDocDetail
