/* eslint-disable max-lines-per-function */
import React, { createContext, useState, useEffect, useContext } from 'react';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router-dom';

import { IUser } from '../interfaces/user';
import { convertToEpoch } from '../utils';
import { getUserFromMail } from '../API/users';
import { useGAUser } from '../hooks/useGoogleAnalytics';
import { getFromInMemory, setInMemory } from '../utils/in-memory-storage';

const initialAuthData: IUser = { id: -1, name: '', role: '', mail: '', isAdmin: false,  isClmAdmin: false, jobTitle: ''};
const noop = () => {};

interface IUserAuthContext {
    user: IUser;
    onLogin: () => void;
    onLogout: () => void;
    tokenValid: boolean;
    isLoading: boolean;
    getUser: () => void;
}
export const AuthDataContext = createContext<IUserAuthContext>({
    user: initialAuthData,
    onLogin: noop,
    onLogout: noop,
    tokenValid: false,
    isLoading: true,
    getUser: () => null,
});

const getUserMailId = (): string => {
    let userMailId = '';
    const tokenStr = getFromInMemory();
    if (tokenStr) {
        const token = jwt.decode(tokenStr) as { exp: number; unique_name: string };
        if (token) {
            if (typeof token.exp === 'undefined' || token.exp < convertToEpoch(new Date()) / 1000) {
                userMailId = '';
            }
            userMailId = token.unique_name;
        }
    }

    return userMailId;
};

const AuthenticationProvider: React.FC = (props: unknown) => {
    const mailId = getUserMailId();
    const [user, setUser] = useState(initialAuthData);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();

    const getUser = async () => {
        const tokenStr = getFromInMemory();
        if (tokenStr) {
            const token = jwt.decode(tokenStr) as { exp: number; unique_name: string };
            if (token) {
                const userInfo = await getUserFromMail(token.unique_name);
                if (userInfo?.apiStatus === 'SUCCESS') {
                    setUser(userInfo.user);
                } else if (userInfo?.apiStatus === 'UNAUTHORIZED') {
                    history.push('/401');
                }
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        if (user) useGAUser(user.id);
    }, [user]);

    const onLogout = () => {
        setInMemory('');
        window.location.assign(`${process.env.REACT_APP_LOG_OUT_REDIRECT}`);
        // setUser(initialAuthData);
    };

    const onLogin = () => {
        setIsLoading(true);
        getUser();
    };

    return (
        <AuthDataContext.Provider
            value={{ user, onLogin, onLogout, tokenValid: !!mailId, isLoading, getUser }}
            {...props}
        />
    );
};

export const useAuthDataContext = (): IUserAuthContext => useContext(AuthDataContext);

export default AuthenticationProvider;
