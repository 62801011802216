import React from 'react';
import NewDocDetail from '../../widgets/new-doc-detail/NewDocDetail';
import DocumentInfo from '../../widgets/document-info/DocumentInfo';
import DocumentProvider, { useDocumentContext } from '../../contexts/document-provider';
import './CreateDocument.scss';
import { InActiveDocument,NoPermission, NoEditPermission } from '../../components/General';

// eslint-disable-next-line max-lines-per-function
const CreateDocument: React.FC = () => {
    const {
        docInfo,
        docTypeInfo,
        documentTypeId,
        documentId,
        selectedDocVersion,
        activeDocVersionId,
        docVersions,
        docTitleError,
        docDescError,
        docEffectiveFromError,
        docEffectiveError,
        docSunsetError,
        setSelectedDocVersion,
        setDocInfo,
        fetchDocumentVersion,
        canViewCreateDocument,
        accessibleByCurrentUser,
        publishOnceApproved,
        isSunsetEnabled,
        activeVersionOwnerInfo,
        isEndDateAsked,
    } = useDocumentContext();
    return (
        <>
            {documentTypeId.toString() !== process.env.REACT_APP_INACTIVE_DOCUMENT_TYPE_ID || docInfo.docNumber !== "Not Assigned" ? (
                <>
                {canViewCreateDocument || accessibleByCurrentUser ? (
                    <>
                        {' '}
                        <DocumentInfo
                            pageType={accessibleByCurrentUser ? 'EDIT_DOCUMENT' : 'CREATE_DOCUMENT'}
                            docInfo={docInfo}
                            documentId={documentId}
                            activeDocVersion={selectedDocVersion}
                            docVersions={docVersions}
                            setActiveDocVersion={setSelectedDocVersion}
                            setDocInfo={setDocInfo}
                            onFavoriteClickHAndler={fetchDocumentVersion}
                            titleError={docTitleError}
                            descError={docDescError}
                            effectiveFromError={docEffectiveFromError}
                            effectivePeriodError={docEffectiveError}
                            sunsetPeriodError={docSunsetError}
                            docTypeInfo={docTypeInfo}
                            publishOnceApproved={publishOnceApproved}
                            isSunsetEnabled={isSunsetEnabled}
                            latestDocVersionId={activeDocVersionId}
                            activeVersionOwnerInfo={activeVersionOwnerInfo}
                            isEndDateAsked = {isEndDateAsked}
                        />
                        <NewDocDetail />
                    </>
                ) : (
                    <NoPermission />
                )}
            </>
            ):(
                <InActiveDocument />
            )}
        </>
    );
};

const CreateDocumentPage: React.FC = () => (
    <div className="new-document">
        <DocumentProvider>
            <CreateDocument />
        </DocumentProvider>
    </div>
);

export default CreateDocumentPage;
