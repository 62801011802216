/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
    DEFAULT_ADVANCED_SEARCH,
    DEFAULT_DOC_TYPE,
    useSearch,
} from '../../contexts/search-provider';
import { IAdditionalSearch, IDocTypeSearch } from '../../interfaces';
import './SearchedParams.scss';

// eslint-disable-next-line max-lines-per-function
const SearchedParams: React.FC = () => {
    const { isLoading, searchString, advancedFilters, docType, filters } = useSearch();
    const [selectedFilters, setSelectedFilters] = useState<IAdditionalSearch[]>([]);
    const [selectedAdvancedFilters, setSelectedAdvancedFilters] =
        useState<IAdditionalSearch[]>(DEFAULT_ADVANCED_SEARCH);
    const [selectedSearchString, setSelectedSearchString] = useState('');
    const [selectedDocType, setSelectedDocType] = useState<IDocTypeSearch>(DEFAULT_DOC_TYPE);

    useEffect(() => {
        setSelectedSearchString(searchString);
        // ! MEMO-FIX : filtering documentNumber
        const a = advancedFilters.filter(
            (b) => !(b.name === 'documentNumber' && b.value === 'MEMO-'),
        );
        setSelectedAdvancedFilters(a);
        setSelectedDocType(docType);
        setSelectedFilters(filters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    return (
        <div className="searched-params">
            <h4>Searched for :</h4>
            {selectedSearchString && <span className="search-pill">{selectedSearchString}</span>}
            {selectedDocType?.value && (
                <span className="search-pill"> Document Type : {selectedDocType.label}</span>
            )}
            {selectedAdvancedFilters.map(
                (search) =>
                    search.value && (
                        <span className="search-pill">
                            {search.displayName} : {search.value}
                        </span>
                    ),
            )}
            {selectedFilters.map(
                (search) =>
                    search.value && (
                        <span className="search-pill">
                            Filter{' '}
                            {search.displayName === 'owner.name'
                                ? 'Author'
                                : search.displayName === 'documentNumber'
                                ? 'Document Number'
                                : search.displayName === 'index'
                                ? 'Document Type'
                                : search.displayName}{' '}
                            :{' '}
                            {search.displayName === 'Effective Date RangeEnd' ||
                            search.displayName === 'Effective Date RangeStart' ||
                            search.displayName === 'Custom Date RangeEnd' ||
                            search.displayName === 'Custom Date RangeStart'
                                ? `${`0${new Date(search.value).getMonth() + 1}`.slice(
                                      -2,
                                  )}-${`0${new Date(search.value).getDate()}`.slice(-2)}-${new Date(
                                      search.value,
                                  ).getFullYear()}`
                                : search.value}
                        </span>
                    ),
            )}
        </div>
    );
};

export default SearchedParams;
