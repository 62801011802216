import React, { useState } from 'react';
import ViewAttachments from '../../components/attachments/view/ViewAttachments';
import DocumentAction from '../../components/document-action/DocumentAction';
import { NoPermission } from '../../components/General';
import ImportantFields from '../../components/important-fields/ImportantFields';
import Reviewers from '../../components/reviewers/Reviewers';
import { ExternalStageCard, StageCard } from '../../components/work-flow-panel/WorkFlowPanel';
import TaskDetailProvider, { useTaskDetailContext } from '../../contexts/task-details-provider';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import DocComments from '../../widgets/doc-comment/DocComments';
import DocumentInfo from '../../widgets/document-info/DocumentInfo';

import './TaskDetails.scss';
import { useDocumentContext } from '../../contexts/document-provider';
import ImportantFieldsForTask from '../../components/important-fields/ImportantFieldsForTask';

// eslint-disable-next-line max-lines-per-function
const TaskDetails: React.FC = () => {
    const { user } = useAuthDataContext();
    const {
        docInfo,
        userType,
        fields,
        fieldsData,
        reviewers,
        approvers,
        externalApprovers,
        documentId,
        activeDocVersion,
        isTaskActive,
        setActiveDocVersion,
        setDocInfo,
        fetchDocumentVersion,
        canViewTaskDetails,
        hideUsers,
        collectComment,
        delegatorInfo,
        docTypeInfo,
        setHideUsers,
        taskId,
        attachmentConfig,
        isDocFetched,
        approverStatus,
        isGroupMember,
        accessibleByCurrentUser,
        publishOnceApproved,
        isSunsetEnabled,
        taskFieldsData,
        taskDocumentDispatch,
        activeVersionOwnerInfo,
        isLeadershipForm,
        isEndDateAsked,
        isTravelAuthorizationForm,
        externalApproversDispatch,
        approversDispatch
    } = useTaskDetailContext();
    const { activeDocVersionId } = useDocumentContext();
    const docTypeInfo1 = useTaskDetailContext().docTypeInfo;
    const [unsavedComment, setUnsavedComment] = useState('');
    const setUnsavedCommentFunction = (comment: string) => {
        setUnsavedComment(comment);
    };

    const isDocusignTriggered = approvers[0]?.approvers[0]?.docuSignTriggered
    ? approvers[0].approvers[0].docuSignTriggered
    : false;

    return (
        <>
            {canViewTaskDetails || isGroupMember ? (
                <>
                    <DocumentInfo
                        pageType="TASK_DETAILS"
                        docInfo={docInfo}
                        documentId={documentId}
                        activeDocVersion={activeDocVersion}
                        latestDocVersionId={activeDocVersionId}
                        docVersions={[]}
                        setActiveDocVersion={setActiveDocVersion}
                        setDocInfo={setDocInfo}
                        onFavoriteClickHAndler={fetchDocumentVersion}
                        descError={false}
                        titleError={false}
                        effectiveFromError={false}
                        effectivePeriodError={false}
                        sunsetPeriodError={false}
                        docTypeInfo={docTypeInfo}
                        delegatorInfo={delegatorInfo}
                        publishOnceApproved={publishOnceApproved}
                        isSunsetEnabled={isSunsetEnabled}
                        activeVersionOwnerInfo={activeVersionOwnerInfo}
                        isEndDateAsked={isEndDateAsked}
                    />
                    <div className="task-details__section">
                        <div className="main-section">
                            <div className="task-details">
                                <h3>Document Details</h3>
                                <ImportantFieldsForTask
                                    isDisabled
                                    fields={fields}
                                    fieldsData={taskFieldsData}
                                    documentDispatch={taskDocumentDispatch}
                                />
                            </div>
                            <ViewAttachments
                                activeDocVersion={activeDocVersion.value as number}
                                attachmentConfig={attachmentConfig}
                                isDocFetched={isDocFetched}
                                allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                            />
                            {approvers.length > 0 && approverStatus !== 'REJECTED' && (
                                <div className="approvers">
                                    {(approvers.length > 0 && approvers[0].approvers.length > 0) ||
                                        (externalApprovers.length > 0 && (
                                            <h3>{docTypeInfo1.approversSectionTitle ? docTypeInfo1.approversSectionTitle : 'Approvers'}</h3>
                                        ))}
                                    <div className="approvers-tab tab-data">
                                        { /* eslint-disable max-lines-per-function */
                                            approvers.map((approver, index) => (
                                            <>
                                                {(approver.approvers.length > 0) ? (
                                                    <>
                                                        {docTypeInfo1.isContract &&
                                                        approver.scopeType === 'groupBased' ? (
                                                            <>
                                                                <StageCard
                                                                    stageIndex={index}
                                                                    error={false}
                                                                    stageData={approver}
                                                                    isEditable={isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isContract && !isDocusignTriggered}
                                                                    // eslint-disable-next-line react/no-array-index-key
                                                                    key={index}
                                                                    cardTitle={approver.approvalStageTitle ? approver.approvalStageTitle : 'Select Approvers'}
                                                                    docInfo={docInfo}
                                                                    docTypeInfo={docTypeInfo}
                                                                    approversDispatch={approversDispatch}
                                                                />
                                                                {externalApprovers?.length ? <ExternalStageCard externalApprovers={ externalApprovers } isEditable={isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isContract && !isDocusignTriggered}  docTypeInfo={docTypeInfo} externalApproversDispatch={externalApproversDispatch} docInfo={docInfo}/> : null}
                                                            </>
                                                        ) : (
                                                            <StageCard
                                                                stageIndex={index}
                                                                error={false}
                                                                stageData={approver}
                                                                isEditable={isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isContract && !isDocusignTriggered}
                                                                // eslint-disable-next-line react/no-array-index-key
                                                                key={index}
                                                                cardTitle={
                                                                    approver?.approvalStageTitle
                                                                        ? approver.approvalStageTitle
                                                                        : ``
                                                                }
                                                                docInfo={docInfo}
                                                                docTypeInfo={docTypeInfo}
                                                                approversDispatch={approversDispatch}
                                                            />
                                                        )}
                                                    </>
                                                ) : null}
                                            </>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {reviewers.length > 0 && (
                                <div className="reviewers">
                                    <h3>Reviewers</h3>
                                    <Reviewers isEditable={false} />
                                </div>
                            )}
                            {userType !== 'OTHER' && isDocFetched && (
                                <div className="comments">
                                    <DocComments
                                        activeDocVersion={activeDocVersion.value as number}
                                        setUnsavedCommentFunction={setUnsavedCommentFunction}
                                        allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                                        userType={userType}
                                    />
                                </div>
                            )}
                            {isTaskActive && (
                                <DocumentAction
                                    docState={docInfo.docState}
                                    documentId={documentId}
                                    userType={userType}
                                    activeDocumentVersion={activeDocVersion.value as number}
                                    hideUsers={hideUsers}
                                    collectComment={collectComment}
                                    setHideUsers={setHideUsers}
                                    taskId={taskId}
                                    assigneeAndCreatorSame={(!isLeadershipForm && !isTravelAuthorizationForm) && user.id === docInfo.author.id}
                                    unsavedComment={unsavedComment}
                                    setUnsavedCommentFunction={setUnsavedCommentFunction}
                                    render={docInfo.render}
                                    assignee={docInfo.assignee}
                                    accessibleByCurrentUser={accessibleByCurrentUser}
                                    autoRenew={docInfo.autoRenew}
                                    docInfo={docInfo}
                                    setDocInfo={setDocInfo}
                                    isEndDateAsked={isEndDateAsked}
                                    isDocusignTriggered={approvers[0]?.approvers[0]?.docuSignTriggered ? approvers[0].approvers[0].docuSignTriggered : false}
                                />
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <NoPermission />
            )}
        </>
    );
};

const TaskDetailsPage: React.FC = () => (
    <div className="task-details">
        <TaskDetailProvider>
            <TaskDetails />
        </TaskDetailProvider>
    </div>
);

export default TaskDetailsPage;
