/* eslint-disable react/button-has-type */
import React from 'react';
import Loader from 'react-loader-spinner';

import './Button.scss';

interface IButtonProps {
    disabled: boolean;
    isLoading: boolean;
    className: string;
    onClick: () => void;
    type: 'button' | 'reset' | 'submit' | undefined;
    loaderColor?: string;
}

const Button: React.FC<IButtonProps> = ({
    disabled,
    isLoading,
    children,
    type,
    className,
    loaderColor,
    onClick,
}) => (
    <button
        type={type}
        className={`btn ${className}`}
        disabled={disabled}
        onClick={() => onClick()}
    >
        {children}
        {isLoading && (
            <Loader type="Oval" color={loaderColor || '#F37321'} height={14} width={14} />
        )}
    </button>
);

export default Button;
