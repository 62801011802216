import React from 'react'

import './Text.scss'
import { IField } from '../../../interfaces/document'
import { IDocumentAction } from '../../../reducers/document-reducer'

interface Props {
    field: IField;
    isDisabled: boolean;
    value: string;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

const Text: React.FC<Props> = ({ field, isDisabled, documentDispatch, value, error }: Props) => (
    <input
        className={`input-text ${error ? 'error' : ''}`}
        name={field.name}
        disabled={isDisabled}
        value={value}
        onChange={(e) =>
            documentDispatch &&
            documentDispatch({ type: 'text', inputName: field.name, value: e.target.value })
        }
        autoComplete="off"
    />
);

export default Text
