/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { IUserOptionValue } from '../../interfaces';
import './userSearchModal.scss';
import { fetchHirerarchy, userSearch } from '../../API/users';
import { PlusIconSvg } from '../../assets/images';
import CONSTANTS from '../../constants';
import STATIC_CONTENT from '../../constants/StaticContent';
import { useDocumentContext } from '../../contexts/document-provider';
import { useAuthDataContext } from '../../contexts/user-auth-provider';

const animatedComponents = makeAnimated();

const customStyles = {
    content: {
        // backgroundColor: '#000',
        bottom: 'auto',
        left: '50%',
        marginRight: '-50%',
        right: 'auto',
        top: '20%',
        transform: 'translate(-50%, -50%)',
    },
};

interface Props {
    isModalOpen: boolean;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    userHandler: (selectedUser: IUserOptionValue[], type: 'EDIT' | 'NEW') => void;
    type: 'EDIT' | 'NEW';
    hideUsers: number[];
    // eslint-disable-next-line react/require-default-props
    roleId?: number[];
    // eslint-disable-next-line react/require-default-props
    groupId?: number;
    // eslint-disable-next-line react/require-default-props
    setEditedApprovers?: React.Dispatch<React.SetStateAction<boolean>>;
}
Modal.setAppElement('#root');
const UserSearchModal: React.FC<Props> = ({
    isModalOpen,
    onModalClose,
    userHandler,
    roleId = [],
    groupId,
    hideUsers,
    type,
    setEditedApprovers
}: Props) => {
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const { user } = useAuthDataContext();
    const {isLeadershipForm , isTravelAuthorizationForm, fieldsData, travelRequestFor} = useDocumentContext();
    const [intialOptions, setIntialOptions] = useState<IUserOptionValue[]>([]);

    const promiseOptions = async (inputValue: string): Promise<IUserOptionValue[]> => {
        setLoading(true);
        setSearchText(inputValue);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if(isTravelAuthorizationForm){
                const filteredItems = intialOptions.filter(item => item.label.startsWith(inputValue));
                if(filteredItems.length > 0){
                    resolve(filteredItems);
                } else{
                    resolve(intialOptions);
                }
                setLoading(false);
                return;
            }
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await userSearch(inputValue, roleId, undefined, undefined, groupId);
                if (users?.apiStatus === 'SUCCESS') {
                    let userList;
                    if (isLeadershipForm) {
                        userList = users.users.filter((u) => u.value as number);
                    } else {
                        userList = users.users.filter(
                            (u) => !hideUsers.includes(u.value as number),
                        );
                    }
                    if((userList.length > 0) && (inputValue.indexOf('<') > -1) || (inputValue.indexOf(',') > -1)){
                        userHandler(userList, type);
                    } 
                    resolve(userList);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        const loadIntialOptions = async () => {
            if(!isTravelAuthorizationForm){
                setIntialOptions([]);
                return;
            }
            let traveller = '';
            if(travelRequestFor.value === STATIC_CONTENT.TRAVEL_FORM.TRAVEL_REQUEST_FOR.OTHER_EMPLOYEE){
                const travellerDetails = fieldsData.filter((item) => item.name === 'traveller');
                traveller = travellerDetails[0].value[0].value;
            } else {
                traveller = user.mail;
            }
            const users = await fetchHirerarchy(traveller);
            if (users?.apiStatus === 'SUCCESS') {
                const userList = users.users.filter((u) => u.value as number);
                setIntialOptions(userList);
            }
        }
        loadIntialOptions();
    },[]);

    return (
        <div className="user-search-modal">
            <Modal
                isOpen={isModalOpen}
                style={customStyles}
                onRequestClose={() => onModalClose(false)}
                contentLabel="Example Modal"
                className="user-search__modal"
            >
                <PlusIconSvg className="close-btn" onClick={() => {
                    if(setEditedApprovers)
                    {
                        setEditedApprovers(false)
                    }
                    onModalClose(false)
                }} />
                <label htmlFor="user-search">{STATIC_CONTENT.USER_SEARCH.LABEL.SELECT_USERS}</label>
                <span className="note">Length should be greater than 3 characters</span>
                <AsyncSelect
                    name="user-search"
                    id="user-search"
                    components={animatedComponents}
                    isLoading={isLoading}
                    isMulti
                    placeholder="Search user..."
                    className="react-select user-search"
                    defaultOptions={intialOptions}
                    classNamePrefix="select"
                    loadOptions={promiseOptions}
                    getOptionLabel={(e) => `${e.label} | ${e.jobTitle}`}
                    noOptionsMessage={() => searchText.length < 3 ? 'Enter minimum 3 characters' : 'No results found'}
                    onChange={(options) => {
                        userHandler(options as IUserOptionValue[], type);
                        setSearchText('')
                    }}
                />
            </Modal>
        </div>
    );
};

export default UserSearchModal;
