import React from 'react';
import ContractsStatusCount from '../../components/contracts/ContractsStatusCount';
import Button from '../../components/button/Button';
import ListAllContracts from '../../components/contracts/ListAllContracts';
import './ContractsDashboard.scss';

const ContractsDashboard: React.FC = () => (
    <div className="contracts-dashboard">
        <div className="status-panel">
            <ContractsStatusCount />
        </div>

        <div className="contract-panel admin-panel">
            <div className="admin-panel-header">
                <h2 className="admin-panel-heading">Contracts</h2>
                <Button
                    disabled={false}
                    isLoading={false}
                    className="viewMore"
                    onClick={() => {
                        window.location.href = `${process.env.PUBLIC_URL}/clm/contracts`;
                    }}
                    type="button"
                >
                    View All Contracts
                </Button>
            </div>
            <div className="admin-panel-content">
                <ListAllContracts />
            </div>
        </div>

    </div>
);

export default ContractsDashboard;