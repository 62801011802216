/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
import Skeleton from 'react-loading-skeleton';

import { Upload } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { Link, useHistory, useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from 'react-loader-spinner';
import Toggle from '../../components/toggle/Toggle';
import './DocumentInfo.scss';
import { IButtonStatus, IDocStatus, IOptionValue, IOwnerChange, IPageType, IUserOptionValue } from '../../interfaces';
import CONSTANTS from '../../constants';
import { IDocumentActionTypes, IDocumentInfo } from '../../interfaces/document';
import { documentAction, exportPDF, downloadPDF, getDocumentRelationsInfo } from '../../API/document';
import { FavoriteComponent } from '../../components/General';
import CopyText from '../../components/CopyText';
import ConfirmationModal, {
    IAcceptedType,
} from '../../components/confirmation-modal/ConfirmationModal';
import { IDocumentTypeInfo } from '../../interfaces/document-type';
import STATIC_CONTENT from '../../constants/StaticContent';
import { SkeletonText } from '../../components/Skeleton';
import Button from '../../components/button/Button';
import { formatDate } from '../../utils/date';
import { OwnerChangeIcon, PlusIconSvg, WarnIcon } from '../../assets/images';
import OwnerSelectModal from '../../components/document-admin-settings/OwnerSelectModal';
import { ownerChangeAction } from '../../API/ownerChange';
import { useDocumentContext } from '../../contexts/document-provider';
// eslint-disable-next-line import/order
import qs from 'qs';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { IVersionOwner } from '../../interfaces/user';
import GenerateWordDocButton from '../../components/generate-word-doc/GenerateWordDoc';


const {Blob} = window;

interface HeadingProps {
    pageType: IPageType;
    docInfo: IDocumentInfo;
    docTypeInfo: IDocumentTypeInfo;
    activeDocVersion: IOptionValue;
    latestDocVersionId: number;
    documentId: number;
    onFavoriteClickHAndler: () => void;
    delegatorInfo: IOptionValue | null;
    handleOpenModal: (show: boolean) => void;
    activeVersionOwnerInfo: IVersionOwner;
}

const { DOCUMENT_INFO } = STATIC_CONTENT;

// eslint-disable-next-line max-lines-per-function
const Heading: React.FC<HeadingProps> = ({
    pageType,
    docInfo,
    docTypeInfo,
    activeDocVersion,
    latestDocVersionId,
    documentId,
    delegatorInfo,
    onFavoriteClickHAndler,
    handleOpenModal,
    activeVersionOwnerInfo
}: HeadingProps) => {
    const [buttonStatus, setButtonStatus] = useState<IButtonStatus>('DEFAULT');
    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const [confirmationModalData, setConfirmationModalData] = useState({
        type: 'DISCARD',
        onConfirm: () => { },
    });
    const {user} = useAuthDataContext();
    const history = useHistory();
    const { search } = useLocation();
    const queryObj = qs.parse(search, { ignoreQueryPrefix: true });
    const onClick = async (type: IDocumentActionTypes) => {
        setButtonStatus('LOADING');
        const res = await documentAction(documentId, type, docTypeInfo);
        if (res?.apiStatus === 'SUCCESS') {
            if (type === 'REVERT') {
                toast.success(DOCUMENT_INFO.TOAST.SUCCESS_MSG.REVERTED);
                history.push({
                    pathname: `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                    search: `?doc_id=${documentId}`,
                });
            }
            if (type === 'DISCARD') {
                if(queryObj.dcoDocumentId){
                    window.history.back();
                }
                else{
                    history.push(`${CONSTANTS.RELATIVE_PATHS.dashboard.Url}`);
                }  
                toast.success(DOCUMENT_INFO.TOAST.SUCCESS_MSG.DISCARDED);
            }
        } else {
            toast.error(DOCUMENT_INFO.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
        setButtonStatus('DEFAULT');
    };
    const { allowViewInProcessDocFlag } = useDocumentContext();
    return (
        <>
            <div className="document-info__heading">
                <div>
                    <div className="title">
                        <div className="title-flex">
                            <h1>
                                {pageType === 'CREATE_DOCUMENT' ? (
                                    <SkeletonText text={docTypeInfo.title} width={200} />
                                ) : (
                                    <>
                                        <SkeletonText text={docTypeInfo.title} width={150} /> -
                                        <SkeletonText text={docInfo.title} width={80} />
                                    </>
                                )}
                            </h1>
                            {docInfo.docState === 'APPROVED' && (
                                <FavoriteComponent
                                    docId={documentId}
                                    isActive={docInfo.isFavorite}
                                    onClickHandler={onFavoriteClickHAndler}
                                />
                            )}
                        </div>
                        {pageType === 'DOCUMENT_DETAIL' && (
                            <span
                                className={`status-pill ${
                                    docInfo?.activeDocumentState &&
                                    docInfo.activeDocumentState.toLocaleLowerCase()
                                }`}
                            >
                                {docInfo.activeDocumentState === 'NEW' ? (
                                    <Skeleton width={70} height={20} borderRadius={8} />
                                ) : docInfo.approvedOn &&
                                  docInfo.activeDocumentState ===
                                      CONSTANTS.DOCUMENT_STATUS.APPROVED ? (
                                    CONSTANTS.DOCUMENT_STATUS.APPROVED
                                ) : (
                                    CONSTANTS.DOCUMENT_STATUS[docInfo.activeDocumentState]
                                )}
                            </span>
                        )}
                    </div>
                    {pageType !== 'CREATE_DOCUMENT' && (
                        <h4 className="description">
                            <SkeletonText text={docInfo.description} width={250} />
                        </h4>
                    )}
                </div>
                <div className="buttons">
                    {/* {(user.id === docInfo.author.id || user.id === activeVersionOwnerInfo?.id) &&
                        (queryObj.dcoDocumentId ? (
                            <></>
                        ) : (
                            <>
                                <span
                                    data-for="ownerChange-action"
                                    style={{ cursor: 'pointer' }}
                                    data-tip
                                >
                                    <OwnerChangeIcon onClick={() => handleOpenModal(true)} />
                                </span>
                                <ReactTooltip
                                    place="top"
                                    type="dark"
                                    effect="solid"
                                    id="ownerChange-action"
                                >
                                    {' '}
                                    <span>Change Ownership</span>
                                </ReactTooltip>
                            </>
                        ))} */}
                    {pageType === 'CREATE_DOCUMENT' && allowViewInProcessDocFlag && (
                        <CopyText
                            text={DOCUMENT_INFO.BUTTON.SHARE}
                            link={`${process.env.REACT_APP_HOST}${CONSTANTS.RELATIVE_PATHS.documentDetails.WPUrl}?doc_id=${documentId}`}
                        />
                    )}
                    {delegatorInfo?.value ? (
                        <p className="delegated-message">{`Delegated from ${delegatorInfo.label}`}</p>
                    ) : null}
                    {pageType === 'CREATE_DOCUMENT' &&
                        (docInfo.approvedVersionId && (activeDocVersion.value as number)) !==
                        docInfo.approvedVersionId &&
                        docInfo.docState !== 'REJECTED' &&
                        !docInfo.documentLock && (
                            <Button
                                type="button"
                                disabled={
                                    buttonStatus === 'LOADING' ||
                                    docInfo.title === CONSTANTS.LOADING.TEXT
                                }
                                className=""
                                isLoading={
                                    buttonStatus === 'LOADING' &&
                                    confirmationModalData.type === 'REVERT'
                                }
                                onClick={() => {
                                    setConfirmationModal(true);
                                    setConfirmationModalData({
                                        onConfirm: () => onClick('REVERT'),
                                        type: 'REVERT',
                                    });
                                }}
                            >
                                {DOCUMENT_INFO.BUTTON.REVERT}
                            </Button>
                        )}
                    {pageType === 'CREATE_DOCUMENT' &&
                        !docInfo.approvedVersionId &&
                        !docInfo.documentLock && (
                            <Button
                                type="button"
                                disabled={
                                    buttonStatus === 'LOADING' ||
                                    docInfo.title === CONSTANTS.LOADING.TEXT
                                }
                                className=""
                                isLoading={
                                    buttonStatus === 'LOADING' &&
                                    confirmationModalData.type === 'REVERT'
                                }
                                onClick={() => {
                                    setConfirmationModal(true);
                                    setConfirmationModalData({
                                        onConfirm: () => onClick('DISCARD'),
                                        type: 'DISCARD',
                                    });
                                }}
                            >
                                {DOCUMENT_INFO.BUTTON.DISCARD}
                            </Button>
                        )}
                    {pageType === 'DOCUMENT_DETAIL' &&
                        (docInfo.docState === 'REJECTED' || docInfo.docState === 'DRAFT') &&
                        !docInfo.documentLock &&
                        Number(activeDocVersion.value) === latestDocVersionId && (
                            <button
                                type="button"
                                onClick={() =>
                                    history.push(
                                        `${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`,
                                    )
                                }
                            >
                                {DOCUMENT_INFO.BUTTON.EDIT}
                            </button>
                        )}
                </div>
                {showConfirmationModal && (
                    <ConfirmationModal
                        type={confirmationModalData.type as IAcceptedType}
                        isModalOpen={showConfirmationModal}
                        onModalClose={setConfirmationModal}
                        onConfirm={confirmationModalData.onConfirm}
                    />
                )}
            </div>
        </>
    );
};

interface Prop {
    show: boolean;
    downloadable: boolean;
    handleDownload: () => void;
    handleShow: (check:boolean) => void;
    downloading: boolean;
}
// eslint-disable-next-line max-lines-per-function
const DownloadModal: React.FC<Prop> = ({show,downloadable,handleDownload,handleShow,downloading}) => {
    const customStyles = {
        content: {
            // backgroundColor: '#000',
            bottom: 'auto',
            left: '50%',
            marginRight: '-50%',
            right: 'auto',
            top: '20%',
            transform: 'translate(-50%, -50%)',
        },
    };

    return (
        <div>
            <Modal isOpen={show} style={customStyles} className="Owner-Change-Modal">
                <PlusIconSvg className="close-btn" onClick={() => handleShow(false)} />
                {downloadable ? (
                    <div style={{display: 'flex'}}>
                        <label htmlFor="user-search">Your download is getting ready.</label>
                        <Loader type="Oval" color={CONSTANTS.BRAND_COLOR} height={20} width={20} />
                    </div>
                ) : (
                    <div style={{width:'250px'}}>
                    <Button
                        disabled={false}
                        isLoading={downloading}
                        className=''
                        onClick={()=>{handleDownload()}}
                        type={undefined}
                    >
                        Click Here to download
                    </Button>
                    </div>
                )}
            </Modal>
        </div>
    );
};


interface Props {
    documentId: number;
    pageType: IPageType;
    docInfo: IDocumentInfo;
    docTypeInfo: IDocumentTypeInfo;
    docVersions: IOptionValue[];
    activeDocVersion: IOptionValue;
    latestDocVersionId: number;
    titleError: boolean;
    descError: boolean;
    effectiveFromError: boolean;
    effectivePeriodError: boolean;
    sunsetPeriodError: boolean;
    setDocInfo: React.Dispatch<React.SetStateAction<IDocumentInfo>>;
    setActiveDocVersion: React.Dispatch<React.SetStateAction<IOptionValue>>;
    onFavoriteClickHAndler: () => void;
    // eslint-disable-next-line react/require-default-props
    delegatorInfo?: IOptionValue;
    publishOnceApproved: boolean;
    isSunsetEnabled: boolean;
    activeVersionOwnerInfo: IVersionOwner;
    // eslint-disable-next-line react/require-default-props
    checkInVersionOwner?: IVersionOwner;
    isEndDateAsked: boolean;
}

// eslint-disable-next-line max-lines-per-function
const DocumentInfo: React.FC<Props> = ({
    documentId,
    pageType,
    docInfo,
    docTypeInfo,
    docVersions,
    activeDocVersion,
    latestDocVersionId,
    titleError,
    descError,
    effectiveFromError,
    effectivePeriodError,
    sunsetPeriodError,
    setDocInfo,
    setActiveDocVersion,
    onFavoriteClickHAndler,
    delegatorInfo,
    publishOnceApproved,
    isSunsetEnabled,
    activeVersionOwnerInfo,
    checkInVersionOwner,
    isEndDateAsked,
}: Props) => {
    const [fileName,setFileName] = useState<string>('');
    const [loading,setLoading] = useState<boolean>(false);
    const [download,setDownload] = useState<boolean>(true);
    const [downloading,setDownloading] = useState<boolean>(false);
    const {user} = useAuthDataContext();
    const [openModal,setOpenModal] = useState(false);
    const history = useHistory();
    const {DOCUMENT_OWNER_CHANGE} = STATIC_CONTENT;
    const [selectedNewOwner, setSelectedNewOwner] = useState<IUserOptionValue | null>(null);
    const States:IDocStatus[] = ['APPROVED','PUBLISHED','UNDER_REVIEW'];
    
    const effectiveFromDate = (
        <div>
            <label htmlFor="effective_from">Effective From Date</label>

            <div className="react-datepicker">
                <ReactDatePicker
                    className={`${effectiveFromError ? 'error' : ''}`}
                    value={!docInfo?.effectiveFrom ? 'Today' : formatDate(docInfo.effectiveFrom)}
                    minDate={isEndDateAsked ? null : new Date()}
                    onChange={(date: Date) => {
                        setDocInfo({ ...docInfo, effectiveFrom: date });
                    }}
                    disabled={!(
                        pageType === 'CREATE_DOCUMENT' ||
                        (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                    ) || docInfo.documentLock}
                />
            </div>
        </div>
    );

    const everGreen = (
        <div>
            <label htmlFor="effective_from">Perpetual</label>
            <Toggle
                isChecked={docInfo.isEverGreen}
                onclick={() =>
                    setDocInfo({ ...docInfo, isEverGreen: !docInfo.isEverGreen })
                }
                isDisabled={!(
                    pageType === 'CREATE_DOCUMENT' ||
                    (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                ) || docInfo.documentLock
                }
            />
        </div>
    );

    const autoRenew = (
        <div>
            <label htmlFor="effective_from">Auto Renew</label>
            <Toggle
                isChecked={docInfo.autoRenew}
                onclick={() =>
                    setDocInfo({ ...docInfo, autoRenew: !docInfo.autoRenew })
                }
                isDisabled={
                    !(
                        pageType === 'CREATE_DOCUMENT' ||
                        (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                    ) ||
                    docInfo.documentLock
                }
            />
        </div>
    );

    const effectiveTillDate = (
        <div>
            <label htmlFor="effective_from">End Date</label>

            <div className="react-datepicker">
                <ReactDatePicker
                    className={`${effectiveFromError ? 'error' : ''}`}
                    value={!docInfo?.effectiveTill ? 'Today' : formatDate(docInfo.effectiveTill)}
                    minDate={new Date()}
                    onChange={(date: Date) => {
                        setDocInfo({ ...docInfo, effectiveTill: date });
                    }}
                    disabled={!(
                        pageType === 'CREATE_DOCUMENT' ||
                        (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                    ) || docInfo.documentLock}
                />
            </div>
        </div>
    );

    const effectiveFromOnceApproved = (
        <div>
            <label htmlFor="effective_from">Effective From Date</label>
            <div>
                <input
                    type="text"
                    placeholder="Once Approved"
                    className="effectivePeriodError"
                    disabled
                />
            </div>
        </div>
    );
    const effectivePeriod = (
        <div>
            <label htmlFor="sunset-period">
                Effective Period (in weeks) <span className="mandatory-field" />
            </label>
            <div>
                <input
                    type="number"
                    className={`${effectivePeriodError ? 'error' : ''} sunset-period`}
                    value={docInfo.effectivePeriod}
                    disabled={!(pageType === 'CREATE_DOCUMENT') || docInfo.documentLock}
                    onWheel={(event) => event.currentTarget.blur()}
                    onKeyDown={(e) =>
                        ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) =>
                        setDocInfo({
                            ...docInfo,
                            effectivePeriod: e.target.valueAsNumber,
                        })
                    }
                />
            </div>
        </div>
    );
    const sunsetPeriod = (
        <div>
            <label htmlFor="sunset-period">
                Sunset Period (in weeks) <span className="mandatory-field" />
            </label>
            <div>
                <input
                    type="number"
                    className={`${sunsetPeriodError ? 'error' : ''} sunset-period`}
                    value={docInfo.sunsetPeriod}
                    disabled={!(pageType === 'CREATE_DOCUMENT') || docInfo.documentLock}
                    onWheel={(event) => event.currentTarget.blur()}
                    onKeyDown={(e) =>
                        ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) =>
                        setDocInfo({
                            ...docInfo,
                            sunsetPeriod: e.target.valueAsNumber,
                        })
                    }
                />
            </div>
        </div>
    );

    const noticePeriod = (
        <div>
            <label htmlFor="sunset-period">
                Notice Period (in days) <span className="mandatory-field" />
            </label>
            <div>
                <input
                    type="number"
                    className={`${sunsetPeriodError ? 'error' : ''} sunset-period`}
                    value={docInfo.sunsetPeriod}
                    disabled={!(
                        pageType === 'CREATE_DOCUMENT' ||
                        (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                    ) || docInfo.documentLock}
                    onWheel={(event) => event.currentTarget.blur()}
                    onKeyDown={(e) =>
                        ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                    }
                    onChange={(e) =>
                        setDocInfo({
                            ...docInfo,
                            sunsetPeriod: e.target.valueAsNumber,
                        })
                    }
                />
            </div>
        </div>
    );

    const handleAction = async () => {
        const documents:IOwnerChange[] = [{
            documentId,
            oldOwnerId: docInfo.author.id,
            state: '',
            docNumber: ''
        }];
        if (!selectedNewOwner?.value) {
            toast.error(DOCUMENT_OWNER_CHANGE.OWNER.WARNING);
        } else {
            const res = await ownerChangeAction(documents, selectedNewOwner?.value as number);
            if (res?.apiStatus === 'FAILURE') {
                toast.error(DOCUMENT_OWNER_CHANGE.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
            } else if(res?.errors.length){
                toast.error(DOCUMENT_OWNER_CHANGE.TOAST.WARNING_MSG.DUPLICATE);
            }
            else {
                toast.success(DOCUMENT_OWNER_CHANGE.TOAST.SUCCESS_MSG.AUTHOR_CHANGED);
                history.push(`${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_DOCUMENTS`);
            }
        }
        setOpenModal(false);
    };

    const handleExport = async () => {
        setLoading(true);
        const pdf:string = await exportPDF(documentId);
        setFileName(pdf);
        setDownload(false);
    };

    const [relationArr, setRelationArr] = useState<any[]>([]);

    async function getDocumentRelations() {
        if(documentId && activeDocVersion.value){
            const res = await getDocumentRelationsInfo(Number(activeDocVersion.value));            
            if(res?.apiStatus === 'SUCCESS') {
                setRelationArr(res?.data);
            }
        }
    }
    useEffect(()=> {        
        if(docTypeInfo && docTypeInfo.allowDCO){
            getDocumentRelations();
        }
    }, [docTypeInfo, activeDocVersion]);


    const handleDownloadPDF = async () => {
        setDownloading(true);
        const res =  await downloadPDF(fileName);
        setDownloading(false);
        setLoading(false);
        setDownload(true);
    };
    const { allowViewInProcessDocFlag } = useDocumentContext();
    
    return (
        <div className="document-info">
            <Heading
                pageType={pageType}
                activeDocVersion={activeDocVersion}
                latestDocVersionId={latestDocVersionId}
                docInfo={docInfo}
                documentId={documentId}
                onFavoriteClickHAndler={onFavoriteClickHAndler}
                docTypeInfo={docTypeInfo}
                delegatorInfo={delegatorInfo || null}
                handleOpenModal={(show: boolean) => {
                    setOpenModal(show);
                }}
                activeVersionOwnerInfo={activeVersionOwnerInfo}
            />
            {(pageType === 'CREATE_DOCUMENT' || pageType === 'EDIT_DOCUMENT') && (
                <div className="section one">
                    <div>
                        <label htmlFor="document-title">
                            {DOCUMENT_INFO.LABEL.TITLE} <span className="mandatory-field" />
                        </label>
                        {docInfo.title === CONSTANTS.LOADING.TEXT ? (
                            <Skeleton width="60%" containerClassName="skeleton-loading" />
                        ) : (
                            <input
                                type="text"
                                className={`${titleError ? 'error' : ''}`}
                                name="document-title"
                                autoComplete="off"
                                autoCapitalize="on"
                                placeholder="Enter Title"
                                value={
                                    docInfo.title === CONSTANTS.LOADING.TEXT ? '' : docInfo.title
                                }
                                disabled={!(
                                    pageType === 'CREATE_DOCUMENT' ||
                                    (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                                ) || docInfo.documentLock}
                                onChange={(e) => setDocInfo({ ...docInfo, title: e.target.value })}
                            />
                        )}
                    </div>
                    <div>
                        <label htmlFor="document-description">
                            {DOCUMENT_INFO.LABEL.DESCRIPTION} <span className="mandatory-field" />
                        </label>
                        {docInfo.description === CONSTANTS.LOADING.TEXT ? (
                            <Skeleton width="60%" containerClassName="skeleton-loading" />
                        ) : (
                            <input
                                type="text"
                                className={`${descError ? 'error' : ''}`}
                                name="document-description"
                                autoComplete="off"
                                autoCapitalize="on"
                                placeholder="Enter Description"
                                value={
                                    docInfo.description === CONSTANTS.LOADING.TEXT
                                        ? ''
                                        : docInfo.description
                                }
                                disabled={!(
                                    pageType === 'CREATE_DOCUMENT' ||
                                    (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                                ) || docInfo.documentLock}
                                onChange={(e) =>
                                    setDocInfo({ ...docInfo, description: e.target.value })
                                }
                            />
                        )}
                    </div>
                </div>
            )}
            <div className="detail-section">
                <div>
                    <label>{DOCUMENT_INFO.LABEL.DOCUMENT_NUMBER}</label>
                    <CopyText text={docInfo.docNumber} />
                </div>
                <div>
                    <label>{DOCUMENT_INFO.LABEL.AUTHOR}</label>
                    <CopyText text={docInfo.versionOwner?.name} />
                </div>
                <div style={{display: docTypeInfo.blockRevise ? "none" : ""}}>
                    <label>{DOCUMENT_INFO.LABEL.VERSION}</label>
                    <Select
                        name="version"
                        id="version"
                        isMulti={false}
                        className="react-select"
                        classNamePrefix="select"
                        options={docVersions}
                        value={activeDocVersion}
                        isDisabled={
                            pageType === 'CREATE_DOCUMENT' ||
                            pageType === 'TASK_DETAILS' ||
                            pageType === 'DOCUMENT_PREVIEW' ||
                            pageType === 'EDIT_DOCUMENT'
                        }
                        onChange={(option) => {
                            if (option && option.value) {
                                setActiveDocVersion(option);
                                // toast.success(`Loaded version ${option.label}`);
                            }
                        }}
                    />
                </div>
                <div>
                    <label htmlFor="revise-duration">{DOCUMENT_INFO.LABEL.CONFIDENTIAL}</label>
                    <Toggle
                        isChecked={docTypeInfo.isConfidential || docInfo.confidential}
                        onclick={() =>
                            setDocInfo({ ...docInfo, confidential: !docInfo.confidential })
                        }
                        isDisabled={
                            docTypeInfo.isConfidential ||
                            !(
                                pageType === 'CREATE_DOCUMENT' ||
                                (pageType === 'EDIT_DOCUMENT' && docTypeInfo.isContract)
                            ) ||
                            docInfo.documentLock
                        }
                    />
                </div>
                <OwnerSelectModal
                    show={openModal}
                    handleShow={(show: boolean) => setOpenModal(show)}
                    updateChangedOwner={(selectedUser: IUserOptionValue) =>
                        setSelectedNewOwner(selectedUser)
                    }
                    handleOwnerChange={() => {
                        handleAction();
                    }}
                />
                {docTypeInfo.allowExport &&
                    (docInfo.docState === 'PUBLISHED' ||
                        docInfo.docState === 'APPROVED' ||
                        docInfo.docState === 'REPUBLISHED' ||
                        docInfo.docState === 'UNDER_REVIEW') && (
                        <div className="export-doc">
                            <>
                                <Upload
                                    onClick={() => {
                                        handleExport();
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                                <label>Export</label>
                            </>
                        </div>
                    )}
                <DownloadModal
                    show={loading}
                    downloadable={download}
                    handleDownload={() => {
                        handleDownloadPDF();
                    }}
                    handleShow={(load: boolean) => {
                        setLoading(load);
                    }}
                    downloading={downloading}
                />
            </div>
            {/* Effectivity */}
            <div className="effectivity">
                {isSunsetEnabled &&
                    publishOnceApproved &&
                    docInfo.title !== CONSTANTS.LOADING.TEXT && (
                        <>
                            {effectiveFromOnceApproved} {effectivePeriod} {sunsetPeriod}
                        </>
                    )}
                {isSunsetEnabled &&
                    !publishOnceApproved && isEndDateAsked &&
                    docInfo.title !== CONSTANTS.LOADING.TEXT && (
                        <>
                            {effectiveFromDate}
                            {everGreen}
                            {docInfo.isEverGreen ? null : effectiveTillDate}
                            {docInfo.isEverGreen ? null : autoRenew}
                            {noticePeriod}
                        </>
                    )}
                {isSunsetEnabled &&
                    !publishOnceApproved && !isEndDateAsked &&
                    docInfo.title !== CONSTANTS.LOADING.TEXT && (
                        <>
                            {effectiveFromDate}
                            {effectivePeriod}
                            {sunsetPeriod}
                        </>
                    )}
                {!isSunsetEnabled &&
                    !publishOnceApproved &&
                    docInfo.title !== CONSTANTS.LOADING.TEXT &&
                    effectiveFromDate}
                { }
            </div>

            {relationArr.length && !docInfo.documentLock ? (
                <div className="relation-info">
                    <div className="warning">
                        <WarnIcon className="warn-icon" />
                    </div>
                    <div>{STATIC_CONTENT.DCO.ADDED_BUT_UNLOCKED}</div>
                    {relationArr.map((curr) => (
                        <div>
                            <Link
                                className="link-style"
                                target="_blank"
                                style={{ textDecoration: 'none' }}
                                to={{
                                    pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                                    search: `?doc_id=${curr.id}`,
                                }}
                            >
                                <SkeletonText text={curr.documentNumber} width={200} />
                            </Link>
                            {/* <span>{curr?.owner?.employeeName}</span> */}
                        </div>
                    ))}
                </div>
            ) : null}

            {relationArr.length && docInfo.documentLock ? (
                <div className="relation-info">
                    <div className="warning">
                        <WarnIcon className="warn-icon" />
                    </div>
                    <div>{STATIC_CONTENT.DCO.ADDED_AND_LOCKED}</div>
                    {relationArr.map((curr) => (
                        <div>
                            <Link
                                className="link-style"
                                target="_blank"
                                style={{ textDecoration: 'none' }}
                                to={{
                                    pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                                    search: `?doc_id=${curr.id}`,
                                }}
                            >
                                <SkeletonText text={curr.documentNumber} width={200} />
                            </Link>
                            {/* <span>{curr?.owner?.employeeName}</span> */}
                        </div>
                    ))}
                </div>
            ) : null}

            {docTypeInfo.allowDCO && !docInfo.documentLock && !relationArr.length ? (
                <div className="relation-info">
                    <div className="warning">
                        <WarnIcon className="warn-icon" />
                    </div>
                    <div>{STATIC_CONTENT.DCO.ALLOWED_DCO_CHILD_NOTE}</div>
                </div>
            ) : null}

            {docTypeInfo.allowReviseByAnyone &&
                docInfo.docState === 'DRAFT' &&
                pageType === 'DOCUMENT_DETAIL' && (
                    <div className="relation-info">
                        <div className="warning">
                            <WarnIcon className="warn-icon" />
                        </div>
                        This document has been checked out by: {checkInVersionOwner?.employeeName}{' '}
                        <a
                            href={`mailto:${checkInVersionOwner?.email}`}
                            style={{ color: '#2079FF', textDecoration: 'none' }}
                        >
                            {checkInVersionOwner?.email}
                        </a>
                    </div>
                )}
        </div>
    );
};
export default DocumentInfo;
