/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { UserIconSvg, DeleteSvg, EditSvg, GroupSvg, SuccessfulIcon } from '../../assets/images';
import { useDocumentContext } from '../../contexts/document-provider';
import { IExternalApprover, IModalData, IOptionValue, IUserOptionValue } from '../../interfaces';
import { IApprovalDocStage, IDocApprovers, IDocumentInfo, IExternalApprovers } from '../../interfaces/document';
import { AddButton, DivAsButton } from '../General';
import Reviewers from '../reviewers/Reviewers';
import UserSearchModal from '../use-search-modal/userSearchModal';
import './WorkFlowPanel.scss';
import STATIC_CONTENT from '../../constants/StaticContent';
import { useEventsHeight } from '../../contexts/events-height-provider';
import { getGroupById } from '../../API/groups';
import ExternalApproverModal from '../externalApprovers/externalApproverModal';
import { removeApprovalFlow, updateApprovalStatus, updateExistingContract, updateExternalApprovers } from '../../API/document';
import Toggle from '../toggle/Toggle';
import DocumentInfo from '../../widgets/document-info/DocumentInfo';
import { useTaskDetailContext } from '../../contexts/task-details-provider';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { IDocumentTypeInfo } from '../../interfaces/document-type';
import { IDocumentApproversAction, IDocumentExternalApproversAction } from '../../reducers/document-approver-reducer';
import Button from '../button/Button';

interface StageCardProps {
    stageIndex: number;
    stageData: IApprovalDocStage;
    approversDispatch: React.Dispatch<IDocumentApproversAction>;
    isEditable: boolean;
    error: boolean;
    cardTitle: string;
    docInfo: IDocumentInfo;
    docTypeInfo: IDocumentTypeInfo;
}

export const StageCard: React.FC<StageCardProps> = ({
    stageData,
    stageIndex,
    approversDispatch,
    isEditable,
    error,
    cardTitle,
    docInfo,
    docTypeInfo
}: StageCardProps) => {
    const {
        hideUsers,
        setHideUsers,
        existingContract,
        accessibleByCurrentUser,
        externalApprovers,
        isTravelAuthorizationForm,
    } = useDocumentContext();
    const {approvers, reviewers, activeApprovalStatusId, documentId} = useTaskDetailContext();
    const {approvers: approversInfo, reviewers: reviewersInfo, activeApprovalStatusId: approvalStatusId, documentId: documentDetailsId} = useDocumentContext();
    const { user } = useAuthDataContext();
    const [isExpanded, setIsExpanded] = useState(true);
    const [showUserSearch, setShowUserSearch] = useState(false);
    const [editedApprovers,setEditedApprovers] = useState(false);
    const [modalData, setModalData] = useState({ approverIndex: -1, prevUserId: 0 });
    const { updateHeight } = useEventsHeight();
    const [isCustom, setIsCustom] = useState(false);
    const [groupInfo, setGroupInfo] = useState<IOptionValue>();
    useEffect(() => {
        updateHeight();
    });

    const userHandler = async (selectedUsers: IUserOptionValue[], type: 'EDIT' | 'NEW') => {
        selectedUsers.forEach((selectedUser) => {
            if (type === 'EDIT') {
                approversDispatch({
                    type: 'update-user',
                    stageIndex,
                    approverIndex: modalData.approverIndex,
                    value: selectedUser,
                });
                let hiddenUsers = hideUsers;
                if (modalData.prevUserId) {
                    hiddenUsers = hideUsers.filter((u) => u !== modalData.prevUserId);
                }
                hiddenUsers.push(selectedUser.value as number);
                setHideUsers(hiddenUsers);
            }

            if (type === 'NEW') {
                const value: IDocApprovers = {
                    employee: selectedUser,
                    isEditable: true,
                    reminders: stageData.reminders,
                    roleId: selectedUser.roleId,
                    state: 'NEW',
                };
                approversDispatch({
                    type: 'push',
                    stageIndex,
                    value,
                });
                hideUsers.push(selectedUser.value as number);
                setHideUsers(hideUsers);
            }
        });
        setShowUserSearch(false);
    };

    useEffect(()=>{
        const updateApprovers = async() => {
            if(docInfo.docState === 'UNDER_REVIEW' && docTypeInfo.isContract && approvers.length !== 0)
            {
                const toastId = toast.loading('updating approvers in process ...');
                await updateApprovalStatus(activeApprovalStatusId,reviewers,approvers,docInfo.author.id,existingContract,documentId);
                toast.dismiss(toastId);
                toast.success('updated approvers successfully');
            }
            else if(docInfo.docState === 'UNDER_REVIEW' && docTypeInfo.isContract && approversInfo.length !== 0)
            {
                const toastId = toast.loading('updating approvers in process ...');
                await updateApprovalStatus(approvalStatusId,reviewersInfo,approversInfo,docInfo.author.id,existingContract,documentDetailsId);
                toast.dismiss(toastId);
                toast.success('updated approvers successfully');
            }
        }
        const x = showUserSearch||editedApprovers;
        setEditedApprovers(x);
        if((x && approvers.length !== 0 && activeApprovalStatusId!==0) || (x && approversInfo.length !== 0 && approvalStatusId!==0)) {
            if(!showUserSearch)
            {
                updateApprovers();
            }
        }
    },[showUserSearch]);

    const deleteUser = async (index: number, employeeId: number) => {
        approversDispatch({
            type: 'delete',
            stageIndex,
            index,
        });
        setHideUsers(hideUsers.filter((f) => f !== employeeId));
        if(docInfo.docState === 'UNDER_REVIEW' && docTypeInfo.isContract && approvers.length !== 0)
        {
            const toastId = toast.loading('updating approvers in process ...');
            await updateApprovalStatus(activeApprovalStatusId,reviewers,approvers,docInfo.author.id,existingContract,documentId);
            toast.dismiss(toastId);
            toast.success('updated approvers successfully');
        }
        else if(docInfo.docState === 'UNDER_REVIEW' && docTypeInfo.isContract && approversInfo.length !== 0)
        {
            const toastId = toast.loading('updating approvers in process ...');
            await updateApprovalStatus(approvalStatusId,reviewersInfo,approversInfo,docInfo.author.id,existingContract,documentDetailsId);
            toast.dismiss(toastId);
            toast.success('updated approvers successfully');
        }
    };

    const onModalOpen = (approverIndex: number, prevUserId: number) => {
        setShowUserSearch(true);
        setModalData({ approverIndex, prevUserId });
    };

    // eslint-disable-next-line consistent-return
    const getGroupInfo = async (groupId: number) => {
        const res = await getGroupById(groupId);
        if (res?.apiStatus === 'SUCCESS') {
            setGroupInfo(res.myGroups[0]);
        } else {
            return undefined;
        }
    };

    useEffect(() => {
        if (showUserSearch === false) {
            setModalData({ approverIndex: -1, prevUserId: 0 });
        }
    }, [showUserSearch]);

    useEffect(() => {
        if (stageData.approvers.length > 0) {
            if (stageData.approvers[0]?.groupId) {
                getGroupInfo(stageData.approvers[0].groupId);
            } else if (stageData.approvers[0]?.individualGroupApproverId) {
                getGroupInfo(stageData.approvers[0].individualGroupApproverId);
            }
        }
    }, [stageData]);

    const btnComponent = (
        <div className="reviewers-tab__item add-reviewers">
            <DivAsButton
                className="add-user-button"
                onClick={() => {
                    onModalOpen(-1, 0);
                    setIsCustom(false);
                }}
            >
                <AddButton />
            </DivAsButton>
            <DivAsButton
                className=""
                onClick={() => {
                    onModalOpen(-1, 0);
                    setIsCustom(false);
                }}
            >
                <div> Add a Approver</div>
            </DivAsButton>
        </div>
    );

    const approversLength = stageData?.approvers.length;
    return (
        <>
            <div
                className={`stage-card ${error ? 'error' : ''} ${
                    stageData.scopeType === 'travelForm' && stageData.approvers.length === 0
                        ? 'hide-travelform-approvalStage'
                        : ''
                }`}
            >
                {cardTitle && (
                    <div className="heading">
                        <h3>
                            {/* {IScopeTypeMapping[stageData.scopeType]}, Approval Type :{' '}
                        {IExecutionTypeMapping[stageData.type]} */}
                            {cardTitle}
                        </h3>
                        <span onClick={() => setIsExpanded(!isExpanded)}>
                            {' '}
                            {isExpanded ? '-' : '+'}
                        </span>
                    </div>
                )}
                {isExpanded && (
                    <div className="stage-card__info">
                        {stageData.approvers.length > 0 &&
                            stageData.approvers.map((approver, approverIndex) => (
                                <>
                                    <div className="reviewers-tab__item">
                                        <div
                                            className={`icon-circle ${approver.state} ${stageData.type}`}
                                        >
                                            {(approver.groupId ||
                                                approver.individualGroupApproverId) &&
                                            groupInfo ? (
                                                <GroupSvg className="hour-glass-icon" />
                                            ) : (
                                                <UserIconSvg className="hour-glass-icon" />
                                            )}
                                            <span className="arrow-icon" />
                                        </div>
                                        <div className="info">
                                            {approver.groupId && groupInfo && (
                                                <>
                                                    <h5>Group Approval{'\u00A0'}</h5>
                                                    <p>{`- ${groupInfo.label}`}</p>
                                                </>
                                            )}
                                            {approver.groupId && approver.employee.value && (
                                                <h5>
                                                    {'\u00A0'} {'\u00A0'}
                                                    {approver.state === 'UNDER_REVIEW' &&
                                                        ' Currently claimed by:'}
                                                    {approver.state === 'APPROVED' &&
                                                        'Approved by:'}
                                                    {'\u00A0'}
                                                </h5>
                                            )}
                                            {approver.individualGroupApproverId && groupInfo && (
                                                <>
                                                    <h5>Select an Approver from group{'\u00A0'}</h5>
                                                    <p>{`- ${groupInfo.label}`}</p>
                                                </>
                                            )}
                                            <h5>{approver.employee.label}</h5>
                                            {approver.employee.jobTitle && (
                                                <span>{approver.employee.jobTitle}</span>
                                            )}
                                            {approver.state === 'APPROVED' &&
                                                approver.updatedAt !== undefined && (
                                                    <p className="approvedTimeStamp">
                                                        {docTypeInfo.approverTimeStamp
                                                            ? docTypeInfo.approverTimeStamp
                                                            : 'Approved at'}{' '}
                                                        {approver.updatedAt?.toLocaleString()}
                                                    </p>
                                                )}
                                            {approver.state === 'REJECTED' &&
                                                approver.updatedAt !== undefined && (
                                                    <p className="rejectedTimeStamp">
                                                        Rejected at{' '}
                                                        {approver.updatedAt?.toLocaleString()}
                                                    </p>
                                                )}
                                            {!approver.employee.activeUser &&
                                                !approver.groupId &&
                                                !approver.individualGroupApproverId && (
                                                    <p className="inactive-link">
                                                        <Link to="/settings?tab=INACTIVE_APPROVERS">
                                                            (Inactive)
                                                        </Link>{' '}
                                                    </p>
                                                )}
                                        </div>
                                        {isEditable && approver.isEditable && (
                                            <div className="user-actions">
                                                <div className="btn">
                                                    <EditSvg
                                                        data-for="view-action"
                                                        data-tip="Select Alternate Approver"
                                                        className="edit"
                                                        onClick={() =>
                                                            isEditable &&
                                                            onModalOpen(
                                                                approverIndex,
                                                                approver.employee.value as number,
                                                            )
                                                        }
                                                    />
                                                    <ReactTooltip
                                                        place="right"
                                                        type="dark"
                                                        effect="solid"
                                                        id="view-action"
                                                    />
                                                </div>
                                                {(docTypeInfo.isContract &&
                                                    docInfo.docState !== 'DRAFT') ||
                                                isTravelAuthorizationForm ? null : (
                                                    <div className="btn">
                                                        <DeleteSvg
                                                            className="delete"
                                                            onClick={() => {
                                                                deleteUser(
                                                                    approverIndex,
                                                                    approver.employee
                                                                        .value as number,
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {approver.adHoc &&
                                        approver.adHoc.map((adhocApprover) => (
                                            <div className="reviewers-tab__item adhoc">
                                                <div
                                                    className={`icon-circle ${adhocApprover.state} PARALLEL`}
                                                >
                                                    <UserIconSvg className="hour-glass-icon" />
                                                    <span className="arrow-icon" />
                                                </div>
                                                <div className="info">
                                                    <h5>{adhocApprover.employee.label}</h5>
                                                    {adhocApprover.employee.jobTitle && (
                                                        <span>
                                                            {adhocApprover.employee.jobTitle}
                                                        </span>
                                                    )}
                                                    {adhocApprover.state === 'APPROVED' && (
                                                        <p className="approvedTimeStamp">
                                                            Approved at{' '}
                                                            {adhocApprover.updatedAt?.toLocaleString()}
                                                        </p>
                                                    )}
                                                    {adhocApprover.state === 'REJECTED' && (
                                                        <p className="rejectedTimeStamp">
                                                            Rejected at{' '}
                                                            {adhocApprover.updatedAt?.toLocaleString()}
                                                        </p>
                                                    )}
                                                    {!adhocApprover.employee.activeUser && (
                                                        <p className="inactive-link">
                                                            <Link to="/settings?tab=INACTIVE_APPROVERS">
                                                                (Inactive)
                                                            </Link>{' '}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                </>
                            ))}
                        {stageData.exempt && stageData.scopeType === 'managementChain' && (
                            <p className="exempt">Management chain can be exempted</p>
                        )}
                        {!stageData.multiApproval &&
                            approversLength === stageData.approvers.length &&
                            stageData.approvers.length === 0 &&
                            stageData.scopeType === 'roleBased' &&
                            !groupInfo &&
                            isEditable &&
                            btnComponent}
                        {!stageData.multiApproval &&
                            approversLength === stageData.approvers.length &&
                            stageData.approvers.length === 0 &&
                            stageData.scopeType === 'groupBased' &&
                            !groupInfo &&
                            isEditable &&
                            btnComponent}
                        {stageData.scopeType === 'roleBased' &&
                            stageData.multiApproval &&
                            isEditable &&
                            btnComponent}

                        {stageData.scopeType === 'custom' &&
                            isEditable &&
                            (docTypeInfo.isContract && docInfo.docState !== 'DRAFT'
                                ? null
                                : btnComponent)}
                        {stageData.customApproval &&
                            stageData.scopeType !== 'custom' &&
                            isEditable && (
                                <div className="reviewers-tab__item add-reviewers">
                                    <DivAsButton
                                        className="add-user-button"
                                        onClick={() => {
                                            onModalOpen(-1, 0);
                                            setIsCustom(true);
                                        }}
                                    >
                                        <AddButton />
                                    </DivAsButton>
                                    <DivAsButton
                                        className=""
                                        onClick={() => {
                                            onModalOpen(-1, 0);
                                            setIsCustom(true);
                                        }}
                                    >
                                        <div> Add an Optional Approver</div>
                                    </DivAsButton>
                                </div>
                            )}
                    </div>
                )}
                <UserSearchModal
                    hideUsers={hideUsers}
                    type={modalData.approverIndex === -1 ? 'NEW' : 'EDIT'}
                    isModalOpen={showUserSearch}
                    onModalClose={setShowUserSearch}
                    roleId={isCustom ? undefined : stageData.roleList}
                    groupId={stageData.individualGroupApproverId}
                    userHandler={userHandler}
                    setEditedApprovers={setEditedApprovers}
                />
            </div>
        </>
    );
};

interface ExternalStageCardProps {
    externalApprovers: IExternalApprover[];
    isEditable: boolean;
    docTypeInfo: IDocumentTypeInfo;
    externalApproversDispatch: React.Dispatch<IDocumentExternalApproversAction>;
    docInfo: IDocumentInfo;
}

export const ExternalStageCard: React.FC<ExternalStageCardProps> = ({
    externalApprovers,
    isEditable,
    docTypeInfo,
    externalApproversDispatch,
    docInfo
}: ExternalStageCardProps) => {
    const [openExternalModal, setExternalModal] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);
    const { accessibleByCurrentUser } =
        useDocumentContext();
    const {taskId, approvers, externalApprovers: taskExternalApprovers, activeDocVersion} = useTaskDetailContext();
    const{approvers: approversInfo, externalApprovers: detailsExternalApprovers, activeDocVersionId} = useDocumentContext();
    const [updateExternalApprover,setUpdateExternalApprover] = useState(false);
    const [modalData, setModalData] = useState<IModalData>({
        email: '',
        name: '',
        approverIndex: -1,
    });

    const submitHandler = (selectedUser: IExternalApprover, type: 'EDIT' | 'NEW') => {
        if (selectedUser.email.split('@')[1] === 'enphaseenergy.com') {
            toast.warning('Please enter an external email address');
        } else {
            if (type === 'EDIT') {
                externalApproversDispatch({
                    type: 'update-user',
                    stageIndex: 0,
                    approverIndex: modalData.approverIndex,
                    value: selectedUser,
                });
            }

            if (type === 'NEW') {
                const value: IExternalApprover = {
                    email: selectedUser.email,
                    name: selectedUser.name,
                    state: 'NEW',
                    updatedAt: new Date().getTime() / 1000,
                };
                externalApproversDispatch({
                    type: 'push',
                    stageIndex: 0,
                    value,
                });
            }
            setExternalModal(false);
        }
    };

    useEffect(()=>{
        const updateApprovers = async() => {
            if(docInfo.docState === 'UNDER_REVIEW' && taskExternalApprovers.length !== 0)
            {   
                const toastId = toast.loading('updating approvers in process ...');
                updateExternalApprovers(Number(activeDocVersion.value),taskExternalApprovers);
                toast.dismiss(toastId);
                toast.success('updated approvers successfully');
            }
            else if(docInfo.docState === 'UNDER_REVIEW' && detailsExternalApprovers.length !== 0)
            {
                const toastId = toast.loading('updating approvers in process ...');
                updateExternalApprovers(activeDocVersionId,detailsExternalApprovers);
                toast.dismiss(toastId);
                toast.success('updated approvers successfully');
            }
        }
        const x = openExternalModal || updateExternalApprover;
        setUpdateExternalApprover(x);
        if(x)
        {
            if((!openExternalModal)) {
                updateApprovers();
            }
        }
    },[openExternalModal]);

    const deleteExternalUser = (index: number) => {
        externalApproversDispatch({
            type: 'delete',
            index,
            stageIndex: 0,
        });
    };

    return (
        <div className={`stage-card ${''}`}>
            {
                <div className="heading">
                    <h3>
                        {/* {IScopeTypeMapping[stageData.scopeType]}, Approval Type :{' '}
                        {IExecutionTypeMapping[stageData.type]} */}
                        External Signer
                    </h3>
                    <span onClick={() => setIsExpanded(!isExpanded)}>
                        {' '}
                        {isExpanded ? '-' : '+'}
                    </span>
                </div>
            }
            {isExpanded && (
                <div className="stage-card__info">
                    {externalApprovers.length > 0 &&
                        externalApprovers.map((approver, approverIndex) => (
                            <>
                                <div className="reviewers-tab__item">
                                    <div className={`icon-circle ${approver.state}`}>
                                        <UserIconSvg className="hour-glass-icon" />
                                        <span className="arrow-icon" />
                                    </div>
                                    <div className="info">
                                        <h4>
                                            {approver.name} - {approver.email}
                                        </h4>
                                        {approver.state === 'APPROVED' &&
                                            approver.updatedAt !== undefined && (
                                                <p className="approvedTimeStamp">
                                                    {docTypeInfo.approverTimeStamp
                                                        ? docTypeInfo.approverTimeStamp
                                                        : 'Approved at'}{' '}
                                                    {new Date(approver.updatedAt)?.toLocaleString()}
                                                </p>
                                        )}
                                    </div>
                                    {isEditable && (
                                        <div className="user-actions">
                                            <div className="btn">
                                                <EditSvg
                                                    className="edit"
                                                    onClick={() => {
                                                        setExternalModal(true);
                                                        setModalData({
                                                            email: approver.email,
                                                            name: approver.name,
                                                            approverIndex,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            {approvers[0]?.approvers[0]?.state === 'UNDER_REVIEW' ||
                                            approversInfo[0]?.approvers[0]?.state ===
                                                'UNDER_REVIEW' ? null : (
                                                <div className="btn">
                                                    <DeleteSvg
                                                        className="delete"
                                                        onClick={() =>
                                                            deleteExternalUser(approverIndex)
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </>
                        ))}
                    {isEditable && (docInfo.docState === 'DRAFT' || (externalApprovers.length === 0)) && (
                            <div className="reviewers-tab__item add-reviewers">
                                <DivAsButton
                                    className="add-user-button"
                                    onClick={() => {
                                        setModalData({ email: '', name: '', approverIndex: -1 });
                                        setExternalModal(true);
                                    }}
                                >
                                    <AddButton />
                                </DivAsButton>
                                <DivAsButton
                                    className=""
                                    onClick={() => {
                                        setModalData({ email: '', name: '', approverIndex: -1 });
                                        setExternalModal(true);
                                    }}
                                >
                                    <div> Add External Approver</div>
                                </DivAsButton>
                            </div>
                        )}
                    <ExternalApproverModal
                        isModalOpen={openExternalModal}
                        onModalClose={setExternalModal}
                        submitHandler={submitHandler}
                        modalData={modalData}
                        setUpdateExternalApprover={setUpdateExternalApprover}
                    />
                </div>
            )}
        </div>
    );
};

interface WorkFlowPanelProps{
    updateNotifyModalState : () => void;
    isButtonAvailabe: boolean;
}


const WorkFlowPanel: React.FC<WorkFlowPanelProps> = ({updateNotifyModalState, isButtonAvailabe}) => {
    const [activeTab, setActiveTab] = useState<'APPROVER' | 'REVIEWER'>('APPROVER');
    Modal.setAppElement('#root');
    const {
        approvers,
        error: {
            STEP3: { errorFields },
        },
        accessibleByCurrentUser,
        externalApprovers,
        docTypeInfo,
        isLeadershipForm,
        activeDocVersionId,
        approversDispatch,
        documentId,
        existingContract,
        setExistingContract,
        disableApprovalFlow,
        setDisableApprovalFlow,
        isGroupMember,
        externalApproversDispatch,
        docInfo,
        isTravelAuthorizationForm,
    } = useDocumentContext();
    const { CREATE_DOCUMENTS, TRAVEL_FORM } = STATIC_CONTENT;
    const updateContract = async (newStatus: boolean) => {
        const response = await updateExistingContract(documentId, newStatus);
        if (response && response.apiStatus !== "SUCCESS") {
            setExistingContract(!existingContract);
        }
    };
    const pageType = accessibleByCurrentUser && docInfo.docState === 'UNDER_REVIEW' ? 'EDIT_DOCUMENT' : 'CREATE_DOCUMENT';
    const handleToggleClick = () => {
        const newStatus = !existingContract;
        setExistingContract(newStatus);
        updateContract(newStatus);
        approversDispatch({type: 'update', value: []});
    };

    return (
        <div className="workflow-panel">
            <div className="tab">
                <button
                    type="button"
                    className={`${activeTab === 'APPROVER' ? 'active' : ''}`}
                    onClick={() => setActiveTab('APPROVER')}
                >
                    {CREATE_DOCUMENTS.BUTTON.APPROVERS_BTN}
                </button>
                <button
                    type="button"
                    className={`${activeTab === 'REVIEWER' ? 'active' : ''}`}
                    onClick={() => setActiveTab('REVIEWER')}
                >
                    {CREATE_DOCUMENTS.BUTTON.REVIEWERS_BTN}
                </button>
            </div>
            {activeTab === 'APPROVER' && !existingContract && (
                <>
                    <div className="approvers-tab tab-data">
                        {docTypeInfo.isContract && pageType === 'CREATE_DOCUMENT' && (<div>
                            <label htmlFor="alreadyExistingContract">Already existing Contract</label>
                            <Toggle
                                isChecked={existingContract}
                                isDisabled={false}
                                onclick={() => { handleToggleClick() }}
                            />
                        </div>)}
                        {approvers.length > 0 &&
                            approvers.map((approver, index) =>
                                docTypeInfo.isContract && approver.scopeType === 'groupBased' ? (
                                    <>
                                        <StageCard
                                            stageIndex={index}
                                            error={errorFields.includes(index.toString())}
                                            stageData={approver}
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={index}
                                            isEditable={!accessibleByCurrentUser}
                                            cardTitle={
                                                approver?.approvalStageTitle
                                                    ? approver.approvalStageTitle
                                                    : 'Select Approvers'
                                            }
                                            docInfo={docInfo}
                                            docTypeInfo={docTypeInfo}
                                            approversDispatch={approversDispatch}
                                        />
                                        {externalApprovers ? <ExternalStageCard
                                            externalApprovers={externalApprovers}
                                            isEditable={!accessibleByCurrentUser}
                                            docTypeInfo={docTypeInfo}
                                            externalApproversDispatch={externalApproversDispatch}
                                            docInfo={docInfo}
                                        /> : null}
                                    </>
                                ) : (
                                    <StageCard
                                        stageIndex={index}
                                        error={errorFields.includes(index.toString())}
                                        stageData={approver}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}
                                        isEditable={!accessibleByCurrentUser}
                                        cardTitle={
                                            approver?.approvalStageTitle
                                                ? approver.approvalStageTitle
                                                : 'Select Approvers'
                                        }
                                        docInfo={docInfo}
                                        docTypeInfo={docTypeInfo}
                                        approversDispatch={approversDispatch}
                                    />
                                ),
                            )}
                    </div>
                    {isLeadershipForm && (
                        <div className="leadership-form-points">
                            <span className="note">
                                {
                                    CREATE_DOCUMENTS.LEADERSHIP_FORM_APPROVER_POINTS
                                        .PROMOTION_TO_DIRECTOR
                                }
                            </span>
                        </div>
                    )}
                    {isTravelAuthorizationForm && (
                        <div className="leadership-form-points">
                            <span className="note">
                                {
                                    TRAVEL_FORM.TRAVEL_FORM_NOTE_TO_AUTHOR_IN_APPROVER_SECTION
                                }
                            </span>
                            <span className="note">
                                * If there are no alternate approvers listed, please 
                                <Button
                                    type="submit"
                                    className="notify-admin"
                                    disabled={isButtonAvailabe}
                                    onClick={() => {
                                        updateNotifyModalState();
                                    }}
                                    isLoading={isButtonAvailabe}
                                >
                                    Click Here
                                </Button> 
                                for admin help
                            </span>
                        </div>
                    )}
                    
                </>
            )}
            {activeTab === 'REVIEWER' && <Reviewers isEditable />}
        </div>
    );
};
export default WorkFlowPanel;
