/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IAPIResponseStatus, IOptionValue } from '../interfaces';
import { IDelegationInfo } from '../interfaces/delegation';
import { getUserFullName } from '../utils';
import { deleteRequest, getRequest, postRequest } from '../utils/api-request';

const getEmployeesDelegatee = async (
    delegateeId: number,
): Promise<IDelegationInfo[] | undefined> => {
    try {
        const res = await getRequest(`delegations?delegateeId=${delegateeId}`, true);
        if (res?.apiStatus === 'SUCCESS') {
            const delegatorList: IDelegationInfo[] = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const delegation of res.response) {
                delegatorList.push({
                    id: delegation.id,
                    employeeId: delegation.delegatorId,
                    employeeName: getUserFullName(
                        delegation.delegator.firstName,
                        delegation.delegator.lastName,
                    ),
                    fromDate: new Date(parseInt(delegation.startTime, 10)),
                    toDate: new Date(parseInt(delegation.endTime, 10)),
                });
            }

            return delegatorList;
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const getEmployeeDelegator = async (
    delegatorId: number,
): Promise<IDelegationInfo[] | undefined> => {
    try {
        const res = await getRequest(`delegations?delegatorId=${delegatorId}`, true);

        if (res?.apiStatus === 'SUCCESS') {
            const delegateeList: IDelegationInfo[] = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const delegation of res.response) {
                delegateeList.push({
                    id: delegation.id,
                    employeeId: delegation.delegateeId,
                    employeeName: getUserFullName(
                        delegation.delegatee.firstName,
                        delegation.delegatee.lastName,
                    ),
                    fromDate: new Date(parseInt(delegation.startTime, 10)),
                    toDate: new Date(parseInt(delegation.endTime, 10)),
                });
            }

            return delegateeList;
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const createDelegation = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formData: any,
): Promise<{ apiStatus: IAPIResponseStatus; error: '' } | undefined> => {
    try {
        const body = formData;
        const res = await postRequest(`delegations/`, body, true);

        if (res?.apiStatus === 'SUCCESS') {
            return { apiStatus: res?.apiStatus, error: '' };
        }
        if (res?.apiStatus === 'FAILURE') {
            return { apiStatus: res?.apiStatus, error: res.response.message };
        }

        return undefined;
    } catch (error) {
        // console.log(error);
        return undefined;
    }
};

const removeDelegation = async (
    delegationId: number,
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const res = await deleteRequest(`delegations/${delegationId}`, true);

        if (res?.apiStatus) return { apiStatus: res?.apiStatus };

        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const userAutoSearch = async (query: string, role = ''): Promise<IOptionValue[] | undefined> => {
    try {
        const res = await getRequest(`users/search/?query=${query}&role=${role}`, true);
        if (res?.apiStatus === 'SUCCESS') {
            const usersList: IOptionValue[] = [];

            // eslint-disable-next-line no-restricted-syntax
            for (const user of res.response) {
                usersList.push({
                    label: getUserFullName(user.firstName, user.lastName),
                    value: user.id,
                });
            }

            return usersList;
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const getActiveDelegators = async (employeeId: number): Promise<IOptionValue[] | undefined> => {
    try {
        const res = await getRequest(`delegations/${employeeId}/active-delegators`, true);

        const delegationList: IOptionValue[] = [];
        if (res?.apiStatus) {
            // eslint-disable-next-line no-restricted-syntax
            for (const delegation of res.response) {
                delegationList.push({
                    value: delegation.id,
                    label: `${delegation.firstName} ${delegation.lastName}`,
                });
            }
        }

        return delegationList;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

export {
    createDelegation,
    getEmployeesDelegatee,
    getEmployeeDelegator,
    removeDelegation,
    userAutoSearch,
    getActiveDelegators,
};
