import React, { useMemo } from 'react';
import { Column, Row, useTable, useSortBy, usePagination } from 'react-table';

import './DocTypeView.scss';
import { IDocTypeCard, IDocTypeStatus } from '../../interfaces/document-type';
import { SortIcon } from '../../assets/images';
import { DateColumn } from '../../components/General';
import Pagination from '../../components/pagination/Pagination';
import CONSTANTS from '../../constants';

const DocTypeLinkColumn = (row: Row<IDocTypeCard>) => {
    const {
        original: { id, activeVersion, name },
    } = row;
    return (
        <a
            href={`${CONSTANTS.RELATIVE_PATHS.documentTypeCreate.WPUrl}?doc_type_id=${id}&doc_type_v_id=${activeVersion}`}
        >
            {name}
        </a>
    );
};

const StatusColumn = ({ value }: { value: IDocTypeStatus }) => (
    <div className={`status-pill ${value.toLocaleLowerCase()}`}>
        {CONSTANTS.DOCUMENT_STATUS[value]}
    </div>
);

const DOCUMENT_TYPE_COLUMNS: Column<IDocTypeCard>[] = [
    {
        Header: 'Document Type',
        accessor: 'name',
        Cell: ({ row }) => <DocTypeLinkColumn {...row} />,
    },
    {
        Header: 'Total Documents',
        accessor: 'totalDocuments',
    },
    {
        Header: 'Created On',
        accessor: 'createdDate',
        Cell: ({ value }) => <DateColumn value={value} />,
    },
    {
        Header: 'Last Updated',
        accessor: 'lastUpdateDate',
        Cell: ({ value }) => <DateColumn value={value} />,
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <StatusColumn value={value} />,
    },
];

interface Props {
    docTypeData: IDocTypeCard[];
}

// eslint-disable-next-line max-lines-per-function
const DocTypeListView: React.FC<Props> = ({ docTypeData }) => {
    const columns = useMemo(() => DOCUMENT_TYPE_COLUMNS, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize },
        canPreviousPage,
        canNextPage,
    } = useTable<IDocTypeCard>(
        {
            columns,
            data: docTypeData,
        },
        useSortBy,
        usePagination,
    );
    return (
        <div className="doc-type-list-view">
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    {column.isSorted && (
                                        <SortIcon
                                            className={`sort ${
                                                column.isSortedDesc ? 'desc' : 'asc'
                                            } `}
                                        />
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {page.length > CONSTANTS.NO_OF_TABLE_ROWS && (
                <Pagination
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                />
            )}
        </div>
    );
};

export default DocTypeListView;
