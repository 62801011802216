/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import Modal from 'react-modal';
import { formatDistanceToNowStrict } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import Select from 'react-select';
import memoSampleDoc from '../../assets/Memo Template.docx';
import { ArrowIcon, DocumentIconSvg, PlusIconSvg } from '../../assets/images';
import './DocCreateModal.scss';
import { createDocument } from '../../API/document';
import { getDocTypeInfoNotes, getDocTypes } from '../../API/document-type';
import { IDocTypePopUp } from '../../interfaces/document-type';
import STATIC_CONTENT from '../../constants/StaticContent';
import Button from '../../components/button/Button';
import CreateMemoTour from '../../components/introJs/CreateMemoTour';
import { useGAEvents } from '../../hooks/useGoogleAnalytics';
import useWindowSize from '../../hooks/use-window-size';
import useIsMount from '../../hooks/use-is-mount';
import itbrSampleDoc from '../../assets/ITBusinessRequirementsTemplate.docx';
import { IOptionValue } from '../../interfaces';

const SkeletonDocTypeList = (): JSX.Element => (
    <li>
        <h4>
            <Skeleton width={100} />
        </h4>
        <p>
            <Skeleton width={30} />
        </p>
        <ArrowIcon />
    </li>
);

const defaultOption = {
    value: '',
    label: '',
};

interface Props {
    isModalOpen: boolean;
    selectedDocType: IDocTypePopUp;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedDocType: React.Dispatch<React.SetStateAction<IDocTypePopUp>>;
    docTypesList: IDocTypePopUp[];
    setDocTypesList: React.Dispatch<React.SetStateAction<IDocTypePopUp[]>>;
}

const { DOC_CREATE } = STATIC_CONTENT;

const DocCreateModal: React.FC<Props> = ({
    isModalOpen,
    onModalClose,
    selectedDocType,
    setSelectedDocType,
    docTypesList,
    setDocTypesList,
}) => {
    const history = useHistory();
    const [inputVal, setInputVal] = useState('');
    const [sortVal, setSortVal] = useState<{ value: string; label: string }>({
        value: 'asc',
        label: 'Ascending',
    });
    const [allDocTypes, setallDocTypes] = useState<IDocTypePopUp[]>([]);
    const [createBtnLoading, setCreateBtnLoading] = useState(false);
    const [createTourBtnLoading, setcreateTourBtnLoading] = useState(false);
    const [fetchingDocTypes, setFetchingDocTypes] = useState(false);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [restepsEnabled, setrestepsEnabled] = useState(false);
    const [initialStep, setInitialStep] = useState(0);
    const { triggerEvent } = useGAEvents();
    const [isMobile, setIsMobile] = useState(false);
    const [isDocSelected, setisDocSelected] = useState(true);
    const [showTourButton, setshowTourButton] = useState(false);
    const options = [
        { value: 'asc', label: 'Ascending' },
        { value: 'desc', label: 'Descending' },
    ];
    const departments = STATIC_CONTENT.SPEC_DEPARTMENTS;
    const categories = STATIC_CONTENT.SPEC_CATEGORIES;
    const [departmentSelected, setDepartmentSelected] = useState<IOptionValue>(defaultOption);
    const [subCategorySelected, setSubCategorySelected] = useState<IOptionValue>(defaultOption);
    const [subCategory2Selected, setSubCategory2Selected] = useState<IOptionValue>(defaultOption);
    const [categorySelected, setCategorySelected] = useState<IOptionValue>(defaultOption);
    const CLCM_CATEGORY = STATIC_CONTENT.CLCM_CATEGORIES;
    const CLCM_SUB_CATEGORY = STATIC_CONTENT.CLCM_SUB_CATEGORIES;
    const CLCM_SUB_CATEGORY2 = STATIC_CONTENT.CLCM_SUB_CATEGORIES2;
    const [clcmCategory, setCLCMCategory] = useState<IOptionValue>(defaultOption);
    const [subCategories, setSubCategories] = useState<IOptionValue[]>([]);
    const [subCategories2, setSubCategories2] = useState<IOptionValue[]>([]);
    const [selectedDocTypeInfoNotes, setSelectedDocTypeInfoNotes] = useState('');
    useEffect(() => {
        if (selectedDocType.id) {
            setSelectedDocTypeInfoNotes('');
            // fetch the static content
            const fetchDocTypeInfoNotes = async () => {
                const res = await getDocTypeInfoNotes(selectedDocType.id);
                if (res?.apiStatus === 'SUCCESS') {
                    setSelectedDocTypeInfoNotes(
                        res.docTypeInfoNotesMarkup ? res.docTypeInfoNotesMarkup : '',
                    );
                }
            };
            fetchDocTypeInfoNotes();
        }
    }, [selectedDocType]);
    useEffect(() => {
        if (departmentSelected !== defaultOption) {
            const specCode = allDocTypes.filter((item) => item.code === departmentSelected.label);
            setSelectedDocTypeInfoNotes('');
            // fetch the static content
            const fetchDocTypeInfoNotes = async () => {
                const res = await getDocTypeInfoNotes(specCode[0].id);
                if (res?.apiStatus === 'SUCCESS') {
                    setSelectedDocTypeInfoNotes(
                        res.docTypeInfoNotesMarkup ? res.docTypeInfoNotesMarkup : '',
                    );
                }
            };
            fetchDocTypeInfoNotes();
        }
    }, [departmentSelected]);
    const fetchPublishedDocTypes = async () => {
        setFetchingDocTypes(true);
        // const str = event ? event.target.value : '';
        // const sortValue = sortVal ? sortVal.value : 'asc';
        const queryParams = {
            inputStr: inputVal,
            sortBy: sortVal.value,
        };
        const res = await getDocTypes(queryParams);
        if (res?.apiStatus === 'SUCCESS') {
            setDocTypesList(res.docTypeCard);
        } else {
            toast.error('something went wrong while fetching document types');
        }
        setFetchingDocTypes(false);
    };
    const fetchAllPublishedDocTypes = async () => {
        const queryParams = {
            inputStr: '',
            sortBy: sortVal.value,
        };
        const res = await getDocTypes(queryParams);
        if (res?.apiStatus === 'SUCCESS') {
            setallDocTypes(res.docTypeCard);
        } else {
            toast.error('something went wrong while fetching document types');
        }
        setFetchingDocTypes(false);
    };

    const isMount = useIsMount(); // used for rendering list on the basis of mount or updated.

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isMount) {
            fetchPublishedDocTypes();
        } else {
            const getData = setTimeout(() => {
                fetchPublishedDocTypes();
            }, 1000);
            return () => clearTimeout(getData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputVal, sortVal]);

    const handleCreateDoc = async () => {
        setCreateBtnLoading(true);
        triggerEvent('DMS_CREATE');
        let object = {};
        if(selectedDocType.code === 'APDP')
        {
            object = {
                contractType: ["Sales"],
                subCategory:  ["Grid Services"],
                subCategory2: ["API"]
            }
        }
        const res = await createDocument(selectedDocType.id, object);
        if (res?.apiStatus === 'SUCCESS') {
            history.push(`/create-document?doc_id=${res.docId}`);
            onModalClose(false);
        }
        if (res?.apiStatus === 'FAILURE') {
            toast.error(res.errorMessage);
        }
        setCreateBtnLoading(false);
    };
    const handleSpecCreateDoc = async () => {
        setCreateBtnLoading(true);
        triggerEvent('DMS_CREATE');
        const specDocumentId = allDocTypes.filter((item) => item.code === departmentSelected.label);
        const res = await createDocument(specDocumentId[0].id, {
            category: [categorySelected.value],
        });
        if (res?.apiStatus === 'SUCCESS') {
            history.push(`/create-document?doc_id=${res.docId}`);
            onModalClose(false);
        }
        if (res?.apiStatus === 'FAILURE') {
            toast.error(res.errorMessage);
        }
        setCreateBtnLoading(false);
    };

    const handleCLCMCreateDoc =async () => {
        setCreateBtnLoading(true);
        triggerEvent('DMS_CREATE');
        let CLCMDocumentId;
        let res;
        if(subCategory2Selected!==defaultOption)
        {
            CLCMDocumentId = allDocTypes.filter((item) => item.code === subCategory2Selected.label);
            res = await createDocument(CLCMDocumentId[0].id, {
                contractType: [clcmCategory.label],
                subCategory:  [subCategorySelected.value],
                subCategory2: [subCategory2Selected.value]
            });
        }
        else if(subCategorySelected !== defaultOption)
        {
            CLCMDocumentId = allDocTypes.filter((item) => item.code === subCategorySelected.label);
            res = await createDocument(CLCMDocumentId[0].id, {
                contractType: [clcmCategory.label],
                subCategory:  [subCategorySelected.value]
            });
        }
        else
        {
            CLCMDocumentId = allDocTypes.filter((item) => item.code === clcmCategory.value);
            res = await createDocument(CLCMDocumentId[0].id, {
                contractType: [clcmCategory.label]
            });
        }
        if (res?.apiStatus === 'SUCCESS') {
            history.push(`/create-document?doc_id=${res.docId}`);
            onModalClose(false);
        }
        if (res?.apiStatus === 'FAILURE') {
            toast.error(res.errorMessage);
        }
        setCreateBtnLoading(false);
    }

    const handleSubCategory = async (option: IOptionValue | null) => {
        if (option) {
            setSubCategorySelected(option);
            const subcategories2 = CLCM_SUB_CATEGORY2.filter((item) => 
                item.label === option.value
            );
            if (subcategories2.length) {
                const subcategory = subcategories2[0].options;
                const categorie2 = [...subcategory];
                setSubCategories2(categorie2);
                setSubCategory2Selected(categorie2[0]);
            }
            else{
                setSubCategories2([]);
                setSubCategory2Selected(defaultOption);
            }
        }
    };

    const handleChange = async (option: IOptionValue) => {
        setCLCMCategory(option);
        setSubCategories2([]);
        setSubCategorySelected(defaultOption);
        setSubCategory2Selected(defaultOption);
        const subcategories = CLCM_SUB_CATEGORY.filter((item) => 
            item.label === option.label
        );
        if (subcategories.length) {
            const subcategory = subcategories[0].options;
            const categorie = [...subcategory];
            setSubCategories(categorie);
            setSubCategorySelected(categorie[0]);
            handleSubCategory(categorie[0]);
        }
        else{
            setSubCategories([]);
        }
    };

    const showIntro = async () => {
        setcreateTourBtnLoading(true);
        setshowTourButton(true);
        setrestepsEnabled(true);
        setcreateTourBtnLoading(false);
    };

    const size = useWindowSize();
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        size.width < 576 ? setIsMobile(true) : setIsMobile(false);
    }, [size]);

    useEffect(() => {
        fetchAllPublishedDocTypes();
    }, []);
    
    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={() => onModalClose(false)}
            contentLabel="Example Modal"
            className="doc-create__modal"
        >
            <div className="doc-type-list">
                {isMobile ? null : (
                    <PlusIconSvg className="close-btn" onClick={() => onModalClose(false)} />
                )}
                {!isDocSelected && isMobile ? (
                    <div
                        className="back-label"
                        aria-hidden="true"
                        onClick={() => setisDocSelected(true)}
                    >
                        <ArrowIcon className="back-icon" />
                        <span>Back</span>
                    </div>
                ) : (
                    ''
                )}
                <div className={`doc-type-list__header ${isDocSelected ? '' : 'hidden'}`}>
                    <div className="doc-type__info">
                        <DocumentIconSvg />
                        <div className="create-doc-heading">
                            <h1>
                                {isMobile
                                    ? DOC_CREATE.LABEL.MOBILE_HEADING
                                    : DOC_CREATE.LABEL.HEADING}
                            </h1>
                            <p>
                                {docTypesList.length === 0
                                    ? DOC_CREATE.LABEL.NO_DOC_AVAILABLE
                                    : `${docTypesList.length}${DOC_CREATE.LABEL.DOCS_AVAILABLE}`}{' '}
                            </p>
                        </div>
                    </div>
                    <div className="doc-type-search">
                        <Button
                            type="button"
                            className="primary-btn"
                            onClick={() => showIntro()}
                            disabled={createTourBtnLoading}
                            isLoading={createTourBtnLoading}
                        >
                            Creating Memo?
                        </Button>
                        {/* <input
                            type="text"
                            autoComplete="off"
                            name="search-doc-type"
                            id="search-doc-type"
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            placeholder="Search"
                        /> */}
                    </div>
                </div>
                <div className="doc-type-list__content">
                    <div className={`list ${isDocSelected ? '' : 'hidden'}`}>
                        <div className="doc-type-filters">
                            <div className="doc-type-search">
                                <input
                                    type="text"
                                    autoComplete="off"
                                    name="search-doc-type"
                                    id="search-doc-type"
                                    onChange={(e) => {
                                        setInputVal(e.target.value);
                                    }}
                                    placeholder="Search Document Type"
                                />
                            </div>
                            <div className="doc-type-sort">
                                <label htmlFor="doctype-sort"> Sort By</label>
                                <Select
                                    name="doctype-sort"
                                    isMulti={false}
                                    className="react-select"
                                    classNamePrefix="select"
                                    value={sortVal}
                                    options={options}
                                    isSearchable={false}
                                    onChange={(option) => {
                                        if (option && option.value) setSortVal(option);
                                    }}
                                    placeholder="Sort by"
                                />
                            </div>
                        </div>
                        {fetchingDocTypes ? (
                            <ul>
                                <SkeletonDocTypeList />
                                <SkeletonDocTypeList />
                                <SkeletonDocTypeList />
                            </ul>
                        ) : (
                            <>
                                {docTypesList.length > 0 ? (
                                    <ul>
                                        {docTypesList.map((docType) => (
                                            <li
                                                key={docType.id}
                                                role="presentation"
                                                onKeyDown={() => {
                                                    setSelectedDocType(docType);
                                                }}
                                                onClick={() => {
                                                    setisDocSelected(false);
                                                    setSelectedDocType(docType);
                                                }}
                                                className={`${
                                                    selectedDocType.id === docType.id
                                                        ? 'active'
                                                        : ''
                                                }`}
                                            >
                                                <h4>{docType.name}</h4>{' '}
                                                <p>{`updated ${formatDistanceToNowStrict(
                                                    docType.updatedAt,
                                                )} ago`}</p>
                                                <ArrowIcon />
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <div className="no-docs-found">No Document Types Found</div>
                                )}
                            </>
                        )}
                    </div>
                    
                    <div className={`doc-type-detail ${isDocSelected ? 'hidden' : ''}`}>
                        {selectedDocType.id ? (
                            <>
                                {selectedDocType.name !== 'SPEC' &&
                                selectedDocType.code !== 'CLCM' ? (
                                    <>
                                        <div className="doc-type-detail__header">
                                            <div>
                                                <h1>{selectedDocType.name}</h1>
                                            </div>
                                            <Button
                                                type="button"
                                                className="primary-btn"
                                                onClick={() => handleCreateDoc()}
                                                disabled={createBtnLoading}
                                                isLoading={createBtnLoading}
                                            >
                                                {DOC_CREATE.BUTTON.CREATE}
                                            </Button>
                                        </div>

                                        {selectedDocTypeInfoNotes ? (
                                            <div
                                                className="doc-type-detail__content"
                                                style={{ color: '#5F6062' }}
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: selectedDocTypeInfoNotes,
                                                    }}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        fontSize: '14px',
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                    </>
                                ) : <></>}
                                
                                {(selectedDocType.name === 'SPEC' ? (
                                    <>
                                        <div className="doc-type-detail__header">
                                            <div>
                                                <h1>{selectedDocType.name}</h1>
                                            </div>
                                            <Button
                                                type="button"
                                                className="primary-btn"
                                                onClick={() => handleSpecCreateDoc()}
                                                disabled={
                                                    createBtnLoading ||
                                                    departmentSelected === defaultOption ||
                                                    categorySelected === defaultOption
                                                }
                                                isLoading={createBtnLoading}
                                            >
                                                {DOC_CREATE.BUTTON.CREATE}
                                            </Button>
                                        </div>

                                        <div className="options">
                                            <div className="category">
                                                <label className="category-label">Category</label>
                                                <Select
                                                    className="react-select"
                                                    options={categories}
                                                    value={categorySelected}
                                                    placeholder="Select Category"
                                                    onChange={(option) => {
                                                        setCategorySelected(
                                                            option || defaultOption,
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="department">
                                                <label className="department-label">
                                                    Department
                                                </label>
                                                <Select
                                                    className="react-select"
                                                    options={departments}
                                                    value={departmentSelected}
                                                    getOptionLabel={(e) =>
                                                        e?.value !== ''
                                                            ? `${e.value} (${e.label}-#####)`
                                                            : ''
                                                    }
                                                    placeholder="Select Department"
                                                    onChange={(option) => {
                                                        setDepartmentSelected(
                                                            option || defaultOption,
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {departmentSelected !== defaultOption ? (
                                            <>
                                                <br />
                                                <h3>{departmentSelected.value}</h3>
                                                <h4>Prefix: {departmentSelected.label}</h4>
                                                <h4>
                                                    Suffix: ##### (This number will be auto
                                                    generated)
                                                </h4>
                                                <h4>
                                                    Document Number Format:{' '}
                                                    {departmentSelected.label}-#####
                                                </h4>
                                                {selectedDocTypeInfoNotes ? (
                                                    <div
                                                        className="doc-type-detail__content"
                                                        style={{ color: '#5F6062' }}
                                                    >
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: selectedDocTypeInfoNotes,
                                                            }}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                fontSize: '14px',
                                                            }}
                                                        />
                                                    </div>
                                                ) : null}
                                            </>
                                        ) : null}
                                        {selectedDocTypeInfoNotes ? (
                                            <div
                                                className="doc-type-detail__content"
                                                style={{ color: '#5F6062' }}
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: selectedDocTypeInfoNotes,
                                                    }}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        fontSize: '14px',
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                    </>
                                ) : <></>)} 
                                
                                { selectedDocType.code === 'CLCM' ? (
                                    <>
                                        <div className="doc-type-detail__header">
                                            <div>
                                                <h1>{selectedDocType.name}</h1>
                                            </div>
                                            <Button
                                                type="button"
                                                className="primary-btn"
                                                onClick={() => handleCLCMCreateDoc()}
                                                disabled={
                                                    createBtnLoading ||
                                                    clcmCategory === defaultOption ||
                                                    (subCategories.length > 0 &&
                                                        subCategorySelected == null) ||
                                                    (subCategories2.length > 0 &&
                                                        subCategory2Selected == null)
                                                }
                                                isLoading={createBtnLoading}
                                            >
                                                {DOC_CREATE.BUTTON.CREATE}
                                            </Button>
                                        </div>

                                        <div className="options">
                                            <div className="category">
                                                <label className="category-label">Category</label>
                                                <Select
                                                    className="react-select"
                                                    options={CLCM_CATEGORY}
                                                    value={clcmCategory}
                                                    placeholder="Select Category"
                                                    onChange={(option) => {
                                                        handleChange(option || defaultOption);
                                                    }}
                                                />
                                            </div>
                                            {subCategories.length ? (
                                                <div className="department">
                                                    <label className="department-label">
                                                        Sub Category
                                                    </label>
                                                    <Select
                                                        className="react-select"
                                                        options={subCategories}
                                                        value={subCategorySelected}
                                                        getOptionLabel={(e) =>
                                                            e?.value !== ''
                                                                ? `${e.value}`
                                                                : ''
                                                        }
                                                        placeholder="Select Sub Category"
                                                        onChange={(option) => {
                                                            handleSubCategory(option);
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                            {subCategories2.length ? (
                                                <div className="department">
                                                    <label className="department-label">
                                                        Sub Category 2
                                                    </label>
                                                    <Select
                                                        className="react-select"
                                                        options={subCategories2}
                                                        value={subCategory2Selected}
                                                        getOptionLabel={(e) =>
                                                            e?.value !== ''
                                                                ? `${e.value}`
                                                                : ''
                                                        }
                                                        placeholder="Select Sub Category"
                                                        onChange={(option) => {
                                                            setSubCategory2Selected(option || defaultOption);
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                        {selectedDocTypeInfoNotes ? (
                                            <div
                                                className="doc-type-detail__content"
                                                style={{ color: '#5F6062' }}
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: selectedDocTypeInfoNotes,
                                                    }}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        fontSize: '14px',
                                                    }}
                                                />
                                            </div>
                                        ) : null}
                                    </>
                                ) : <></>}

                                {selectedDocType.name
                                    .toLocaleLowerCase()
                                    .includes('leadership promotion nomination form') && (
                                    <div
                                        className="doc-type-detail__content"
                                        style={{ color: '#5F6062' }}
                                    >
                                        {/* <p>{selectedDocType.description}</p> */}
                                        <br />
                                        <p style={{ margin: '0' }}>
                                            Managers can use this form to nominate employees for
                                            promotions to leadership roles (to grades 08 and above)
                                            round the year. The form captures details about the
                                            employee’s overall experience, tenure at Enphase,
                                            performance data, 360 feedback etc. Please ensure that
                                            a) you have completed 360 feedback process prior to
                                            filling the form, and b) you have recommendation via
                                            email from NEO or NEO designate.
                                        </p>
                                        <br />
                                        {/* <img src={MemoSample} width="100%" alt="memo-sample" /> */}
                                        <p style={{ margin: '0 0 2px' }}>Steps to create:</p>
                                        <ol style={{ padding: '0 0 0 20px' }}>
                                            <li style={{ marginBottom: '2px' }}>
                                                {DOC_CREATE.LABEL.LEADERSHIPFORM_1}
                                            </li>
                                            <li style={{ marginBottom: '2px' }}>
                                                {DOC_CREATE.LABEL.LEADERSHIPFORM_2}
                                            </li>
                                            <li style={{ marginBottom: '2px' }}>
                                                {DOC_CREATE.LABEL.LEADERSHIPFORM_3}
                                            </li>
                                            <li style={{ marginBottom: '2px' }}>
                                                {DOC_CREATE.LABEL.LEADERSHIPFORM_4}
                                            </li>
                                            <li style={{ marginBottom: '2px' }}>
                                                {DOC_CREATE.LABEL.LEADERSHIPFORM_5}
                                            </li>
                                            <li style={{ marginBottom: '2px' }}>
                                                {DOC_CREATE.LABEL.LEADERSHIPFORM_6}
                                            </li>
                                            <li style={{ marginBottom: '2px' }}>
                                                {DOC_CREATE.LABEL.LEADERSHIPFORM_7}
                                            </li>
                                            <li style={{ marginBottom: '2px' }}>
                                                {DOC_CREATE.LABEL.LEADERSHIPFORM_8}
                                            </li>
                                        </ol>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <h4 className="select-doc-type">
                                    {DOC_CREATE.LABEL.SELECT_DOCUMENT_TYPE}
                                </h4>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <CreateMemoTour
                initialStep={initialStep}
                stepsEnabled={stepsEnabled}
                setStepsEnabled={setStepsEnabled}
                onModalClose={onModalClose}
                selectedDocType={selectedDocType}
                setSelectedDocType={setSelectedDocType}
                docTypesList={docTypesList}
                setDocTypesList={setDocTypesList}
            />
            {showTourButton && (
                <CreateMemoTour
                    initialStep={initialStep}
                    stepsEnabled={restepsEnabled}
                    setStepsEnabled={setrestepsEnabled}
                    onModalClose={onModalClose}
                    selectedDocType={selectedDocType}
                    setSelectedDocType={setSelectedDocType}
                    docTypesList={docTypesList}
                    setDocTypesList={setDocTypesList}
                />
            )}
        </Modal>
    );
};
export default DocCreateModal;
