import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

import { CopyIconSvg } from '../assets/images';
import { copyToClipBoard } from '../utils';
import { SkeletonText } from './Skeleton';

interface Props {
    text: string;
    // eslint-disable-next-line react/require-default-props
    docTypeBackgroundColor?: string;
    // eslint-disable-next-line react/require-default-props
    link?: string;
}

// eslint-disable-next-line max-lines-per-function
const CopyText = ({ text,docTypeBackgroundColor,link }: Props): JSX.Element => {
    const [copied, setCopied] = useState(false);
    const [props, api] = useSpring(() => ({
        backgroundColor: '#f3f3f3',
        cursor: 'pointer'
    }));

    const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
        usePopperTooltip({
            placement: 'bottom',
            trigger: 'click',
            closeOnOutsideClick: false,
            visible: copied,
            onVisibleChange: setCopied,
        });

    useEffect(() => {
        if (copied) {
            setTimeout(() => setCopied(false), 1000);
        }
    }, [copied]);

    return (
        <animated.span
            style={props}
            className="copy-text"
            onClick={() => {
                setCopied(true);
                if (link !== undefined) {
                    copyToClipBoard(link);
                } else {
                    copyToClipBoard(text);
                }
            }}
        >
            <h4>
                <SkeletonText text={text} width={50} />
            </h4>
            <span ref={setTriggerRef}>
                <CopyIconSvg />
            </span>
            {visible && (
                <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
                    Copied
                    <div {...getArrowProps({ className: 'tooltip-arrow' })} />
                </div>
            )}
        </animated.span>
    );
};

export default CopyText;
