import React from 'react';
import Toggle from '../../components/toggle/Toggle';
import { useDocTypeContext } from '../../contexts/document-type-provider';
import DocTypeNumbering from '../doc-type-numbering/DocTypeNumbering';

import './DocTypeAdditionalConfig.scss';

// eslint-disable-next-line max-lines-per-function
const DocTypeAdditionalConfig: React.FC = () => {
    const {
        docTypeConfig,
        setDocTypeConfig,
        docTypeInfo,
        generationTrigger,
        setGenerationTrigger,
        docTypeErrors,
        changeMaxRepublishCount,
    } = useDocTypeContext();
    return (
        <div className="doc-type-setting-config">
            <h2>Configuration</h2>

            <div className="config-item first">
                <h4 className="config-item--title">Document Type is Confidential</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.isConfidential}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                isConfidential: !docTypeConfig.isConfidential,
                            })
                        }
                    />
                </div>
            </div>
            <div className="config-item">
                <h4 className="config-item--title">Collect comment while Approving or Rejecting</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.collectComment}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                collectComment: !docTypeConfig.collectComment,
                            })
                        }
                    />
                </div>
            </div>
            <div className="config-item">
                <h4 className="config-item--title">Document Type is Contract</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.isContract}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                isContract: !docTypeConfig.isContract,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Allow Export</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowExport}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowExport: !docTypeConfig.allowExport,
                            })
                        }
                    />
                </div>
            </div>
            <div className="config-item">
                <h4 className="config-item--title">Allow Template Generation</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowTemplateGeneration}
                        isDisabled={false}
                        // isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowTemplateGeneration: !docTypeConfig.allowTemplateGeneration,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Allow For DCO</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowDCO}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowDCO: !docTypeConfig.allowDCO,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Anyone Can Revise</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowReviseByAnyone}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowReviseByAnyone: !docTypeConfig.allowReviseByAnyone,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Anyone Can View In Process Doc</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowViewingInProcessDocByAnyone}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowViewingInProcessDocByAnyone: !docTypeConfig.allowViewingInProcessDocByAnyone,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Allow Custom Versioning</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.allowCustomVersioning}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                allowCustomVersioning: !docTypeConfig.allowCustomVersioning,
                            })
                        }
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Block Revise</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.blockRevise}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                blockRevise: !docTypeConfig.blockRevise,
                            })
                        }
                    />
                </div>
            </div>

            <div className="generate-num-outer">
                <label htmlFor="scope-type">Generate Number on</label>
                <div className="select-handler">
                    <input
                        type="radio"
                        id="submit"
                        name="submit"
                        value="submit"
                        checked={generationTrigger.toUpperCase() === 'ON_SUBMIT'}
                        onChange={() => setGenerationTrigger('ON_SUBMIT')}
                        disabled={docTypeInfo.status === 'PUBLISHED'}
                    />
                    <label htmlFor="submit">Submit</label>
                    <input
                        type="radio"
                        id="create"
                        name="create"
                        value="create"
                        checked={generationTrigger.toUpperCase() === 'ON_CREATE'}
                        onChange={() => setGenerationTrigger('ON_CREATE')}
                        disabled={docTypeInfo.status === 'PUBLISHED'}
                    />
                    <label htmlFor="create">Create</label>
                </div>
            </div>
            <div className="config-item">
                <h4 className="config-item--title">Enable Sunset Phase</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.isSunsetEnabled}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                isSunsetEnabled: !docTypeConfig.isSunsetEnabled,
                            })
                        }
                    />
                </div>
            </div>
            {docTypeConfig.isSunsetEnabled && (
                <div className="config-item">
                    <h4 className="config-item--title">Max Republish</h4>
                    <div className="config-item--section">
                        <input
                            type="number"
                            className={`${docTypeErrors.maxRepublishCount ? 'error' : ''
                                } effective-period`}
                            onKeyDown={(e) => {
                                if (e.key === '.') e.preventDefault();
                            }}
                            value={Number(docTypeConfig.maxRepublishCount)}
                            disabled={docTypeInfo.status === 'PUBLISHED'}
                            onChange={(e) => {
                                changeMaxRepublishCount(e.target.valueAsNumber);
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="config-item">
                <h4 className="config-item--title">Publish Once Approved</h4>
                <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.publishOnceApproved}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                publishOnceApproved: !docTypeConfig.publishOnceApproved,
                            })
                        }
                    />
                </div>
            </div>
            {docTypeConfig.isSunsetEnabled && (
                <div className="config-item">
                    <h4 className="config-item--title">Effective Till Date</h4>
                    <div className="config-item--section">
                    <Toggle
                        isChecked={docTypeConfig.isEndDateAsked}
                        isDisabled={docTypeInfo.status === 'PUBLISHED'}
                        onclick={() =>
                            setDocTypeConfig({
                                ...docTypeConfig,
                                isEndDateAsked: !docTypeConfig.isEndDateAsked,
                            })
                        }
                    />
                    </div>
                </div>
            )}
            <div className="config-item">
                <h4 className="config-item--title">Details Section Title</h4>
                <div className="config-item--section">
                    <input
                        className={`${docTypeErrors.code ? 'error' : ''}`}
                        name=""
                        id=""
                        disabled={docTypeInfo.status === 'PUBLISHED'}
                        value={docTypeConfig.detailsSectionTitle}
                        autoComplete="off"
                        onChange={(e)=>{
                            setDocTypeConfig({
                                ...docTypeConfig,
                                detailsSectionTitle: e.target.value
                            })
                        }}
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Attachments Section Title</h4>
                <div className="config-item--section">
                    <input
                        className={`${docTypeErrors.code ? 'error' : ''}`}
                        name=""
                        id=""
                        disabled={docTypeInfo.status === 'PUBLISHED'}
                        value={docTypeConfig.attachmentSectionTitle}
                        autoComplete="off"
                        onChange={(e)=>{
                            setDocTypeConfig({
                                ...docTypeConfig,
                                attachmentSectionTitle: e.target.value
                            })
                        }}
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Approvers Section Title</h4>
                <div className="config-item--section">
                    <input
                        className={`${docTypeErrors.code ? 'error' : ''}`}
                        name=""
                        id=""
                        disabled={docTypeInfo.status === 'PUBLISHED'}
                        value={docTypeConfig.approversSectionTitle}
                        autoComplete="off"
                        onChange={(e)=>{
                            setDocTypeConfig({
                                ...docTypeConfig,
                                approversSectionTitle: e.target.value
                            })
                        }}
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Approved Time Stamp</h4>
                <div className="config-item--section">
                    <input
                        className={`${docTypeErrors.code ? 'error' : ''}`}
                        name=""
                        id=""
                        disabled={docTypeInfo.status === 'PUBLISHED'}
                        value={docTypeConfig.approverTimeStamp}
                        autoComplete="off"
                        onChange={(e)=>{
                            setDocTypeConfig({
                                ...docTypeConfig,
                                approverTimeStamp: e.target.value
                            })
                        }}
                    />
                </div>
            </div>

            <div className="config-item">
                <h4 className="config-item--title">Document Numbering</h4>
                <div className="config-item--section">
                    <DocTypeNumbering />
                </div>
            </div>

        </div>
    );
};

export default DocTypeAdditionalConfig;
