/* eslint-disable no-nested-ternary */
import React from 'react';
import { isValid, getMilliseconds } from 'date-fns';
import Skeleton from 'react-loading-skeleton';

import { addToFavorites, removeFromFavorites } from '../API/dashboard';
import { PlusIconSvg, StarIconSvg } from '../assets/images';
import CONSTANTS from '../constants';
import { IDocStatus } from '../interfaces';
import { formatDate } from '../utils/date';
import './General.scss';

const AddButton: React.FC = () => (
    <span className="add-button">
        <PlusIconSvg />
    </span>
);

interface IDivAsButton {
    // eslint-disable-next-line react/require-default-props
    className?: string;
    children: React.ReactNode;
    onClick: () => void;
}

const DivAsButton: React.FC<IDivAsButton> = ({ className, children, onClick }: IDivAsButton) => (
    <div
        className={className}
        onClick={() => onClick()}
        role="button"
        tabIndex={0}
        onKeyDown={() => onClick()}
    >
        {children}
    </div>
);

const DateColumn = ({ value }: { value: Date }): JSX.Element => (
    <>
        {isValid(value) ? (
            getMilliseconds(value) === 1 ? (
                <Skeleton width={150} />
            ) : (
                formatDate(value)
            )
        ) : (
            '-'
        )}
    </>
);

const StatusColumn = ({ value }: { value: IDocStatus }): JSX.Element =>
    value && (
        <div className={`status-pill ${value.toLocaleLowerCase()}`}>
            {value === 'NEW' ? <Skeleton width={80} /> : CONSTANTS.DOCUMENT_STATUS[value]}
        </div>
    );

const NoDocumentsFound = ({ heading }: { heading: string }): JSX.Element => (
    <div className="no-documents-found">
        <h1>{heading}</h1>
    </div>
);

const NoDocumentsReferenceFound = ({ heading }: { heading: string }): JSX.Element => (
    <div className="no-documents-reference-found">
        <h1>{heading}</h1>
    </div>
);

const NoPermission = (): JSX.Element => (
    <div className="no-permission">
        <h1>You do not have permissions to view this document</h1>
    </div>
);

const InActiveDocument = (): JSX.Element => (
    <div className="no-permission">
        <h1>This form is closed for new submission until further notice by HR</h1>
    </div>
);

const NoEditPermission = (): JSX.Element => (
    <div className="no-permission">
        <h1>You do not have permissions to edit this document</h1>
    </div>
);

interface IFavoriteComponentProps {
    docId: number;
    isActive: boolean;
    onClickHandler: () => void;
}
const FavoriteComponent: React.FC<IFavoriteComponentProps> = ({
    docId,
    isActive,
    onClickHandler,
}) => {
    const onClick = async () => {
        if (isActive) {
            const res = await removeFromFavorites(docId);
            if (res?.apiStatus === 'SUCCESS') {
                onClickHandler();
            }
        } else {
            const res = await addToFavorites(docId);
            if (res?.apiStatus === 'SUCCESS') {
                onClickHandler();
            }
        }
    };

    return (
        <div className="favorite-component">
            <StarIconSvg className={`star ${isActive ? 'active' : ''} `} onClick={onClick} />
        </div>
    );
};

export {
    AddButton,
    DivAsButton,
    DateColumn,
    StatusColumn,
    NoDocumentsFound,
    NoDocumentsReferenceFound,
    FavoriteComponent,
    NoPermission,
    NoEditPermission,
    InActiveDocument,
};
