export const getFromLocalStorage = (key: string): string | null => localStorage.getItem(key);

export const setLocalStorage = (key: string, value: string): void => {
    localStorage.setItem(key, value);
};

export const removeFromLocalStorage = (key: string): void => {
    localStorage.removeItem(key);
};

export const clearAllLocalStorage = (): void => localStorage.clear();

export const keyExistInLocalStorage = (key: string): boolean =>
    !(localStorage.getItem(key) === null); 
