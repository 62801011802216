/* eslint-disable no-debugger */
/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import { useDocumentContext } from '../../../contexts/document-provider';
import './EditAttachments.scss';
import useFileUpload from '../../../hooks/use-file-upload';
import {
    IAttachmentConfig,
    IAttachmentMeta,
    IFileUpload,
    IAttachment,
} from '../../../interfaces/attachment';
import { createAttachment, deleteAttachment } from '../../../API/attachments';
import { getExtension } from '../../../utils';
import { PlusIconSvg } from '../../../assets/images';
import CONSTANTS from '../../../constants';
import STATIC_CONTENT from '../../../constants/StaticContent';

interface IAttachmentProps {
    attachmentConfig: IAttachmentConfig;
    index: number;
    attachments: IAttachment[];
    fetchAttachments: () => Promise<void>;
    errorFields: string[];
}
const { CREATE_DOCUMENTS } = STATIC_CONTENT;

const Attachment: React.FC<IAttachmentProps> = ({
    attachmentConfig,
    index,
    attachments,
    fetchAttachments,
    errorFields,
}: IAttachmentProps) => {
    const { uploadFile } = useFileUpload();
    const [fileName, setFileName] = useState('');
    const { documentId, selectedDocVersion, setIsFileUploading } = useDocumentContext();
    const [uploading, setUploading] = useState(false);

    const uploadAttachment = async (file: File, metaData: IAttachmentMeta) => {
        setIsFileUploading(true);
        setUploading(true);
        const res = await createAttachment(
            documentId,
            selectedDocVersion.value as number,
            file,
            metaData,
        );
        if (res?.apiStatus === 'FAILURE') {
            toast(CREATE_DOCUMENTS.TOAST.ERROR_MSG.UNABLE_TO_UPLOAD);
        }
        if (res?.apiStatus === 'SUCCESS') {
            await fetchAttachments();
            setFileName('');
        }
        setUploading(false);
        setIsFileUploading(false);
    };

    const onClick = async () => {
        // const regex = new RegExp(/^[ A-Za-z0-9_@.*()#&-]*$/);
        const regexForName = new RegExp(/^[ A-Za-z0-9_@*()#&-]*$/);
        uploadFile(
            {
                accept: attachmentConfig.allowedFileTypes.map((a) => `.${a}`).toString(),
            },
            async ({ name, size, file, mimeType }: IFileUpload) => {
                const metaData: IAttachmentMeta = {
                    attachmentType: attachmentConfig.attachmentType,
                    displayName: attachmentConfig.displayName,
                    extension: getExtension(name),
                    mimeType,
                    name,
                    size,
                };
                const nameWithoutExtension = name.replace(/\.[^.]*$/, '');
                if (regexForName.test(nameWithoutExtension)) {
                    if (
                        attachmentConfig.allowedFileTypes.length > 0 &&
                        !attachmentConfig.allowedFileTypes
                            .map((a) => a.toLowerCase())
                            .includes(metaData.extension.toLowerCase())
                    ) {
                        toast.error(
                            CREATE_DOCUMENTS.TOAST.ERROR_MSG.FILE_NOT_SUPPORTED +
                            attachmentConfig.allowedFileTypes.join(', ').toLowerCase(),
                        );
                    } else if (size > 26214400) {
                        toast.error(CREATE_DOCUMENTS.TOAST.ERROR_MSG.FILE_LARGER_THAN_25);
                    } else {
                        setFileName(name);
                        if (attachmentConfig.allowMultiple) {
                            await uploadAttachment(file, metaData);
                        } else {
                            const [attachment] = attachments;
                            if (attachment) {
                                const res = await deleteAttachment(attachment.id);
                                if (res === 'SUCCESS') {
                                    await uploadAttachment(file, metaData);
                                }
                                if (res === 'FAILURE') {
                                    toast.error(CREATE_DOCUMENTS.TOAST.ERROR_MSG.UNABLE_TO_DELETE);
                                }
                            } else {
                                await uploadAttachment(file, metaData);
                            }
                        }
                    }
                } else {
                    toast.error(CREATE_DOCUMENTS.TOAST.ERROR_MSG.INVALID_FILENAME)
                }

            },
        );
    };

    const removeAttachment = async (attachmentId: number) => {
        const res = await deleteAttachment(attachmentId);
        if (res === 'SUCCESS') {
            fetchAttachments();
        }
        if (res === 'FAILURE') {
            toast.error(CREATE_DOCUMENTS.TOAST.ERROR_MSG.UNABLE_TO_DELETE);
        }
    };

    return (
        <div key={index}>
            <label
                htmlFor="cover-letter"
                className={attachmentConfig.isMandatory ? 'mandatory-field' : ''}
            >
                {attachmentConfig.displayName}
            </label>
            <button
                type="button"
                className={`${errorFields.includes(attachmentConfig.attachmentType) ? ' error-btn ' : ''
                    }`}
                onClick={() => onClick()}
                disabled={uploading}
            >
                {!attachmentConfig.allowMultiple && attachments.length === 1
                    ? 'Replace'
                    : 'Upload a file'}
            </button>
            <div className="attachment-list">
                {attachments.map((a) => (
                    <div className="attachment-item">
                        <h5>{a.fileMeta.name}</h5>
                        <div>
                            <PlusIconSvg onClick={() => removeAttachment(a.id)} />
                        </div>
                    </div>
                ))}
                {fileName && (
                    <div className="attachment-item">
                        <h5>{fileName}</h5>
                        <Loader type="Oval" color={CONSTANTS.BRAND_COLOR} height={14} width={14} />
                    </div>
                )}
            </div>
        </div>
    );
};

const EditAttachments: React.FC = () => {
    const {
        attachmentConfig,
        attachments,
        fetchAttachments,
        isPRB,
        isTravelAuthorizationForm,
        error: {
            STEP2: { errorFields },
        },
    } = useDocumentContext();
    return (
        <div className="edit-attachments">
            {attachmentConfig.length > 0 &&
                attachmentConfig.map((attachment, index) => (
                    <Attachment
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        attachmentConfig={attachment}
                        index={index}
                        errorFields={errorFields}
                        fetchAttachments={fetchAttachments}
                        attachments={attachments.filter(
                            (a) => a.fileMeta.attachmentType === attachment.attachmentType,
                        )}
                    />
                ))}
            <span className="note">{CREATE_DOCUMENTS.NOTE}{attachmentConfig[0]?.allowedFileTypes.join(",").toLowerCase()}</span>
            <span className='note'>{CREATE_DOCUMENTS.NOTE_END}</span>
            {isPRB && (
                <span className='note'>{CREATE_DOCUMENTS.PRB_FORM}</span>
            )}
            {isTravelAuthorizationForm && (
                <span className='note'>* Optional: please upload any supporting documents for this travel.</span>
            )}
        </div>
    );
};

export default EditAttachments;
