import React, { useState, useEffect } from 'react';
import DelegationSetting from '../../components/delegation-setting/DelegationSetting';
import NotificationsSetting from '../../components/notification-setting/NotificationsSetting';
import { ISettingTabs } from '../../interfaces/settings';
import './Settings.scss';
import STATIC_CONTENT from '../../constants/StaticContent';
import InactiveApproversSetting from '../../components/inactive-approvers/user/InactiveApproversSetting';
import InactiveApproversAdminSetting from '../../components/inactive-approvers/admin/InactiveApproversAdminSetting';
import ApprovalTasksAdminSetting from '../../components/approval-tasks/admin/TaskApproversAdminSetting';

import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { useQueryParams } from '../../contexts/query-params-provider';
import DmsGroup from '../../components/dms-group/DmsGroup';
import GroupProvider from '../../contexts/group-provider';
import DocumentAdminSettings from '../../components/document-admin-settings/DocumentAdminSettings';
import SunsetPage from '../sunset/SunsetNav';
import HRBPInfoAdminSettings from '../../components/hrbp-info-admin-settings/HRBPInfoAdminSettings';

const { SETTINGS_PAGE } = STATIC_CONTENT;

const settingTabs: ISettingTabs[] = [
    'NOTIFICATIONS',
    'DELEGATION',
    'INACTIVE_APPROVERS',
    'SUNSET_DOCUMENTS',
];

const adminSettingTabs: ISettingTabs[] = ['INACTIVE_APPROVERS_ADMIN', 'DMS_GROUP', 'APPROVAL_TASKS','DOCUMENT' , 'HRBP_INFO'];

// eslint-disable-next-line max-lines-per-function
const Settings: React.FC = () => {
    const { getQueryParam } = useQueryParams();
    const tabParam = getQueryParam('tab');
    const [activeTab, setActiveTab] = useState<ISettingTabs>('NOTIFICATIONS');
    const { user, getUser } = useAuthDataContext();

    useEffect(() => {
        if (tabParam) setActiveTab(tabParam as ISettingTabs);
    }, [tabParam]);

   useEffect(() => {
    const GetUserByEmail = async () => {
        await getUser();
    }
    GetUserByEmail();
   },[])

    return (
        <div className="settings-page">
            <GroupProvider>
                <div className="setting-tabs">
                    <h1>{SETTINGS_PAGE.HEADING}</h1>
                    <ul>
                        {settingTabs.map((tab) => (
                            <li
                                role="presentation"
                                className={activeTab === tab ? 'active' : ''}
                                onClick={() => setActiveTab(tab)}
                            >
                                {SETTINGS_PAGE.TABS[tab]}
                            </li>
                        ))}
                        {user.isAdmin && (
                            <>
                                <h1 className="admin-heading">{SETTINGS_PAGE.ADMIN_HEADING}</h1>
                                {adminSettingTabs.map((tab) => (
                                    <li
                                        role="presentation"
                                        className={activeTab === tab ? 'active' : ''}
                                        onClick={() => setActiveTab(tab)}
                                    >
                                        {SETTINGS_PAGE.TABS[tab]}
                                    </li>
                                ))}
                            </>
                        )}
                    </ul>
                </div>
                <div className="setting-main">
                    <h1>{SETTINGS_PAGE.TABS[activeTab]} Settings</h1>
                    {activeTab === 'NOTIFICATIONS' && (
                        <>
                            <p>{SETTINGS_PAGE.NOTIFICATION_TAB_TIP}</p>
                            <NotificationsSetting />
                        </>
                    )}
                    {activeTab === 'DELEGATION' && <DelegationSetting />}
                    {activeTab === 'INACTIVE_APPROVERS' && <InactiveApproversSetting />}

                    {activeTab === 'INACTIVE_APPROVERS_ADMIN' && <InactiveApproversAdminSetting />}
                    {activeTab === 'SUNSET_DOCUMENTS' && <SunsetPage />}
                    {activeTab === 'DMS_GROUP' && <DmsGroup />}
                    {activeTab === 'APPROVAL_TASKS' && <ApprovalTasksAdminSetting />}

                    {activeTab === 'DOCUMENT' && <DocumentAdminSettings />}
                    {activeTab === 'HRBP_INFO' && <HRBPInfoAdminSettings />}
                </div>
            </GroupProvider>
        </div>
    );
};

export default Settings;
