import React, { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import './DocumentAction.scss';
import CONSTANTS from '../../constants';
import { IDocumentActionTypes } from '../../interfaces/document';
import { PlusIconSvg } from '../../assets/images';
import { createComment } from '../../API/comment';
import STATIC_CONTENT from '../../constants/StaticContent';

const { ACTION_MODAL, DOC_COMMENTS } = STATIC_CONTENT;

interface Props {
    isModalOpen: boolean;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    onClick: (type: IDocumentActionTypes) => Promise<void>;
    type: IDocumentActionTypes;
    activeDocVersion: number;
    allowReviseByAnyone: boolean;
}

// eslint-disable-next-line max-lines-per-function
const ActionModal: React.FC<Props> = ({
    isModalOpen,
    onModalClose,
    onClick,
    type,
    activeDocVersion,
    allowReviseByAnyone
}: Props) => {
    const [comment, setComment] = useState('');

    const onSubmit = async () => {
        if (comment) {
            const res = await createComment(
                activeDocVersion,
                `${CONSTANTS.RANDOM_COMMENT_STRINGS[type]}${comment}`,
                allowReviseByAnyone
            );
            if (res?.apiStatus === 'FAILURE') {
                toast.error(DOC_COMMENTS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
            }
            setComment('');
        }
        onClick(type);
        onModalClose(false);
    };

    return (
        <div className="action-modal">
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => onModalClose(false)}
                contentLabel="Example Modal"
                className="action__modal"
            >
                <div>
                    <PlusIconSvg className="close-btn" onClick={() => onModalClose(false)} />
                    <h1>{`Are you sure you want to ${type.toLocaleLowerCase()}?`}</h1>
                    <span>{ACTION_MODAL.LABEL.WARNING}</span>
                    <div className="comment-section">
                        <label htmlFor="comment">{ACTION_MODAL.LABEL.WRITE_A_COMMENT}</label>
                        <textarea
                            name="comment"
                            id="comment"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </div>
                    <button type="button" className="primary-btn" onClick={onSubmit}>
                        {ACTION_MODAL.BUTTON.SUBMIT}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default ActionModal;
