/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';

import { getAttachments, downloadAttachment } from '../../../API/attachments';
import { DocumentIconSvg, DownloadIcon } from '../../../assets/images';
import { IAttachment, IAttachmentConfig } from '../../../interfaces/attachment';
import { bytesToSize } from '../../../utils';
import './ViewAttachments.scss';
import STATIC_CONTENT from '../../../constants/StaticContent';

const { DOCUMENT_PREVIEW } = STATIC_CONTENT;

interface IDownloadProps {
    attachmentId: number;
    fileName: string;
}

export const DownloadAttachment: React.FC<IDownloadProps> = ({ attachmentId, fileName }) => {
    const [downloading, setDownloading] = useState(false);
    const downloadFile = async () => {
        setDownloading(true);
        await downloadAttachment(attachmentId, fileName);
        setDownloading(false);
    };

    return (
        <div className="download-icon">
            {downloading ? (
                <Loader type="Oval" color="#000" height={14} width={14} />
            ) : (
                <DownloadIcon onClick={() => downloadFile()} />
            )}
        </div>
    );
};

interface IAttachmentInfo {
    attachments: IAttachment[];
}

const AttachmentInfo: React.FC<IAttachmentInfo> = ({ attachments }) => (
    <div className="attachment-info-1">
        {attachments.map((attachment) => (
            <div className="attachment__info" key={attachment.id}>
                <DocumentIconSvg className="document-icon" />
                <div className="attachment__detail">
                    <h4
                        onClick={() => downloadAttachment(attachment.id, attachment.fileMeta.name)}
                        role="presentation"
                    >
                        {attachment.fileMeta.name}
                    </h4>
                    <span>{bytesToSize(attachment.fileMeta.size)}</span>
                </div>
                <DownloadAttachment
                    attachmentId={attachment.id}
                    fileName={attachment.fileMeta.name}
                />
            </div>
        ))}
    </div>
);

interface Props {
    activeDocVersion: number;
    attachmentConfig: IAttachmentConfig[];
    showTitle?: boolean;
    isDocFetched:boolean;
    allowReviseByAnyone: boolean;
}

const ViewAttachments: React.FC<Props> = ({
    activeDocVersion,
    attachmentConfig,
    showTitle = false,
    isDocFetched,
    allowReviseByAnyone
}) => {
    const [attachments, setAttachments] = useState<IAttachment[]>([]);

    useEffect(() => {
        const fetchAttachments = async () => {
            const res = await getAttachments(activeDocVersion, allowReviseByAnyone);
            if (res?.apiStatus === 'SUCCESS') {
                setAttachments(res.attachments);
            }
        };
        if(isDocFetched)fetchAttachments();
    }, [activeDocVersion,isDocFetched]);

    return (
        <>
            {(attachments.length > 0 || showTitle) && (
                attachmentConfig.length ? (
                <div className="attachments">
                    <div className="view-attachments">
                        <h3>
                            Attachments {attachments.length > 0 ? `(${attachments.length})` : ''}
                        </h3>
                        {attachments.length > 0 && (
                            <div className="attachment">
                                {attachmentConfig.map((config) => (
                                    <div key={config.attachmentType} className="attachment__list">
                                        {attachments.filter(
                                            (a) =>
                                                a.fileMeta.attachmentType === config.attachmentType,
                                        ).length > 0 && (
                                            <>
                                                <div
                                                    className={`attachment__type ${
                                                        config.isMandatory ? 'mandatory-field' : ''
                                                    }`}
                                                >
                                                    {config.displayName}
                                                </div>
                                                <AttachmentInfo
                                                    attachments={attachments.filter(
                                                        (a) =>
                                                            a.fileMeta.attachmentType ===
                                                            config.attachmentType,
                                                    )}
                                                />
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                        {attachments.length === 0 && showTitle && (
                            <p className="no-attachment">{DOCUMENT_PREVIEW.NO_ATTACHMENTS}</p>
                        )}
                    </div>
                </div>
                ) : null
            )}
        </>
    );
};

export default ViewAttachments;
