import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import CONSTANTS from './constants';
import Router from './router';
import AuthDataProvider from './contexts/user-auth-provider';
import './App.scss';
import Header from './widgets/header/Header';
import Breadcrumbs from './components/breadcrumbs/Breadcrumbs';
import QueryParamsProvider from './contexts/query-params-provider';
import Footer from './widgets/footer/Footer';
import SearchProvider from './contexts/search-provider';
import { useInitializeGA } from './hooks/useGoogleAnalytics';

// eslint-disable-next-line max-lines-per-function
const App: React.FC = () => {
    useInitializeGA();
    const showBanner = CONSTANTS.BANNER.showBanner === 'true';
    return (
        <>
            <div className="App">
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <QueryParamsProvider>
                        <AuthDataProvider>
                            <SearchProvider>
                                <SkeletonTheme>
                                    {showBanner && (
                                        <div className="banner">{CONSTANTS.BANNER.BANNER_TEXT}</div>
                                    )}
                                    <Header />

                                    <div className="main">
                                        <Breadcrumbs />
                                        <Router />
                                    </div>
                                    <Footer />
                                </SkeletonTheme>
                            </SearchProvider>
                        </AuthDataProvider>
                    </QueryParamsProvider>
                </BrowserRouter>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
};

export default App;
