import { IExternalApprover, IUserOptionValue } from '../interfaces';
import { IApprovalDocStage, IDocApprovers, IExternalApprovers } from '../interfaces/document';

export type IDocumentExternalApproversAction =
    | { type: 'update'; value: IExternalApprover[] }
    | {
          type: 'update-user';
          stageIndex: number;
          approverIndex: number;
          value: IExternalApprover;
      }
    | { type: 'push'; stageIndex: number; value: IExternalApprover }
    | { type: 'delete'; stageIndex: number; index: number }

export type IDocumentApproversAction =
    | { type: 'update'; value: IApprovalDocStage[] }
    | {
          type: 'update-user';
          stageIndex: number;
          approverIndex: number;
          value: IUserOptionValue;
      }
    | { type: 'push'; stageIndex: number; value: IDocApprovers }
    | { type: 'delete'; stageIndex: number; index: number };

// eslint-disable-next-line max-lines-per-function
export const documentApproversReducer = (
    state: IApprovalDocStage[],
    action: IDocumentApproversAction,
): IApprovalDocStage[] => {
    switch (action.type) {
        case 'update': {
            const tempState: IApprovalDocStage[] = [];
            return tempState.concat(action.value);
        }
        case 'update-user': {
            const { approvers, scopeType } = state[action.stageIndex];
            if (scopeType === 'roleBased') {
                approvers[action.approverIndex].roleId = action.value.roleId;
            }
            approvers[action.approverIndex].employee = action.value;
            return [
                ...state.slice(0, action.stageIndex),
                { ...state[action.stageIndex], approvers },
                ...state.slice(action.stageIndex + 1),
            ];
        }
        case 'push': {
            const { approvers } = state[action.stageIndex];
            approvers.push(action.value);
            return [
                ...state.slice(0, action.stageIndex),
                { ...state[action.stageIndex], approvers },
                ...state.slice(action.stageIndex + 1),
            ];
        }
        case 'delete': {
            const { approvers } = state[action.stageIndex];
            approvers.splice(action.index, 1);
            return [
                ...state.slice(0, action.stageIndex),
                { ...state[action.stageIndex], approvers },
                ...state.slice(action.stageIndex + 1),
            ];
        }
        default:
            return state;
        }
    };
    
    export const externalApproversReducer = (
        state: IExternalApprover[],
        action: IDocumentExternalApproversAction,
      ): IExternalApprover[] => {
    
        switch (action.type) {
            case 'update': {
                const tempState: IExternalApprover[] = [];
                return tempState.concat(action.value);
            }
          case 'update-user':
            return [
              ...state.slice(0, action.approverIndex),
              action.value,
              ...state.slice(action.approverIndex + 1),
            ];
       
          case 'push':
            return [...state, action.value];
       
          case 'delete':
            return [
              ...state.slice(0, action.index),
              ...state.slice(action.index + 1),
            ];
       
          default:
            return state;
    }
  };
