import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { AlignMiddle, TextCenter } from 'react-bootstrap-icons';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import Button from '../button/Button';
import { PlusIconSvg } from '../../assets/images';
import STATIC_CONTENT from '../../constants/StaticContent';
import { IOwnerChange, IUserOptionValue } from '../../interfaces';
import CONSTANTS from '../../constants';
import { userSearch } from '../../API/users';
import './OwnerSelectModal.scss';
import { makeDocumentObsolete } from '../../API/document';

const animatedComponents = makeAnimated();

const customStyles = {
    content: {
        // backgroundColor: '#000',
        bottom: 'auto',
        left: '50%',
        marginRight: '-50%',
        right: 'auto',
        top: '20%',
        transform: 'translate(-50%, -50%)',
    },
};

interface Props {
    documents: IOwnerChange[];
    show: boolean;
    handleShow: (check: boolean) => void;
    handleObsolete: (Docs: IOwnerChange[]) => void;
}
// eslint-disable-next-line max-lines-per-function
const ObsoleteModal: React.FC<Props> = ({ documents, show, handleShow, handleObsolete }) => {
    const [Docs, setDocsForObsolete] = useState<IOwnerChange[]>([]);
    useEffect(() => {
        setDocsForObsolete(
            documents.filter(
                (document) => document.state === 'PUBLISHED' || document.state === 'SUNSET',
            ),
        );
    }, [documents]);

    return (
        <div>
            <Modal isOpen={show} style={customStyles} className="Owner-Change-Modal">
                <PlusIconSvg className="close-btn" onClick={() => handleShow(false)} />
                <label htmlFor="user-search">
                    Only Documents listed below from the selected can be Obsoleted
                </label>
                <div className="Docs">
                    {Docs.map((doc: IOwnerChange) => (
                        <h2>{doc.docNumber}</h2>
                    ))}
                </div>
                <h5 className="Note">(*Only Published or sunset documents can be obsoleted)</h5>
                <Button
                    onClick={() => handleObsolete(Docs)}
                    disabled={false}
                    isLoading={false}
                    className="submit-button"
                    type={undefined}
                >
                    Confirm
                </Button>
            </Modal>
        </div>
    );
};

export default ObsoleteModal;
