/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { getMilliseconds } from 'date-fns';

import './DocComments.scss';
import { toast } from 'react-toastify';
import { createComment, deleteComment, getComments, updateComment } from '../../API/comment';
import { IComment } from '../../interfaces/document';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { formatDate, formatTime } from '../../utils/date';
import STATIC_CONTENT from '../../constants/StaticContent';
import { SkeletonText } from '../../components/Skeleton';
import { useEventsHeight } from '../../contexts/events-height-provider';
import { IUserType } from '../../interfaces';

const { DOC_COMMENTS } = STATIC_CONTENT;

const Comment: React.FC<{
    commentInfo: IComment;
    userId: number;
    fetchComments: () => Promise<void>;
}> = ({ commentInfo, userId, fetchComments }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [comment, setComment] = useState(commentInfo.comment);

    const removeComment = async () => {
        const res = await deleteComment(commentInfo.id);
        if (res?.apiStatus === 'SUCCESS') {
            fetchComments();
        } else {
            toast.error(DOC_COMMENTS.TOAST.ERROR_MSG.UNABLE_TO_DELETE);
        }
    };

    const saveEditedComment = async () => {
        const res = await updateComment(commentInfo.id, comment);
        if (res?.apiStatus === 'SUCCESS') {
            fetchComments();
            setIsEditable(false);
        } else {
            toast.error(DOC_COMMENTS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
    };

    return (
        <div className="comment">
            <span className="comment--title">
                {commentInfo.commenterId === userId ? (
                    'Me'
                ) : (
                    <SkeletonText text={commentInfo.commenterName} width={100} />
                )}
                ,{' '}
                {getMilliseconds(commentInfo.updatedDate) === 1 ? (
                    <Skeleton width={70} />
                ) : (
                    formatDate(commentInfo.updatedDate)
                )}{' '}
                at{' '}
                {getMilliseconds(commentInfo.updatedDate) === 1 ? (
                    <Skeleton width={50} />
                ) : (
                    formatTime(commentInfo.updatedDate)
                )}{' '}
                {commentInfo.commentEdited ? ' \u00b7 Edited' : ''}
            </span>
            {isEditable ? (
                <textarea
                    name="comment"
                    id="comment"
                    rows={3}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
            ) : (
                <p className={commentInfo.commentType.toLowerCase()}>
                    <pre>{commentInfo.comment}</pre>
                </p>
            )}
            {commentInfo.commenterId === userId && (
                <div>
                    {isEditable ? (
                        <>
                            <button
                                type="button"
                                className="link"
                                onClick={() => saveEditedComment()}
                            >
                                {DOC_COMMENTS.BUTTON.SAVE}
                            </button>
                            <button
                                type="button"
                                className="link"
                                onClick={() => {
                                    setIsEditable(false);
                                    setComment(commentInfo.comment);
                                }}
                            >
                                {DOC_COMMENTS.BUTTON.CANCEL}
                            </button>
                        </>
                    ) : (
                        <>
                            {!commentInfo.commentType && (
                                <>
                                    <button
                                        type="button"
                                        className="link"
                                        onClick={() => setIsEditable(true)}
                                    >
                                        {DOC_COMMENTS.BUTTON.EDIT}
                                    </button>
                                    <button
                                        type="button"
                                        className="link"
                                        onClick={() => removeComment()}
                                    >
                                        {DOC_COMMENTS.BUTTON.DELETE}
                                    </button>
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

interface Props {
    activeDocVersion: number;
    setUnsavedCommentFunction: any;
    allowReviseByAnyone: boolean;
    userType: IUserType;
}

const defaultComments = () => {
    const comments: IComment[] = [];
    // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < 3; i++) {
    //     comments.push({
    //         comment: CONSTANTS.LOADING.TEXT,
    //         commentEdited: false,
    //         commentType: '',
    //         commenterId: CONSTANTS.LOADING.NUMBER,
    //         commenterName: CONSTANTS.LOADING.TEXT,
    //         updatedDate: CONSTANTS.LOADING.DATE,
    //         id: CONSTANTS.LOADING.NUMBER,
    //     });
    // }

    return comments;
};

const DocComments: React.FC<Props> = ({ activeDocVersion, setUnsavedCommentFunction, allowReviseByAnyone, userType }) => {
    const { user } = useAuthDataContext();
    const [comments, setComments] = useState<IComment[]>(defaultComments());
    const [newComment, setNewComment] = useState('');
    const [saving, setSaving] = useState(false);
    const { updateHeight } = useEventsHeight();
    
    const fetchComments = async () => {
        if(activeDocVersion) {
            const commentsData = await getComments(activeDocVersion, allowReviseByAnyone);
            if (commentsData?.apiStatus === 'SUCCESS') {
                setComments(commentsData.comments);
            } else {
                toast.error('Something went wrong');
            }
        }
    };

    useEffect(() => {
        updateHeight();
    });

    const addNewComment = async () => {
        if(newComment === '') {
            toast.warn(DOC_COMMENTS.TOAST.WARNING_MSG.ADD_COMMENT_FIRST);
        } else {
            setSaving(true);
            const res = await createComment(activeDocVersion, newComment, allowReviseByAnyone);
            if (res?.apiStatus === 'SUCCESS') {
                fetchComments();
                setNewComment('');
            } else {
                toast.error(DOC_COMMENTS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
            }
            setSaving(false);
        }
    };

    useEffect(() => {
        fetchComments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDocVersion]);

    return (
        <div className="doc-comments">
            {(userType === 'OTHER' && comments.length) || userType !== 'OTHER' ? (
                <h3>Comments {comments.length > 0 ? `(${comments.length})` : ''}</h3>
            ) : (
                ''
            )}
            <div className="comments-list">
                {comments.length > 0 &&
                    comments.map((comment) => (
                        <Comment
                            commentInfo={comment}
                            userId={user.id}
                            key={comment.id}
                            fetchComments={fetchComments}
                        />
                    ))}
            </div>
            {userType !== 'OTHER' && (
                <div className="add-comment">
                    <h5>{DOC_COMMENTS.LABEL.WRITE_A_COMMENT}</h5>
                    <textarea
                        name="comment"
                        id="comment"
                        rows={3}
                        value={newComment}
                        onChange={(e) => {
                            setNewComment(e.target.value);
                            setUnsavedCommentFunction(e.target.value);
                        }}
                    />
                    <button
                        type="button"
                        className="submit-comment"
                        onClick={() => addNewComment()}
                        disabled={saving}
                    >
                        {DOC_COMMENTS.BUTTON.SUBMIT_COMMENT}
                    </button>
                </div>
            )}
        </div>
    );
};

export default DocComments;
