import React, { useState } from 'react';
import qs from 'qs';
import { toast } from 'react-toastify';
import CONSTANTS from '../../constants';
import {
    IDocStatus,
    IOwnerChange,
    IOwnerChangeAdminDocuments,
    IPaginationData,
    IUserOptionValue,
} from '../../interfaces';
import { ownerChangeString } from '../../utils';
import Button from '../button/Button';
import DocumentAdminFilters from './filters/DocumentAdminFilters';
import './DocumentAdminSettings.scss';
import OwnerSelectModal from './OwnerSelectModal';
import DocumentAdminTable from './Table/DocumentAdminTable';
import { getDocumentList, ownerChangeAction } from '../../API/ownerChange';
import ObsoleteModal from './ObsoleteModal';
import { makeDocumentObsolete } from '../../API/document';

// eslint-disable-next-line max-lines-per-function
const DocumentAdminSettings: React.FC = () => {
    const defaultDocForOwnerChange = () => {
        const Documents: IOwnerChangeAdminDocuments[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i <= 9; i++) {
            Documents.push({
                documentId: CONSTANTS.LOADING.NUMBER,
                docNumber: CONSTANTS.LOADING.TEXT,
                documentType: CONSTANTS.LOADING.TEXT,
                ownerId: CONSTANTS.LOADING.NUMBER,
                owner: CONSTANTS.LOADING.TEXT,
                status: 'NEW',
                updatedAt: CONSTANTS.LOADING.DATE,
            });
        }

        return Documents;
    };
    const [docForOwnerChange, setDocsForOwnerChange] = useState(defaultDocForOwnerChange);
    const [openModal, setOpenModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedOwner, setSelectedOwner] = useState<IUserOptionValue>({
        jobTitle: '',
        label: 'ALL',
        value: '',
        role: '',
        roleId: 0,
        activeUser: true,
    });
    const [DocNumber, setDocNumber] = useState('');
    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: CONSTANTS.NO_OF_TABLE_ROWS,
        totalCount: 0,
    };
    const [selectedDocuments, setSelectedDocuments] = useState<IOwnerChange[]>([]);
    const [selectedNewOwner, setSelectedNewOwner] = useState<IUserOptionValue | null>(null);
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const update = async (
        pageIndex?: number,
        pageSize?: number,
        order?: string,
        orderType?: string | undefined,
    ) => {
        const documentNumber: string | undefined = DocNumber !== '' ? DocNumber : undefined;
        const OwnerIds: number | undefined = selectedOwner?.value
            ? (selectedOwner.value as number)
            : undefined;
        const size = pageSize !== undefined ? pageSize : pagination.pageSize;
        const index = pageIndex !== undefined ? pageIndex : pagination.pageIndex;
        const orderBy = order;
        const sortOrder = orderType;
        const params = {
            ownerId: OwnerIds,
            documentNumber,
            limit: size,
            offset: index * size,
            orderBy,
            sortOrder,
        };
        const documents = await getDocumentList(qs.stringify(params));
        if (documents?.apiStatus === 'SUCCESS') {
            setDocsForOwnerChange(documents.DocumentList);
            setPagination({
                pageSize: size,
                pageIndex: index,
                totalCount: documents.totalDocuments,
            });
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleSingleCheckBox = (documentId: number, oldOwnerId: number, state: IDocStatus, docNumber: string) => {
        const selectedDocumentsCopy = [...selectedDocuments];
        const position = selectedDocumentsCopy
            .map((d) => ownerChangeString(d.documentId, d.oldOwnerId))
            .indexOf(ownerChangeString(documentId, oldOwnerId));
        if (position === -1) {
            selectedDocumentsCopy.push({ documentId, oldOwnerId, state, docNumber });
        } else {
            selectedDocumentsCopy.splice(position, 1);
        }
        setSelectedDocuments(selectedDocumentsCopy);
    };

    const handleSelectAll = (selectAll1: boolean) => {
        if (selectAll1) {
            setSelectedDocuments(
                docForOwnerChange.map((d) => ({
                    documentId: d.documentId,
                    oldOwnerId: d.ownerId,
                    state: d.status,
                    docNumber: d.docNumber
                })),
            );
        } else {
            setSelectedDocuments([]);
        }
    };

    const handleAction = async () => {
        const documents = selectedDocuments;
        if (!selectedNewOwner?.value) {
            toast.error('Please Select Owner');
        } else {
            const res = await ownerChangeAction(documents, selectedNewOwner?.value as number);
            if (res?.apiStatus === 'FAILURE') {
                toast.error('Something went wrong');
            } else {
                setSelectedDocuments([]);
                update();
            }
        }
        setOpenModal(false);
    };

    const handleObsolete = async (Docs:IOwnerChange[]) => {
        const documents = Docs;
        const res = await makeDocumentObsolete(documents);
        if (res?.apiStatus === 'FAILURE') {
            toast.error('Something went wrong');
        } 
        else
        {
            setSelectedDocuments([]);
            update();
        }
        setModal(false);
    };

    return (
        <div className="document-admin-settings">
            <DocumentAdminFilters
                owner={selectedOwner}
                updateOwner={(owner) => setSelectedOwner(owner)}
                docNumber={DocNumber}
                updateDocNumber={setDocNumber}
            />
            <DocumentAdminTable
                selectAll={selectedDocuments.length === docForOwnerChange.length}
                docNumber={DocNumber}
                SelectedOwner={selectedOwner}
                SelectedDocuments={selectedDocuments}
                handleSelectAll={(selectALl2) => {
                    handleSelectAll(selectALl2);
                }}
                handleSingleCheckBox={(documentId, oldOwnerId, state, DocumentNumber) =>
                    handleSingleCheckBox(documentId, oldOwnerId, state, DocumentNumber)
                }
                data={docForOwnerChange}
                pagination={pagination}
                fetchDocuments={(
                    pageIndex: number,
                    pageSize: number,
                    order: string,
                    orderType: string | undefined,
                ) => update(pageIndex, pageSize, order, orderType)}
            />
            <div className="buttons">
                <Button
                    disabled={!(selectedDocuments.length > 0)}
                    type="button"
                    isLoading={false}
                    onClick={() => {
                        setOpenModal(true);
                    }}
                    className=""
                >
                    Change Owner
                </Button>

                <Button
                    disabled={!(selectedDocuments.length > 0)}
                    type="button"
                    isLoading={false}
                    onClick={() => {
                        setModal(true);
                    }}
                    className="Obsolete-button"
                >
                    Obsolete
                </Button>
            </div>
            <ObsoleteModal
                show={modal}
                handleShow={(show: boolean) => setModal(show)}
                documents={selectedDocuments}
                handleObsolete={(docs:IOwnerChange[])=>handleObsolete(docs)}
            />
            <OwnerSelectModal
                show={openModal}
                handleShow={(show: boolean) => setOpenModal(show)}
                updateChangedOwner={(selectedUser: IUserOptionValue) =>
                    setSelectedNewOwner(selectedUser)
                }
                handleOwnerChange={() => handleAction()}
            />
        </div>
    );
};
export default DocumentAdminSettings;
