import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ArrowIcon } from '../../assets/images';

import CONSTANTS from '../../constants';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import './Breadcrumbs.scss';

// TODO : dynamic breadcrumb based on path of page

interface IBreadcrumb {
    name: string;
    link: string;
}

const breadcrumbMapping: { [key: string]: IBreadcrumb[] } = {
    '/dashboard': [],
    '/create-document': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        {
            name: 'My Documents',
            link: `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_DOCUMENTS`,
        },
        { name: 'Create Document', link: CONSTANTS.RELATIVE_PATHS.createDocument.Url },
    ],
    '/document-type/create': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'Document Types', link: CONSTANTS.RELATIVE_PATHS.documentTypes.Url },
        { name: 'Create', link: CONSTANTS.RELATIVE_PATHS.documentTypeCreate.Url },
    ],
    '/document-types': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'Document Types', link: CONSTANTS.RELATIVE_PATHS.documentTypes.Url },
    ],
    '/document-details': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        {
            name: 'My Documents',
            link: `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_DOCUMENTS`,
        },
        { name: 'Document Details', link: CONSTANTS.RELATIVE_PATHS.documentDetails.Url },
    ],
    '/search': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'Search', link: CONSTANTS.RELATIVE_PATHS.search.Url },
    ],
    '/settings': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'Settings', link: CONSTANTS.RELATIVE_PATHS.settings.Url },
    ],
    '/task-details': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        {
            name: 'My Approvals',
            link: `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_APPROVALS`,
        },
        { name: 'Task Details', link: CONSTANTS.RELATIVE_PATHS.taskDetails.Url },
    ],
    '/documentation': [
        { name: 'Dashboard', link: CONSTANTS.RELATIVE_PATHS.dashboard.Url },
        { name: 'User Guide', link: CONSTANTS.RELATIVE_PATHS.documentation.Url },
    ],
    '/clm/contracts': [
        { name: 'Contracts Dashboard', link: CONSTANTS.RELATIVE_PATHS.contractsDashboard.Url},
        { name: 'Contracts', link: CONSTANTS.RELATIVE_PATHS.contracts.Url}
    ]
};

// eslint-disable-next-line max-lines-per-function
const Breadcrumbs: React.FC = () => {
    const [breadcrumb, setBreadCrumb] = useState<IBreadcrumb[]>();
    const { pathname, search } = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (breadcrumbMapping[pathname]) {
            const breadcrumbs = breadcrumbMapping[pathname];
            if (pathname === '/document-details' || pathname === '/task-details') {
                if (search.includes('MY_GROUP_APPROVALS')) {
                    breadcrumbs[1].name = 'My Group Approvals';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_GROUP_APPROVALS`;
                } else if (search.includes('MY_REVIEWS')) {
                    breadcrumbs[1].name = 'My Reviews';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_REVIEWS`;
                } else if (search.includes('MY_FAVORITES')) {
                    breadcrumbs[1].name = 'My Favorites';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_FAVORITES`;
                } else if (search.includes('MY_LATEST')) {
                    breadcrumbs[1].name = 'Latest Documents';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_LATEST`;
                } else if (search.includes('SEARCH')) {
                    breadcrumbs[1].name = 'Search';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.search.Url}`;
                } else if (pathname === '/document-details') {
                    breadcrumbs[1].name = 'My Documents';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_DOCUMENTS`;
                } else {
                    breadcrumbs[1].name = 'My Approvals';
                    breadcrumbs[1].link = `${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_APPROVALS`;
                }
            }
            setBreadCrumb(breadcrumbs);
        } else {
            setBreadCrumb([]);
        }
    }, [pathname, search]);

    const onClick = (link: string) => {
        history.push(`/${link}`);
    };

    const { tokenValid, user } = useAuthDataContext();
    return (
        <div className="breadcrumbs">
            {tokenValid && user.id && (
                <ul>
                    {breadcrumb?.map((data) => (
                        <li key={data.name}>
                            <span
                                role="link"
                                onClick={() => onClick(data.link)}
                                onKeyPress={() => onClick(data.link)}
                                tabIndex={0}
                            >
                                {data.name}
                            </span>
                            <ArrowIcon />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Breadcrumbs;
