import { format, isToday, isYesterday } from 'date-fns';

const formatDate = (date: Date): string => {
    if (isToday(date)) return `Today`;
    if (isYesterday(date)) return `Yesterday`;

    return format(date, 'MM-dd-yyyy');
};
const formatTime = (date: Date): string => format(date, 'hh:mm a');

const formatDateForEvent = (date: Date): string => {
    if (isToday(date)) return `Today at ${formatTime(date)}`;
    if (isYesterday(date)) return `Yesterday at ${formatTime(date)}`;

    return `on ${format(date, 'do MMM, yyyy ')} at ${formatTime(date)}`;
};

const returnformatDateOnly = (date: Date): string => `${format(date, 'do MMM, yyyy ')} at ${formatTime(date)}`;

const convertEpochToDate = (epochTime: number): Date => {
    const date = new Date(0);
    date.setUTCSeconds(epochTime);

    return date;
};

function calculateYearsDifference(given: Date, current: Date) : {years : number,dateToCalculateMonths : Date}{
    let years = 0;
    let dateToCalculateMonths = given;
    let givenDate = given;
    // eslint-disable-next-line no-constant-condition
    while(true){
        const nextDate = new Date(givenDate.getFullYear()+1, givenDate.getMonth() , givenDate.getDate());
        if(nextDate < current){
            years += 1;
            dateToCalculateMonths = nextDate;
            givenDate = nextDate;
        } else {
            break;
        }
    }
    return {
        years,
        dateToCalculateMonths,
    }
};

function calculateMonthssDifference(given: Date, current: Date) : {months : number,dateToCalculateDays : Date}{
    let months = 0;
    let dateToCalculateDays = given;
    let givenDate = given;
    // eslint-disable-next-line no-constant-condition
    while(true){
        const nextDate = new Date(givenDate.getFullYear(), givenDate.getMonth() + 1 , givenDate.getDate());
        if(nextDate < current){
            months += 1;
            dateToCalculateDays = nextDate;
            givenDate = nextDate;
        } else {
            break;
        }
    }
    return {
        months,
        dateToCalculateDays,
    }
};

function calculateYearGap(dateString: string) : string {
    const currentDate = new Date();
    const givenDate = new Date(dateString);
    const {years , dateToCalculateMonths} = calculateYearsDifference(givenDate, currentDate);
    const {months , dateToCalculateDays} = calculateMonthssDifference(dateToCalculateMonths, currentDate);
    const timeDifference = Math.abs(currentDate.getTime() - dateToCalculateDays.getTime());
    const oneDay = 24 * 60 * 60 * 1000;
    const days = Math.round(timeDifference / oneDay);
    return `${years} year(s) ${months} month(s) ${days} day(s)`;
}


export {calculateYearGap, formatDate, formatTime, convertEpochToDate, formatDateForEvent, returnformatDateOnly ,calculateYearsDifference ,calculateMonthssDifference };
