/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import './DocumentAction.scss';
import ReactTooltip from 'react-tooltip';
import { IButtonStatus, IDocStatus, IUserType } from '../../interfaces';
import { createComment } from '../../API/comment';
import { createEventForEditDocument, documentAction, documentActionClaimUnclaim, updateAutoRenew } from '../../API/document';

import { IDocumentActionTypes, IDocumentInfo } from '../../interfaces/document';
import CONSTANTS from '../../constants';
import ActionModal from './ActionModal';
import ReviseModal from './ReviseModal';
import ForwardDocModal from './ForwardDocModal';
import AddApproverModal from './AddApproverModal';
import STATIC_CONTENT from '../../constants/StaticContent';
import Button from '../button/Button';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { useTaskDetailContext } from '../../contexts/task-details-provider';
import RepublishModal from './RepublishModal';
import { useDocumentContext } from '../../contexts/document-provider';
import TerminateModal from './TerminateModal';
import DocusignModal from './DocusignModal';

interface Props {
    userType: IUserType;
    documentId: number;
    docState: IDocStatus;
    activeDocumentVersion: number;
    hideUsers: number[];
    setHideUsers: React.Dispatch<React.SetStateAction<number[]>>;
    collectComment?: boolean;
    taskId?: string;
    assigneeAndCreatorSame?: boolean;
    unsavedComment: string;
    setUnsavedCommentFunction: any;
    render: boolean;
    assignee: {
        employeeName: string;
        id: number;
        email: string;
    };
    accessibleByCurrentUser?: boolean;
    isSunsetEnabled?: boolean;
    autoRenew: boolean;
    docInfo: IDocumentInfo;
    setDocInfo: React.Dispatch<React.SetStateAction<IDocumentInfo>>;
    isEndDateAsked: boolean;
    isDocusignTriggered?: boolean;
}

const { DOCUMENT_ACTIONS, DOC_COMMENTS } = STATIC_CONTENT;

const DocumentAction: React.FC<Props> = ({
    userType,
    documentId,
    docState,
    activeDocumentVersion,
    hideUsers,
    collectComment,
    taskId,
    setHideUsers,
    assigneeAndCreatorSame,
    unsavedComment,
    setUnsavedCommentFunction,
    render,
    assignee,
    accessibleByCurrentUser,
    isSunsetEnabled,
    autoRenew,
    docInfo,
    setDocInfo,
    isEndDateAsked,
    isDocusignTriggered
}) => {
    const history = useHistory();
    const { fetchTaskDetails, updateFields, approvers } = useTaskDetailContext();
    const docTypeInfo1 = useTaskDetailContext().docTypeInfo;
    const { fetchDocumentVersion } = useDocumentContext();
    const { user } = useAuthDataContext();
    const [buttonStatus, setButtonStatus] = useState<IButtonStatus>('DEFAULT');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [forwardModal, setForwardModal] = useState(false);
    const [addApprovalModal, setAddApprovalModal] = useState(false);
    const [isReviseModalOpen, setIsReviseModalOpen] = useState(false);
    const [isRepublishModalOpen, setIsRepublishModal] = useState(false);
    const [isTerminateModalOpen, setIsTerminateModal] = useState(false);
    const [openModal,setOpenModal] = useState(false);
    const { isPublishLimitReached, docTypeInfo } = useDocumentContext();
    const [actionType, setActionType] = useState<IDocumentActionTypes>('');
    const isContract = docTypeInfo.isContract || docTypeInfo1.isContract;
    const onClick = async (type: IDocumentActionTypes) => {
        docTypeInfo.isContract = (docTypeInfo1.isContract || docTypeInfo.isContract);
        setButtonStatus('LOADING');
        // first save the comments if any
        if ((type === 'APPROVE' || type === 'REJECT') && unsavedComment) {
            const commentRes = await createComment(
                activeDocumentVersion,
                unsavedComment,
                docTypeInfo.allowReviseByAnyone,
            );
            if (commentRes?.apiStatus === 'SUCCESS') {
                setUnsavedCommentFunction('');
            } else {
                toast.error(DOC_COMMENTS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
            }
        }
        let res;
        if(type === 'TERMINATE'){
            res = await documentAction(documentId, 'TERMINATE', docTypeInfo, undefined, undefined, {
                effectiveFromDate: docInfo.effectiveFrom,
                endDate: isEndDateAsked,
                effectiveTillDate: docInfo.effectiveTill,
                effectivePeriod: docInfo.effectivePeriod,
                sunsetPeriod: docInfo.sunsetPeriod,
            });
        }
        else{
            res = await documentAction(documentId, type, docTypeInfo, taskId);
        }
        if (res?.apiStatus === 'SUCCESS') {
            if (type === 'APPROVE' || type === 'REJECT') {
                toast.success(`${type === 'APPROVE' ? 'Approved' : 'Rejected'} Document`);
                history.push(`${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_APPROVALS`);
            } else if (type === 'WITHDRAW') {
                toast.success(DOCUMENT_ACTIONS.TOAST.SUCCESS_MSG.DOCUMENT_WITHDRAWN);
                history.push(`${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`);
            } else if (type === 'REVIEWED') {
                toast.success(DOCUMENT_ACTIONS.TOAST.SUCCESS_MSG.DOCUMENT_REVIEWED);
                history.push(`${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_REVIEWS`);
            } else {
                toast.success(DOCUMENT_ACTIONS.TOAST.SUCCESS_MSG.SUCCESS);
            }
        } else {
            toast.error(DOCUMENT_ACTIONS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
        setButtonStatus('DEFAULT');
    };

    const editDocument = async () => {
        await createEventForEditDocument(documentId, user.id);
        setButtonStatus('LOADING');
        history.push(`/${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`, {
            accessibleByCurrentUser,
        });
    };

    const toggleClaim = async (type: IDocumentActionTypes) => {
        setButtonStatus('LOADING');
        const res = await documentActionClaimUnclaim(type, taskId);
        if (res?.apiStatus === 'SUCCESS') {
            toast.success(`${type === 'CLAIM' ? 'Claimed' : 'Unclaimed'} Document`);
            await fetchTaskDetails();
            setButtonStatus('DEFAULT');
        } else {
            toast.error(DOCUMENT_ACTIONS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
    };
    return (
        <div className="document-action">
            <div className="button-group">
                {userType === 'CREATOR' && docState === 'UNDER_REVIEW' && (
                    <>
                        <Button
                            type="submit"
                            className="primary-btn edit"
                            disabled={buttonStatus === 'LOADING'}
                            onClick={() => {
                                setActionType('WITHDRAW');
                                onClick('WITHDRAW');
                            }}
                            isLoading={buttonStatus === 'LOADING' && actionType === 'WITHDRAW'}
                        >
                            {DOCUMENT_ACTIONS.BUTTON.WITHDRAW}
                        </Button>
                    </>
                )}
                {(docState === 'PUBLISHED' || docState === 'SUNSET') && !docTypeInfo.blockRevise && (
                    <>
                        <Button
                            type="submit"
                            className="primary-btn edit"
                            disabled={buttonStatus === 'LOADING'}
                            onClick={() => {
                                setActionType('REVISE');
                                setIsReviseModalOpen(true);
                            }}
                            isLoading={buttonStatus === 'LOADING' && actionType === 'REVISE'}
                        >
                            {DOCUMENT_ACTIONS.BUTTON.REVISE}
                        </Button>
                    </>
                )}
                {userType === 'CREATOR' &&
                    docState === 'SUNSET' &&
                    isSunsetEnabled &&
                    !isEndDateAsked && (
                        <>
                            <Button
                                type="submit"
                                data-for="republish"
                                data-tip="The max Republish limit has been reached"
                                className="primary-btn edit"
                                disabled={buttonStatus === 'LOADING' || isPublishLimitReached}
                                onClick={() => {
                                    setActionType('REPUBLISH');
                                    setIsRepublishModal(true);
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'REPUBLISH'}
                            >
                                {DOCUMENT_ACTIONS.BUTTON.REPUBLISH}
                            </Button>
                            <ReactTooltip id="republish" place="right" effect="solid" type="dark" />
                        </>
                    )}
                {userType === 'CREATOR' &&
                    docState === 'PUBLISHED' &&
                    docInfo.isEverGreen && (
                        <>
                            <Button
                                type="submit"
                                data-for="republish"
                                data-tip="The max Republish limit has been reached"
                                className="primary-btn edit"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={async () => {
                                    await onClick('TERMINATE');
                                    await fetchDocumentVersion();
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'REPUBLISH'}
                            >
                                {DOCUMENT_ACTIONS.BUTTON.TERMINATE}
                            </Button>
                            <ReactTooltip id="republish" place="right" effect="solid" type="dark" />
                        </>
                    )}
                {userType === 'CREATOR' &&
                    docState === 'SUNSET' &&
                    isSunsetEnabled &&
                    autoRenew && (
                        <>
                            <Button
                                type="submit"
                                data-for="republish"
                                data-tip="The max Republish limit has been reached"
                                className="primary-btn edit"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={async () => {
                                    setDocInfo({ ...docInfo, autoRenew: false });
                                    await updateAutoRenew(documentId, activeDocumentVersion);
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'REPUBLISH'}
                            >
                                Prevent Renewal
                            </Button>
                            <ReactTooltip id="republish" place="right" effect="solid" type="dark" />
                        </>
                    )}
                {userType === 'APPROVER' && docState === 'UNDER_REVIEW' && !render && (
                    <>
                        {!assigneeAndCreatorSame && (
                            <Button
                                type="submit"
                                className="primary-btn approve"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={() => {
                                    setActionType('APPROVE');
                                    if (docTypeInfo1.isContract) {
                                        setOpenModal(true);
                                    } else if (collectComment) {
                                        setIsModalOpen(true);
                                    } else {
                                        onClick('APPROVE');
                                    }
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'APPROVE'}
                            >
                                <h4>{DOCUMENT_ACTIONS.BUTTON.APPROVE}</h4>
                            </Button>
                        )}
                        {!assigneeAndCreatorSame && (
                            <Button
                                type="submit"
                                className="primary-btn reject"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={() => {
                                    setActionType('REJECT');
                                    if (collectComment) {
                                        setIsModalOpen(true);
                                    } else {
                                        onClick('REJECT');
                                    }
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'REJECT'}
                            >
                                <h4>{DOCUMENT_ACTIONS.BUTTON.REJECT}</h4>
                            </Button>
                        )}
                        {accessibleByCurrentUser && (
                            <Button
                                type="submit"
                                className="primary-btn forward"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={async () => {
                                    setButtonStatus('LOADING');
                                    setButtonStatus('DEFAULT');
                                    setActionType('EDIT');
                                    editDocument();
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'EDIT'}
                            >
                                <h4>{DOCUMENT_ACTIONS.BUTTON.EDIT}</h4>
                            </Button>
                        )}
                        <Button
                            type="submit"
                            className="primary-btn forward"
                            disabled={buttonStatus === 'LOADING'}
                            onClick={async () => {
                                setButtonStatus('LOADING');
                                setButtonStatus('DEFAULT');
                                setActionType('FORWARD');
                                setForwardModal(true);
                                setActionType('FORWARD');
                            }}
                            isLoading={buttonStatus === 'LOADING' && actionType === 'FORWARD'}
                        >
                            <h4>{DOCUMENT_ACTIONS.BUTTON.REASSIGN}</h4>
                        </Button>
                        {!assigneeAndCreatorSame && !isContract && (
                            <Button
                                type="submit"
                                className="primary-btn ad-hoc"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={async () => {
                                    setButtonStatus('LOADING');
                                    setButtonStatus('DEFAULT');
                                    setActionType('AD_HOC');
                                    setAddApprovalModal(true);
                                    setActionType('AD_HOC');
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'AD_HOC'}
                            >
                                <h4>{DOCUMENT_ACTIONS.BUTTON.ADD_APPROVER}</h4>
                            </Button>
                        )}
                    </>
                )}
                {render && isDocusignTriggered && docTypeInfo1.isContract &&  (
                    <>
                        <h4>Please sign the contract sent out to you through Email to approve this Contract</h4>
                    </>
                )}
                {render && !(isDocusignTriggered) &&  (
                    <>
                        {assignee && Object.keys(assignee).length !== 0 && user.id === assignee.id && (
                            <div>
                                <Button
                                    type="submit"
                                    className="primary-btn approve"
                                    disabled={buttonStatus === 'LOADING'}
                                    onClick={() => {
                                        setActionType('APPROVE');
                                        if (collectComment) {
                                            setIsModalOpen(true);
                                        } else {
                                            onClick('APPROVE');
                                        }
                                    }}
                                    isLoading={
                                        buttonStatus === 'LOADING' && actionType === 'APPROVE'
                                    }
                                >
                                    <h4>{DOCUMENT_ACTIONS.BUTTON.APPROVE}</h4>
                                </Button>
                                <Button
                                    type="submit"
                                    className="primary-btn reject"
                                    disabled={buttonStatus === 'LOADING'}
                                    onClick={() => {
                                        setActionType('REJECT');
                                        if (collectComment) {
                                            setIsModalOpen(true);
                                        } else {
                                            onClick('REJECT');
                                        }
                                    }}
                                    isLoading={
                                        buttonStatus === 'LOADING' && actionType === 'REJECT'
                                    }
                                >
                                    <h4>{DOCUMENT_ACTIONS.BUTTON.REJECT}</h4>
                                </Button>
                                {accessibleByCurrentUser && (
                                    <Button
                                        type="submit"
                                        className="primary-btn forward"
                                        disabled={buttonStatus === 'LOADING'}
                                        onClick={async () => {
                                            setButtonStatus('LOADING');
                                            setButtonStatus('DEFAULT');
                                            setActionType('EDIT');
                                            editDocument();
                                        }}
                                        isLoading={
                                            buttonStatus === 'LOADING' && actionType === 'EDIT'
                                        }
                                    >
                                        <h4>{DOCUMENT_ACTIONS.BUTTON.EDIT}</h4>
                                    </Button>
                                )}
                                <Button
                                    type="submit"
                                    className="primary-btn approve"
                                    disabled={buttonStatus === 'LOADING'}
                                    onClick={async () => {
                                        setButtonStatus('LOADING');
                                        setActionType('UNCLAIM');
                                        toggleClaim('UNCLAIM');
                                    }}
                                    isLoading={
                                        buttonStatus === 'LOADING' && actionType === 'UNCLAIM'
                                    }
                                >
                                    <h4>Unclaim</h4>
                                </Button>
                                {!isContract && (
                                    <Button
                                        type="submit"
                                        className="primary-btn ad-hoc"
                                        disabled={buttonStatus === 'LOADING'}
                                        onClick={async () => {
                                            setButtonStatus('LOADING');
                                            await updateFields();
                                            setButtonStatus('DEFAULT');
                                            setActionType('AD_HOC');
                                            setAddApprovalModal(true);
                                            setActionType('AD_HOC');
                                        }}
                                        isLoading={
                                            buttonStatus === 'LOADING' && actionType === 'AD_HOC'
                                        }
                                    >
                                        <h4>{DOCUMENT_ACTIONS.BUTTON.ADD_APPROVER}</h4>
                                    </Button>
                                )}
                            </div>
                        )}
                        {assignee && Object.keys(assignee).length === 0 && (
                            <div>
                                <span>Currently Unclaimed</span>
                                <Button
                                    type="submit"
                                    className="primary-btn approve"
                                    disabled={buttonStatus === 'LOADING'}
                                    onClick={async () => {
                                        setActionType('CLAIM');
                                        toggleClaim('CLAIM');
                                    }}
                                    isLoading={buttonStatus === 'LOADING' && actionType === 'CLAIM'}
                                >
                                    <h4>Claim</h4>
                                </Button>
                            </div>
                        )}
                        {assignee && Object.keys(assignee).length !== 0 && user.id !== assignee.id && (
                            <div>
                                <span>{`Currently claimed by : ${assignee.employeeName}`}</span>
                                <Button
                                    type="submit"
                                    className="primary-btn approve"
                                    disabled={buttonStatus === 'LOADING'}
                                    onClick={() => {
                                        setActionType('CLAIM');
                                        toggleClaim('CLAIM');
                                    }}
                                    isLoading={buttonStatus === 'LOADING' && actionType === 'CLAIM'}
                                >
                                    <h4>Claim</h4>
                                </Button>
                            </div>
                        )}
                    </>
                )}
                {userType === 'REVIEWER' && docState === 'UNDER_REVIEW' && (
                    <>
                        {!assigneeAndCreatorSame && (
                            <Button
                                type="submit"
                                className="primary-btn edit"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={() => {
                                    setActionType('REVIEWED');
                                    onClick('REVIEWED');
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'REVIEWED'}
                            >
                                <h4>{DOCUMENT_ACTIONS.BUTTON.REVIEWED}</h4>
                            </Button>
                        )}
                    </>
                )}
            </div>
            {isReviseModalOpen && (
                <ReviseModal
                    isModalOpen={isReviseModalOpen}
                    onModalClose={setIsReviseModalOpen}
                    documentId={documentId}
                />
            )}
            {openModal && (
                <DocusignModal
                    isModalOpen={openModal}
                    onModalClose={setOpenModal}
                    documentId={documentId}
                />
            )}
            {isRepublishModalOpen && (
                <RepublishModal
                    isModalOpen={isRepublishModalOpen}
                    onModalClose={setIsRepublishModal}
                    documentId={documentId}
                />
            )}
            {isTerminateModalOpen && (
                <TerminateModal
                    isModalOpen={isTerminateModalOpen}
                    onModalClose={setIsTerminateModal}
                    documentId={documentId}
                />
            )}
            {isModalOpen && (
                <ActionModal
                    isModalOpen={isModalOpen}
                    onModalClose={setIsModalOpen}
                    type={actionType}
                    onClick={onClick}
                    activeDocVersion={activeDocumentVersion}
                    allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                />
            )}
            {forwardModal && (
                <ForwardDocModal
                    documentId={documentId}
                    isModalOpen={forwardModal}
                    onModalClose={setForwardModal}
                    hideUsers={hideUsers}
                    taskId={taskId || ''}
                    activeDocumentVersion={activeDocumentVersion}
                    unsavedComment={unsavedComment}
                    setUnsavedCommentFunction={setUnsavedCommentFunction}
                />
            )}
            {addApprovalModal && (
                <AddApproverModal
                    documentId={documentId}
                    isModalOpen={addApprovalModal}
                    onModalClose={setAddApprovalModal}
                    hideUsers={hideUsers}
                    setHideUsers={setHideUsers}
                    taskId={taskId || ''}
                />
            )}
        </div>
    );
};

export default DocumentAction;
